import { createSlice } from '@reduxjs/toolkit'
import { TeamDef } from '../../interfaces/teamDef'

interface TeamState {
    team: TeamDef
    teams: TeamDef[]
}

const initialState: TeamState = {
    team: {} as TeamDef,
    teams: [],
}

export const teamSlice: any = createSlice({
    name: 'team',
    initialState,
    reducers: {
        setTeam: (state, action) => {
            state.team = action.payload
        },
        setTeamsBA: (state, action) => {
            state.teams = action.payload
        },
        pushTeamsBA: (state, action: any) => {
            state.teams = [...state.teams, action as TeamDef] // TODO:  Review this line
        },
        setTeams: (state, action) => {
            state.teams = action.payload
        },
    },
})

export const { setTeam, setTeamsBA, pushTeamsBA, setTeams } = teamSlice.actions

export default teamSlice.reducer
