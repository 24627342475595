import { AddressDef, ClientDef, addressSet, clientSet } from './clientDef'
import { InternalItem, internalItemSet } from './internalItemDef'

export enum PaymentMethod {
    PUE = 'PUE',
    PPD = 'PPD',
}

export enum PaymentForm {
    EFECTIVO = '01',
    CHEQUE_NOMINATIVO = '02',
    TRANSFERENCIA_ELECTRONICA = '03',
    TARJETA_CREDITO = '04',
    MONEDERO_ELECTRONICO = '05',
    DINERO_ELECTRONICO = '06',
    VALES_DESPENSA = '08',
    DACION_PAGO = '12',
    PAGO_SUBROGACION = '13',
    PAGO_CONSIGNACION = '14',
    CONDONACION = '15',
    COMPENSACION = '17',
    NOVACION = '23',
    CONFUSION = '24',
    REMISION_DEUDA = '25',
    PRESCRIPCION_CADUCIDAD = '26',
    SATISFACCION_ACREEDOR = '27',
    TARJETA_DEBIRO = '28',
    TARJETA_SERVICIOS = '29',
    APLICACION_ANTICIPOS = '30',
    INTERMEDARIO_PAGOS = '31',
    POR_DEFINIR = '99',
}

export enum InvoiceUse {
    ADQUISICION_MERCANCIAS = 'G01',
    DEVOLUCIONES = 'G02',
    GASTOS_GENERAL = 'G03',
    CONSTRUCCIONES = 'I01',
    OFICINA_MOBILIARIO = 'I02',
    EQUIPO_TRANSPORTE = 'I03',
    COMPUTO = 'I04',
    DADOS_MOLDES_HERRAMENTAL = 'I05',
    COMUNICACION_TELEFONICA = 'I06',
    COMUNICACION_SATELITAL = 'I07',
    OTROS_EQUIPOS = 'I08',
    HONORARIOS_MEDICOS = 'D01',
    GASTOS_MEDICOS = 'D02',
    GASTOS_FUNERARIOS = 'D03',
    DONATIVOS = 'D04',
    HIPOTECARIOS = 'D05',
    APORTACIONES_SAR = 'D06',
    SEGUROS_PRIMAS = 'D07',
    GASTOS_DE_TRANSPORTE_ESCOLAR = 'D08',
    DEPOSITOS_PARA_AHORRO = 'D09',
    SERVICIOS_EDUCATIVOS = 'D10',
    SIN_EFECTOS_FISCALES = 'S01',
    PAGOS = 'CP01',
    NOMINAS = 'CN01',
}

export enum Periodicity {
    DAY = 'day',
    WEEK = 'week',
    FORTNIGHT = 'fortnight',
    MONTH = 'month',
    TWO_MONTHS = 'two_months',
}

export interface Global {
    periodicity: Periodicity
    months: string
    year: number
}

export const globalSet = (object: any) => {
    const global: Global = {
        periodicity: object['periodicity'] ?? null,
        months: object['months'] ?? null,
        year: object['year'] ?? null,
    }
    return global
}

export enum TaxSystem {
    GENERAL_DE_LEY_PERSONAS_MORALES = '601',
    PERSONAS_MORALES_CON_FINES_NO_LUCRATIVOS = '603',
    SUELDOS_Y_SALARIOS_E_INGRESOS_ASIMILADOS_A_SALARIOS = '605',
    ARRENDAMIENTO = '606',
    DEMAS_INGRESOS = '608',
    CONSOLIDACION = '609',
    RESIDENTES_EN_EL_EXTRANJERO_SIN_ESTABLECIMIENTO_PERMANENTE_EN_MEXICO = '610',
    INGRESOS_POR_DIVIDENDOS = '611',
    PERSONAS_FISICAS_CON_ACTIVIDADES_EMPRESARIALES_Y_PROFESIONALES = '612',
    INGRESOS_POR_INTERESES = '614',
    SIN_OBLIGACIONES_FISCALES = '616',
    SOCIEDADES_COOPERATIVAS_DE_PRODUCCION_QUE_OPTAN_POR_DIFERIR_SUS_INGRESOS = '620',
    INCORPORACION_FISCAL = '621',
    ACTIVIDADES_AGRICOLAS_GANADERAS_SILVICOLAS_Y_PESQUERAS = '622',
    OPCIONAL_PARA_GRUPOS_DE_SOCIEDADES = '623',
    COORDINADOS = '624',
    HIDROCARBUROS = '628',
    REGIMEN_DE_ENAJENACION_O_ADQUISICION_DE_BIENES = '607',
    DE_LOS_REGIMENES_FISCALES_PREFERENTES_Y_DE_LAS_EMPRESAS_MULTINACIONALES = '629',
    ENAJENACION_DE_ACCIONES_EN_BOLSA_DE_VALORES = '630',
    REGIMEN_DE_LOS_INGRESOS_POR_OBTENCION_DE_PREMIOS = '615',
    REGIMEN_DE_LAS_ACTIVIDADES_EMPRESARIALES_CON_INGRESOS_A_TRAS_DE_PLATAFORMAS_TECNOLOGICAS = '625',
    REGIMEN_SIMPLIFICADO_DE_CONFIANZA = '626',
}

export enum Export {
    NO_APLICA = '01',
    DEFINITIVA_CON_CLAVE = '02',
    TEMPORAL = '03',
    DEFINITIVA_CON_CLAVE_DISTINTA_A_A1 = '04',
}

export interface InvoiceCustomer {
    legal_name: string
    tax_id: string
    address: AddressDef
    tax_system: TaxSystem
}

export interface TaxElement {
    rate: number
    base?: number
    type: string
    factor: string
    withholding: boolean
    inclusive?: boolean
    id?: string
    ieps_mode?: string
}

export interface LocalTaxElement {
    rate: number
    type: string
    factor: string
    withholding: boolean
}

export interface InvoiceProduct {
    description: string
    product_key: string
    price: number
    tax_included: boolean
    taxes: TaxElement[]
    unit_key: string
    unit_name: string
    sku: string
    local_taxes?: TaxElement[]
}

export interface InvoiceItem {
    quantity: number
    discount: number
    product: object
}

export enum InvoiceType {
    INGRESO = 'I',
    EGRESO = 'E',
    TRASLADO = 'T',
    NOMINA = 'N',
    PAGO = 'P',
}

export interface InvoiceDef {
    uuid: string
    id: string
    from: string
    customer: InvoiceCustomer
    client: ClientDef
    items: InternalItem[]
    internalItems: InternalItem[]
    payment_form: PaymentForm
    invoiceType: string
    type: InvoiceType
    payment_method: PaymentMethod
    use: InvoiceUse
    currency: string
    exchange: number
    conditions?: string
    receipts?: string[]
    payments?: string[]
    related_documents?: object[]
    global?: Global | null
    export?: Export
    complements?: any[]
    date: string
    address?: AddressDef
    external_id?: string
    folio_number: number
    series: string
    pdf_custom_section?: string
    addenda?: string
    team: string
    namespaces?: object[]
    status: string
    livemode?: boolean
    timestamp: number
    total?: number
    cancellation_status?: string
    download?: () => void
    relation?: any
    clientName: string
    invoices: any[]
    metadata: any
    whmcsInvoiceId?: number
}

export interface OptimizedInvoiceDef {
    uuid: string
    id: string
    total: number
    from: string
    amount: number
    items: InternalItem[]
    exchange: number
    currency: string
    customer: InvoiceCustomer
    client: ClientDef
    clientId: string
    clientID: string
    folio_number: number
    invoiceType: string
    series: string
    timestamp: number
    status: string
    use: string
    livemode: boolean
    cancellation_status?: string
    receipts?: string[]
    payments?: string[]
    payment_method?: PaymentMethod
    payment_form?: PaymentForm
    type?: InvoiceType
    internalItems?: InternalItem[]
    clientName: string
    cancellationCodeText?: string
    invoices: any[]
    complements?: any[]
    metadata: any
    installment?: number
    lastBalance?: number
    whmcsInvoiceId?: number
    global?: Global | null
    isGlobal?: boolean
}

export const taxElementSet = (object: any) => {
    const taxElement: TaxElement = {
        rate: object['rate'] ?? null,
        base: object['base'] ?? null,
        type: object['type'] ?? null,
        factor: object['factor'] ?? null,
        withholding: object['withholding'] ?? null,
        inclusive: object['inclusive'] ?? null,
        id:
            (object['rate'] ?? '') +
            (object['type'] ?? '') +
            (object['factor'] ?? '') +
            (object['withholding'] ?? '') +
            (object['inclusive'] ?? ''),
        ieps_mode: object['ieps_mode'] ?? null,
    }
    return taxElement
}

export const invoiceCustomerSet = (o: any = {}) => {
    const object = o ?? {}
    const invoiceCustomer: InvoiceCustomer = {
        legal_name: object['legal_name'] ?? null,
        tax_id: object['tax_id'] ?? null,
        address: addressSet(object['address'] ?? {}) ?? {},
        tax_system: object['tax_system'] ?? null,
    }
    return invoiceCustomer
}

export const invoiceProductSet = (object: any) => {
    const invoiceProduct: InvoiceProduct = {
        description: object['description'] ?? null,
        product_key: object['product_key'] ?? null,
        price: object['price'] ?? null,
        tax_included: object['tax_included'] ?? null,
        taxes: (object['taxes'] ?? []).map((t: object) => taxElementSet(t)) ?? null,
        local_taxes: object['local_taxes'] ?? null,
        unit_key: object['unit_key'] ?? null,
        unit_name: object['unit_name'] ?? null,
        sku: object['sku'] ?? null,
    }
    return invoiceProduct
}

export const invoiceItemSet = (object: any) => {
    const invoiceItem: InvoiceItem = {
        quantity: object['quantity'] ?? null,
        discount: object['discount'] ?? null,
        product: invoiceProductSet(object['product'] ?? []) ?? null,
    }
    return invoiceItem
}

export const invoiceDefSet = (object: any) => {
    if (!object) return {} as InvoiceDef

    const invoice: InvoiceDef = {
        uuid: object['uuid'] ?? null,
        id: object['id'] ?? null,
        from: object['from'] ?? null,
        livemode: object['livemode'] ?? null,
        invoiceType: object['invoiceType' ?? 'type'] ?? 'I',
        customer: invoiceCustomerSet(object['customer'] ?? {}) ?? null,
        client: clientSet(object['client'] ?? {}),
        items: (object['items'] ?? []).map((i: object) => internalItemSet(i)) ?? null,
        internalItems: (object['internalItems'] ?? []).map((i: object) => internalItemSet(i)) ?? null,
        payment_form: object['payment_form'] ?? null,
        type: object['type'] ?? null,
        payment_method: object['payment_method'] ?? null,
        use: object['use'] ?? null,
        currency: object['currency'] ?? null,
        receipts: object['receipts'] ?? null,
        payments: object['payments'] ?? null,
        exchange: object['exchange'] ?? null,
        conditions: object['conditions'] ?? null,
        related_documents: object['related_documents'] ?? [],
        global: object.global ? globalSet(object['global']) : null,
        export: object['export'] ?? null,
        complements: object['complements'] ?? null,
        date: object['date'] ?? null,
        address: addressSet(object['address'] ?? {}) ?? {},
        external_id: object['external_id'] ?? null,
        team: object['team'] ?? null,
        folio_number: object['folio_number'] ?? null,
        series: object['series'] ?? null,
        pdf_custom_section: object['pdf_custom_section'] ?? null,
        addenda: object['addenda'] ?? null,
        status: object['status'] ?? null,
        namespaces: object['namespaces'] ?? null,
        timestamp: object['timestamp'] ?? null,
        total: object['total'] ?? null,
        cancellation_status: object['cancellation_status'] ?? null,
        invoices: object['invoices'] ?? [],
        clientName: object['client'] ? object['client']['legal_name'] ?? null : null,
        metadata: object['metadata'] ?? null,

        download: async () => {
            //TODO: ADD METHODS FOR EVERYTHING
            return
        },
        relation: object['relation'] ?? null,
        whmcsInvoiceId: object['whmcsInvoiceId'] ?? null,
    }
    return invoice
}

export const optimizedInvoiceDefSet = (object: any) => {
    const legal_name = (object['customer'] ?? {
        legal_name: '',
    })['legal_name']
    const optimizedInvoice: OptimizedInvoiceDef = {
        uuid: object['uuid'] ?? null,
        id: object['id'] ?? null,
        exchange: object['exchange'] ?? null,
        total: object['total'] ?? null,
        from: object['from'] ?? null,
        invoiceType: object['invoiceType' ?? 'type'] ?? 'I',
        amount: object['total'] ?? null,
        receipts: object['receipts'] ?? null,
        payments: object['payments'] ?? null,
        currency: object['currency'] ?? null,
        customer: invoiceCustomerSet(object['customer'] ?? {}) ?? null,
        client: clientSet(object['client'] ?? {}),
        clientId: object['clientId'] ?? object['clientID'] ?? null,
        clientID: object['clientId'] ?? object['clientID'] ?? null,
        folio_number: object['folio_number'] ?? null,
        series: object['series'] ?? null,
        timestamp: object['timestamp'] ?? null,
        status: object['status'] ?? null,
        use: object['use'] ?? null,
        invoices: object['invoices'] ?? [],
        livemode: object['livemode'] ?? null,
        cancellation_status: object['cancellation_status'] ?? null,
        payment_method: object['payment_method'] ?? null,
        payment_form: object['payment_form'] ?? null,
        items: (object['items'] ?? []).map((i: object) => internalItemSet(i)) ?? null,
        internalItems: object['internalItems'] ?? [] ?? null,
        clientName: legal_name,
        cancellationCodeText: object['cancellationCodeText'] ?? null,
        complements: object['complements'] ?? null,
        metadata: object['metadata'] ?? null,
        installment: object['installment'] ?? null,
        lastBalance: object['lastBalance'] ?? null,
        whmcsInvoiceId: object['whmcsInvoiceId'] ?? null,
        global: object.global ? globalSet(object['global']) : null,
        isGlobal: object['isGlobal'] ?? false,
    }
    return optimizedInvoice
}
