import { TeamDef } from '../../../interfaces/teamDef'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { SearchParamInURL } from '../../functions/helpers'

export const getStripeConnectLink = async ({ team, auth }: { team: TeamDef; auth: any }) => {
    try {
        let link = null
        if (!team?.stripe?.completed) {
            link = {
                url: 'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_G17KI523sJrtpFv6vEiBTeggOTaGUWBG&scope=read_write',
            }
        } else if (team?.stripe) {
            link = await SignedInternalAPIRequest(
                {
                    teamId: team.id,
                    billingAccountId: team.billingAccount,
                    type: 'onboarding_link',
                    redirectUrl: window.location.href.includes('https')
                        ? window.location.href
                        : 'https://app.gigstack.pro',
                    refreshUrl: window.location.href.includes('https')
                        ? window.location.href
                        : 'https://app.gigstack.pro',
                    cancelUrl: window.location.href.includes('https')
                        ? window.location.href
                        : 'https://app.gigstack.pro',
                },
                'stripeActions',
                auth.currentUser,
            )
        }
        return link
    } catch (error) {
        throw error
    }
}

export const finishStripeConnection = async ({
    setLoading = (n: boolean) => {},
    authUser,
}: {
    setLoading: (n: boolean) => void
    authUser: any
}) => {
    const platform = SearchParamInURL('platform')
    if (platform === 'stripe') {
        const code = SearchParamInURL('code')
        const CreateConnectionStripe = async () => {
            try {
                const link = await SignedInternalAPIRequest(
                    {
                        type: 'connect_oauth',
                        code,
                        redirectUrl: 'https://app.gigstack.pro/settings?subtab=integrations',
                        refreshUrl: 'https://app.gigstack.pro/settings?subtab=integrations',
                    },
                    'stripeActions',
                    authUser,
                )
                setLoading(false)

                return window.open(link.url, '_self')
            } catch (error) {
                setLoading(false)
            }
        }
        if (code) {
            setLoading(true)
            CreateConnectionStripe()
        }
    }
}
