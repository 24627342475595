import { ClientDef, clientSet } from './clientDef'
import { Payment } from './paymentsDef'
import { InvoiceDef } from './invoicesDef'

export interface RecurringEventCalendarDef {
    onDay?: number
    onTime?: string
    onWeekday?: any
    startDate?: number
    endDate?: number
    nextRunUtc?: number
    nextRun?: number
    startStringDate?: string
    temporality?: 'monthly' | 'weekly' | 'daily' | 'yearly' | 'once'
    completed: boolean
}

const checkRecurringEventCompletion = (object: any) => {
    if (!object) return false
    const { onTime, startDate, endDate, temporality } = object
    if (onTime && startDate && endDate && temporality) {
        return true
    } else return false
}

export const RecurringEventCalendarDefSet = (object: any) => {
    const recurringEventCalendar: RecurringEventCalendarDef = {
        onDay: object['onDay'] ?? null,
        onTime: object['onTime'] ?? null,
        onWeekday: object['onWeekday'] ?? null,
        startDate: object['startDate'] ?? null,
        endDate: object['endDate'] ?? null,
        nextRunUtc: object['nextRunUtc'] ?? null,
        startStringDate: object['startStringDate'] ?? null,
        temporality: object['temporality'] ?? null,
        nextRun: object['nextRun'] ?? null,
        completed: checkRecurringEventCompletion(object),
    }
    return recurringEventCalendar
}

export interface Recurring {
    actions?: string[]
    billingAccount: string
    client: ClientDef | null
    clientId: string | null
    clientID: string | null
    elementType: string
    endDate: number
    endStringDate: string
    endDateUTC: number
    fid: string
    id: string
    incomingNextRun?: any
    lastUpdate: number
    nextHits: string[]
    nextRun?: any
    nextRunString?: string
    nextRunUTC?: number
    onDay?: number
    onTime: string
    onWeekday?: any
    owner: string
    replicateElement: Payment | InvoiceDef
    startDate: number
    startStringDate: string
    startDateUTC: number
    startEndDate?: any
    status: string
    team: string
    timestamp: number
    timestampUTC: number
    temporality: string
    type: string
    updateList: string[]
    relations?: string[]
    pausedReason?: string
    localChanges?: boolean
    errors: any[]
    errorMessages: any[]
}

export interface OptimizedReccuringDef {
    id: string
    status: string
    amount: number
    clientId: string
    client: ClientDef | null
    temporality: string
    timestamp: number
    event: string
    nextRun: string
    relations?: string[]
    errors: any[]
    errorMessages: any[]
}

export const RecurringSet = (object: any) => {
    const recurring: Recurring = {
        actions: object['actions'] ?? [],
        billingAccount: object['billingAccount'] ?? null,
        client: object['client'] ? clientSet(object['client']) : null,
        clientId: object['clientId'] ?? object['clientID'] ?? null,
        clientID: object['clientID'] ?? object['clientId'] ?? null,
        elementType: object['elementType'] ?? null,
        endDate: object['endDate'] ?? null,
        endStringDate: object['endStringDate'] ?? null,
        endDateUTC: object['endDateUTC'] ?? null,
        fid: object['fid'] ?? object['id'],
        id: object['id'] ?? object['fid'],
        incomingNextRun: object['incomingNextRun'] ?? null,
        lastUpdate: object['lastUpdate'] ?? null,
        nextHits: object['nextHits'] ?? [],
        nextRun: object['nextRun'] ?? null,
        nextRunString: object['nextRunString'] ?? null,
        nextRunUTC: object['nextRunUTC'] ?? null,
        onDay: object['onDay'] ?? null,
        onTime: object['onTime'] ?? null,
        onWeekday: object['onWeekday'] ?? null,
        owner: object['owner'] ?? null,
        replicateElement: object['replicateElement'] ?? null,
        startDate: object['startDate'] ?? null,
        startEndDate: object['startEndDate'] ?? null,
        startStringDate: object['startStringDate'] ?? null,
        startDateUTC: object['startDateUTC'] ?? null,
        status: object['status'] ?? null,
        team: object['team'] ?? null,
        timestamp: object['timestamp'] ?? null,
        timestampUTC: object['timestampUTC'] ?? null,
        temporality: object['temporality'] ?? null,
        type: object['type'] ?? null,
        updateList: object['updateList'] ?? [],
        relations: object['relations'] ?? [],
        pausedReason: object['pausedReason'] ?? null,
        localChanges: object['localChanges'] ?? false,
        errors: object['errors'] ?? [],
        errorMessages: object['errorMessages'] ?? [],
    }
    return recurring
}

export const optimizedRecurringDefSet = (object: any) => {
    const paymentRecurring: OptimizedReccuringDef = {
        id: object['fid'] ?? object['id'],
        status: object['status'] ?? null,
        amount: object['amount'] ?? null,
        clientId: object['clientId'] ?? null,
        client: object['client'] ? clientSet(object['client']) : null,
        temporality: object['temporality'] ?? null,
        timestamp: object['timestamp'] ?? null,
        event: object['event'] ?? null,
        nextRun: object['nextRun'] ?? null,
        relations: object['relations'] ?? [],
        errors: object['errors'] ?? [],
        errorMessages: object['errorMessages'] ?? [],
    }

    return paymentRecurring
}
