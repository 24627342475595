import { Col, Input, message, Row, Table, Tag, Tooltip, Typography } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { getItemsAmounts } from "../../functions/helpers"
import { CheckCircle, DotsThree, SpinnerGap, Warning } from "@phosphor-icons/react"
import moment from "moment"
import { openModal } from "../../context/modalsSlice"
import { setData } from "../../context/dataSlice"
import { FixedType } from "rc-table/lib/interface"
import { useState } from "react"
import { InvoicesColumns } from "./Columns/InvoicesColumns"
import { useFirestoreCollectionData } from "reactfire"
import { collection, getFirestore, orderBy, query, where } from "firebase/firestore"
import { paymentForms } from "../../datasets/Fiscal"

export const MassiveInvoiceStatusTable = () => {
    const { massiveInvoiceStatus }= useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)
    const dispatch = useDispatch()

    const [search, setSearch] = useState<string>('')

    const q = query(
            collection(getFirestore(), `massiveActions/${massiveInvoiceStatus?.id}/docs`),
            where('team', '==', team?.id || ''),
            orderBy('timestamp', 'desc'),
          )


    const { data, status } = useFirestoreCollectionData<any>(q, {
        idField: 'id'
    })

    async function handleClickOnFailedInvoice (failedInvoice: any) {
        try {
            if (failedInvoice) {
                dispatch(setData({item: "preloadedFailedInvoiceData", data: failedInvoice}))
            } else {
                throw new Error("Error al cargar la factura")
            }
            dispatch(openModal("editFailedMassInvoiceVisible"))
        } catch (error: any) {
            console.log(error)
            message.error(error.message ?? "Error al cargar la factura")
        }   
    }

    
    function renderActionsDocs({record, route}: {record: any, route: string}) {

        const errorText = record.error || null

        return (
            <Row align="middle" justify="space-between">
                <Tooltip title={errorText || 'Factura'}>
                    <Row>
                        {errorText ? <Warning size={20} className="icon" /> : <></>}
                        <Tag
                            title={errorText || 'Factura'}
                            style={{ marginLeft: '5px' }}
                            className="bg-white rounded-full"
                        >
                            <Typography.Text className="p-small-medium">
                                {errorText ? 'Información fiscal incorrecta' : '1 Factura'}
                            </Typography.Text>
                        </Tag>
                    </Row>
                </Tooltip>
                <DotsThree style={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => {
                    if (errorText) {
                        handleClickOnFailedInvoice({
                            ...record, 
                            route: `massiveActions/${massiveInvoiceStatus?.id}/docs/${record.id}`
                        })
                    } else {
                        message.info('La factura se creó exitosamente')
                    }
                }}/>
            </Row>
        )
    }

    const fixedPosition: FixedType = "right"

    const handleInvoiceStatus = (status: string) => {
        switch (status) {
            case 'error':
                return  <Warning size={20} />
            case 'completed':
                return <CheckCircle size={20} />
            case 'processing':
                return <SpinnerGap size={20} />
            default: 
                return <></>
        }
    }

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'data',
            key: 'legal_name',
            render: (data: { client: { legalName: string } }) => {
                return data.client.legalName ? data.client.legalName : '---'
            },
        },
        {
            title: 'Total',
            dataIndex: 'data',
            key: 'total',
            render: (data: { items: any[] }) => {
                const { totalString } = getItemsAmounts(data.items, false)
                return totalString
            },
        },
        {
            title: 'Estatus',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => {
                return handleInvoiceStatus(status)
            }
        },
        {
            title: 'Fuente',
            dataIndex: 'from',
            key: 'from',
            render: (from: string, record: any) => InvoicesColumns({ record, columnName: 'from' }),
            
        },
        {
            title: 'Tipo',
            dataIndex: 'invoiceType',
            key: 'invoiceType',
            render: (invoiceType: string, record: any) => {
                return InvoicesColumns({ record: {
                    ...(record?.data || {}),
                }, columnName: 'invoiceType' })
            },
        },
        {
            title: 'Folio',
            dataIndex: 'folio_number',
            key: 'folio_number',
            render: (folio_number: string) => {
                return folio_number || '---'
            },
        },
        {
            title: "Método de Pago",
            dataIndex: "payment_method",
            key: "payment_method",
            render: (pm: string, record: any)=> {
                const { payment_method, payment_form } = record?.data
                const readablePaymentForm = paymentForms.find((pf) => pf?.value === payment_form)?.label || payment_form || ""
                return (payment_form && payment_method) ? `${payment_method} - ${readablePaymentForm}` : "---" 
            }
        },
        {
            title: 'Fecha de Emisión',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (timestamp: number, record: any) => {
                if(record?.status !== 'completed') {
                    return '---'
                }
                return moment(timestamp).format('DD/MM/YYYY HH:mm')
            },
        },
        {
            title: 'Acciones/Documentos',
            dataIndex: 'actionsDocs',
            key: 'actions',
            fixed: fixedPosition,
            render: (actions: any, record: any) => renderActionsDocs({
                record,
                route: `massiveActions/${massiveInvoiceStatus?.id}/docs/${record.id}`
            }),
        },
    ]

    return(
        <Col>
        <Row style={{  
            marginBottom: '10px'
        }}>
            <Input
                placeholder="Buscar..."
                style={{ maxWidth: '25%', minWidth: '300px' }}
                onChange={(v) => {
                    const value = v.target.value
                    setSearch(value.toLocaleLowerCase())
                }}
            />
        </Row>
            <Table
                columns={columns}
                dataSource={(data || [])?.filter((invoice: any) => {
                    return (
                        (invoice.data?.client?.legalName || '').toLowerCase().includes(search) ||
                        (String(invoice?.data?.folio_number) || '').includes(search) ||
                        (invoice?.data?.from || '').toLowerCase().includes(search) ||
                        (invoice?.data?.payment_method || '').toLowerCase().includes(search) ||
                        (invoice?.data?.payment_form || '').toLowerCase().includes(search) ||
                        (invoice?.status || '').toLowerCase().includes(search) ||
                        (String(invoice?.data?.total) || '').toLowerCase().includes(search)
                    )
                })}
                pagination={false}
                scroll={{x: "max-content"}}
                style={{ width: "100%" }}
                loading={status === 'loading'}
            />
        </Col>
    )
}