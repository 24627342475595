import { useSelector } from 'react-redux'
import { SearchParamInURL } from '../functions/helpers'
import { collection, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { Button, Col, Form, FormProps, message, Row, Typography } from 'antd'
import Step1Gif from '../../assets/images/finishSetup1.gif'
import { CheckBoxInput, SelectInput } from '../components/Forms/Inputs'

export const OAuthView = () => {
    const redirectUrl = SearchParamInURL('redirectUrl')
    const source = SearchParamInURL('source')

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { user } = useSelector((state: any) => state.user)

    const [name] = useState(user?.firstName ?? '')
    const [selectOptions, setSelectOptions] = useState<any[]>([])
    const [loading, setLoading] = useState(false)

    const { team } = useSelector((state: any) => state.team)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    const teamsQuery = query(
        collection(getFirestore(), 'teams'),
        where('billingAccount', '==', billingAccount.id),
        orderBy('timestamp', 'desc'),
    )

    useEffect(() => {
        const getTeams = async () => {
            const teams = await getDocs(teamsQuery)
            setSelectOptions(
                teams.docs.map((doc) => {
                    const data = doc.data()
                    return {
                        label: data.brand.alias,
                        value: doc.id,
                    }
                }),
            )
        }

        getTeams()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team?.id])

    const onFinish: FormProps['onFinish'] = async (body) => {
        setLoading(true)
        try {
            const { teams, livemode } = body
            const q = query(
                collection(getFirestore(), 'apikeys'),
                where('team', '==', teams),
                where('valid', '==', true),
            )
            const apiKeys = await getDocs(q)
            const keys = apiKeys.docs.map((doc) => doc.data())

            if (!keys.length) message.error('No se encontraron claves de API válidas para este equipo')
            const keyId = keys.find((key) => key.livemode !== Boolean(livemode))?.apikey
            const decodedUri = decodeURIComponent(redirectUrl ?? '')

            if (redirectUrl?.includes('?')) {
                window.open(`${decodedUri}&team=${teams}&key=${keyId}`, '_self')
            } else {
                window.open(`${decodedUri}?team=${teams}&key=${keyId}`, '_self')
            }

            setLoading(false)
        } catch (error) {
            setLoading(false)
            message.error('No se encontraron claves de API válidas para este equipo')
        }
    }

    return (
        <div className="bg-dots bg-neutral-6">
            <Row justify="center" align="middle">
                <Col
                    xs={24}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        overflow: 'hidden',
                    }}
                >
                    <Row>
                        <Col xs={24} lg={12} style={{ padding: '30px' }} className="shadow-lg rounded-lg bg-white">
                            <div className="d-flex flex-column" style={{ marginTop: '20px', marginBottom: '40px' }}>
                                <Typography.Text className="h5-bold neutral-1">Selecciona un team</Typography.Text>
                                <div style={{ marginTop: '20px' }}>
                                    <Form layout="vertical" onFinish={onFinish}>
                                        <SelectInput
                                            name="teams"
                                            label="Team a conectar"
                                            rules={[{ required: true, message: 'Este campo es requerido' }]}
                                            options={selectOptions}
                                        />

                                        <CheckBoxInput
                                            name="livemode"
                                            content={
                                                <Typography.Text className="p-base-regular neutral-3">
                                                    Conectar en modo prueba
                                                </Typography.Text>
                                            }
                                        />

                                        <Button className="btn-primary w-100" htmlType="submit" loading={loading}>
                                            Conectar
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </Col>

                        <Col xs={0} lg={12} style={{ overflow: 'hidden' }}>
                            <div style={{ height: '100%', padding: '0px 20px' }}>
                                <div style={{ padding: '30px', borderRadius: '10px' }} className="d-flex flex-column">
                                    <img style={{ width: '110px' }} src={Step1Gif} alt="Information setup animation" />
                                    <Typography.Text
                                        className="h4-bold neutral-1"
                                        style={{ padding: 0, marginTop: '40px' }}
                                    >
                                        Hola {name}
                                    </Typography.Text>
                                    <Typography.Text
                                        className="h6-regular neutral-2 fade-in-animation"
                                        style={{ marginTop: '15px' }}
                                    >
                                        Nos encanta tenerte aquí en gigstack, comencemos seleccionando tu team a
                                        conectar en {source}.
                                    </Typography.Text>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
