import React, { useEffect, useState } from 'react'
import { Row, Col, Typography, Button, Divider, Avatar } from 'antd'
import { Check } from '@phosphor-icons/react'
import SatModalGs from '../modals/gettingStarted/SatModalGettingStarted'
import DefaultsModal from '../modals/gettingStarted/DefaultsModal'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { GetTeamHelper } from '../context/databaseContextHelpers'
import { teamCompleteGettingStarted } from '../../helpers/helperFunctions'

const GettingStarted: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { team } = useSelector((state: any) => state.team)
    const fs = getFirestore()

    const [satModal, setSatModal] = useState(false)
    const [defaultsModal, setDefaultsModal] = useState(false)
    const [completedSteps, setCompletedSteps] = useState({
        sat: false,
        defaults: false,
        integrations: false,
    })

    const handleStepCompletion = async (step: 'sat' | 'defaults') => {
        setCompletedSteps((prevState) => ({
            ...prevState,
            [step]: true,
        }))

        if (step === 'sat') {
            setSatModal(false)
            setDefaultsModal(true)
        } else if (step === 'defaults') {
            await updateDoc(doc(fs, `teams/${team?.id}`), { 'defaults.completed': true })
            setDefaultsModal(false)
            GetTeamHelper(team?.id, dispatch)
            navigate('/integrations')
        }
    }

    useEffect(() => {
        if (team) {
            const gettingStartedStatus = teamCompleteGettingStarted(team)

            setCompletedSteps({
                sat: gettingStartedStatus.completedSteps.includes('facturapi'),
                defaults: gettingStartedStatus.completedSteps.includes('defaults'),
                integrations: gettingStartedStatus.integrationIsConnected,
            })
        }
    }, [team])

    return (
        <>
            <SatModalGs
                isOpen={satModal}
                onClose={() => setSatModal(false)}
                onStepCompletion={() => handleStepCompletion('sat')}
            />

            <DefaultsModal
                isOpen={defaultsModal}
                onClose={async (step: number) => {
                    if (step === 6) {
                        await updateDoc(doc(fs, `teams/${team?.id}`), { 'defaults.completed': true })
                        GetTeamHelper(team?.id, dispatch)
                    }
                    setDefaultsModal(false)
                }}
                onStepCompletion={() => handleStepCompletion('defaults')}
            />

            <Row justify="center" align="middle" style={{ paddingTop: '80px' }}>
                <Col>
                    <Typography.Text className="h5-bold neutral-1" style={{ marginBottom: '50px' }}>
                        Tus primeros pasos en gigstack
                    </Typography.Text>
                    {/* SAT */}
                    <Row style={{ alignItems: 'center', padding: '20px 0px' }} gutter={[16, 16]}>
                        <Col>
                            <Avatar size={30} className={completedSteps.sat ? 'bg-neutral-1' : 'bg-neutral-5'}>
                                <Typography.Text className="p-small-bold neutral-1">
                                    {completedSteps.sat ? <Check className="white" /> : 1}
                                </Typography.Text>
                            </Avatar>
                        </Col>
                        <Col flex="auto">
                            <Typography.Text className="p-large-bold neutral-1">Conéctate al SAT</Typography.Text>
                            <br />
                            <Typography.Text className="p-base-regular neutral-3">
                                Autoriza a gigstack para acceder a tu cuenta del SAT y automatizar la generación de
                                facturas.
                            </Typography.Text>
                        </Col>
                        <Col>
                            <Button
                                className={!completedSteps.sat ? 'btn-primary' : 'btn-secondary'}
                                style={{ marginTop: '20px' }}
                                onClick={() => setSatModal(true)}
                                disabled={completedSteps.sat}
                            >
                                Conecta tu cuenta
                            </Button>
                        </Col>
                    </Row>
                    <Divider />
                    {/* IMPUESTOS */}
                    <Row style={{ alignItems: 'center', padding: '20px 0px' }} gutter={[16, 16]}>
                        <Col>
                            <Avatar size={30} className={completedSteps.defaults ? 'bg-neutral-1' : 'bg-neutral-5'}>
                                <Typography.Text className="p-small-bold neutral-1">
                                    {completedSteps.defaults ? <Check className="white" /> : 2}
                                </Typography.Text>
                            </Avatar>
                        </Col>
                        <Col flex="auto">
                            <Typography.Text className="p-large-bold neutral-1">
                                Define tus impuestos <span className="red-5">*</span>
                            </Typography.Text>
                            <br />
                            <Typography.Text className="p-base-regular neutral-3">
                                Revisa y configura los datos de tu empresa y los impuestos predeterminados para tus
                                facturas.
                            </Typography.Text>
                        </Col>
                        <Col>
                            <Button
                                className={
                                    completedSteps.sat && !completedSteps.defaults ? 'btn-primary' : 'btn-secondary'
                                }
                                style={{ marginTop: '20px' }}
                                disabled={!completedSteps.sat || completedSteps.defaults}
                                onClick={() => setDefaultsModal(true)}
                            >
                                Definir Impuestos
                            </Button>
                        </Col>
                    </Row>
                    <Divider />
                    {/* Ingresos */}
                    <Row style={{ alignItems: 'center', padding: '20px 0px' }} gutter={[16, 16]}>
                        <Col>
                            <Avatar size={30} className={completedSteps.integrations ? 'bg-neutral-1' : 'bg-neutral-5'}>
                                <Typography.Text className="p-small-bold neutral-1">
                                    {completedSteps.integrations ? <Check className="white" /> : 2}
                                </Typography.Text>
                            </Avatar>
                        </Col>
                        <Col flex="auto">
                            <Typography.Text className="p-large-bold neutral-1">
                                Conecta una fuente de ingresos <span className="red-5">*</span>
                            </Typography.Text>
                            <br />
                            <Typography.Text className="p-base-regular neutral-3">
                                Vincula tu tienda de Stripe u otra plataforma de ingresos para sincronizar y automatizar
                                tus ventas.
                            </Typography.Text>
                        </Col>
                        <Col>
                            <Button
                                className={
                                    completedSteps.defaults && !completedSteps.integrations
                                        ? 'btn-primary'
                                        : 'btn-secondary'
                                }
                                style={{ marginTop: '20px' }}
                                disabled={!completedSteps.defaults || completedSteps.integrations}
                                onClick={() => navigate('/integrations')}
                            >
                                Seleccionar Fuente
                            </Button>
                        </Col>
                    </Row>
                    <Divider />
                </Col>
            </Row>
        </>
    )
}

export default GettingStarted
