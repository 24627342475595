import { Button, Divider, Form, message, Modal, Row, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'
import { IntegrationOnboarding } from '../../components/Integrations/IntegrationOnboarding'
import { integrations } from '../../datasets/Integrations'
import { useEffect, useState } from 'react'
import { addParamToUrl } from '../../functions/UrlParams'
import { Copy, Robot } from '@phosphor-icons/react'
import SwitchHolder from '../../components/Forms/SwitchHolder'
import { updateAutomaticInvoicingIntegration } from './helpers'

export const WoocomerceModal = () => {
    const dispatch = useDispatch()
    const { woocomerceModalVisible } = useSelector((state: any) => state.modals)
    const { team } = useSelector((state: any) => state.team)
    const { testmode } = useSelector((state: any) => state.data)

    const integration = integrations(team).find((i) => i.id === 'woocommerce')
    const [step, setstep] = useState(team?.woocomerce?.completed ? 1 : 0)
    const [automaticInvoicing, setAutomaticInvoicing] = useState(team?.woocomerce?.automaticInvoicing ?? false)
    const [form] = Form.useForm()

    useEffect(() => {
        if (woocomerceModalVisible) {
            addParamToUrl('open', 'woocomerce')
        }
    }, [woocomerceModalVisible])

    const ToRender = () => {
        switch (step) {
            case 0:
                return (
                    <IntegrationOnboarding
                        logo={integration?.logo ?? ''}
                        title="Conecta Woocomerce con gigstack"
                        description="Cobra y factura automáticamente con Woocomerce"
                        bullets={[
                            { type: 'positive', description: 'Facturar automáticamente tus ventas' },
                            { type: 'positive', description: 'Sincroniza automáticamente tus ventas' },
                        ]}
                        IntegrationFooter={
                            <Row justify="space-between" style={{ padding: '20px 24px 20px 24px' }}>
                                <Row>
                                    {/** TODO: Agregar funcionalidad */}
                                    {/* <Button  icon={<Question className="icon clickable" weight="regular" size={16} />}>
                                <Typography.Text className="mediumparagraphbold">¿Cómo funciona?</Typography.Text>
                            </Button> */}
                                </Row>
                                <Row>
                                    <Button type="primary" onClick={() => setstep(step + 1)}>
                                        Conectar
                                    </Button>
                                </Row>
                            </Row>
                        }
                    />
                )
            case 1:
                return (
                    <div style={{ padding: '20px 24px' }}>
                        <Typography.Text className="mediumparagraphbold">
                            Conecta tu tienda en Woocomerce
                        </Typography.Text>
                        <Typography.Text className="smallparagraph descriptions">
                            Conecta tu tienda en línea y automatiza todas las transacciones que recibas.
                        </Typography.Text>

                        <Form form={form} layout="vertical" style={{ marginTop: '15px' }} onFinish={() => {}}>
                            {/* <TextInput
                                label="Cuenta CLABE"
                                name="clabe"
                                onChange={(v: any) => {
                                    const clabeV = clabe.validate(v.target.value)
                                    console.log(clabeV)
                                    if (clabeV.ok) {
                                        form.setFieldsValue({ bank: clabeV.tag })
                                    } else {
                                        form.setFieldsValue({ bank: '' })
                                    }
                                }}
                                rules={[{ required: true, message: 'Es necesario capturar la cuenta CLABE' }]}
                            /> */}

                            <Divider />
                            <SwitchHolder
                                changeState={async () => {
                                    await updateAutomaticInvoicingIntegration({
                                        automaticInvoicing,
                                        dispatch,
                                        setAutomaticInvoicing,
                                        team,
                                        integrationName: 'woocomerce',
                                    })
                                }}
                                switchState={automaticInvoicing}
                                icon={<Robot className="icon clickable" weight="regular" size={16} />}
                                title={'Activar facturación automática'}
                                description="Intentaremos facturar automáticamente tus ventas, si no es posible, crearemos un recibo de venta para que tu cliente genere su factura"
                            />
                            <div style={{ marginTop: '20px' }}></div>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    padding: '15px',
                                    border: '1px dotted #E0E0E0',
                                    borderRadius: '5px',
                                }}
                            >
                                <Row align="middle">
                                    <Typography.Text className="smallparagraph" style={{ flex: 1 }}>
                                        https://us-central1-gigstackpro.cloudfunctions.net/woocomerceconnection/v1/
                                        {testmode ? 'test' : 'live'}/{team.id}
                                    </Typography.Text>
                                    <Copy
                                        className="icon clickable"
                                        weight="regular"
                                        size={16}
                                        style={{ marginLeft: '10px' }}
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                `https://us-central1-gigstackpro.cloudfunctions.net/woocomerceconnection/v1/${testmode ? 'test' : 'live'}/${team.id}`,
                                            )
                                            message.success('URL copiada al portapapeles')
                                        }}
                                    />
                                </Row>
                            </div>
                            <Typography.Text className="smallparagraph descriptions" style={{ marginTop: '10px' }}>
                                Copia y pega esta URL en tu tienda en línea para conectarla con gigstack. En
                                Woocommerce, ve a Configuración {'>'} Ajustes {'>'} Avanzado {'>'} Webhooks {'>'}{' '}
                                Agregar Webhook y selecciona “Pedido Actualizado” en “Tema”. Repite el proceso para
                                “Pedido Creado”.
                            </Typography.Text>
                        </Form>
                    </div>
                )
            default:
                return <></>
        }
    }

    return (
        <Modal
            open={woocomerceModalVisible}
            onCancel={() => {
                dispatch(closeModal('woocomerceModalVisible'))
            }}
            className="integrationModal"
            title={null}
            footer={null}
            style={{ top: 20 }}
        >
            <ToRender />
        </Modal>
    )
}
