import { Button, Input, InputRef, Row, Spin, Tabs } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MagnifyingGlass } from '@phosphor-icons/react'
import { SearchIndexComponent } from './IndexComponent'
import { setData } from '../context/dataSlice'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { TeamDef } from '../../interfaces/teamDef'

const getData = async ({
    collection,
    query,
    testmode,
    dispatch,
    team,
    setLoading,
}: {
    collection: string
    query: string
    testmode: boolean
    dispatch: any
    team: TeamDef
    setLoading: (v: any) => void
}) => {
    setLoading(true)
    const data = httpsCallable(getFunctions(), 'searchapp')
    const response = await data({ query: query, collection, apiKey: team?.typesense?.keys?.live_key, testmode })
    dispatch(setData({ item: 'searchResult', data: response.data }))
    setLoading(false)
}

const ComponentSearchAlgolia = ({ indexName, teamId, type }: { indexName?: any; teamId?: any; type?: any }) => {
    const dispatch = useDispatch()
    const { user } = useSelector((state: any) => state.user)
    const { team } = useSelector((state: any) => state.team)
    const { testmode, searchResult } = useSelector((state: any) => state.data)
    const [query, setQuery] = useState('')
    const [IsOpen, setIsOpen] = useState(false)
    const [activeTab, setActiveTab] = useState('')
    const [loading, setLoading] = useState(false)
    const componentRef = useRef<HTMLDivElement>(null)

    const searchResultsItems = [
        {
            key: 'invoices',
            label: 'Facturas',
            children: <SearchIndexComponent indexName="invoices" />,
        },
        {
            key: 'payments',
            label: 'Pagos',
            children: <SearchIndexComponent indexName="payments" />,
        },
        {
            key: 'receipts',
            label: 'Recibos de venta',
            children: <SearchIndexComponent indexName="receipts" />,
        },
    ]

    const changeTab = (type: string) => {
        setActiveTab(type)
        getData({
            query: query,
            collection: type,
            testmode,
            dispatch,
            team,
            setLoading,
        })
    }

    const searchData = useCallback(() => {
        dispatch(
            setData({
                item: 'search',
                data: {
                    type: activeTab,
                    query: query,
                },
            }),
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab])

    const chooseSearchType = (text: string) => {
        const value = text.split('_')[0].toLowerCase()
        switch (value) {
            case 'pi':
            case 'ch':
            case 'payment':
                setActiveTab(() => 'payments')
                break
            case 'receipt':
                setActiveTab(() => 'receipts')
                break
        }
    }

    const changeCaptureHandler = (event: any) => {
        if (event.length >= 1) setQuery(() => event)
        chooseSearchType(event)
        setIsOpen(true)
        getData({
            query: event,
            collection: activeTab,
            testmode,
            dispatch,
            team,
            setLoading,
        })
    }

    const keyDownHandler = (event: any) => {
        if (event.key === 'Enter') {
            // setIsOpenByEnter(true)
            searchData()
        }
    }

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (componentRef.current && event.target instanceof Node && !componentRef.current.contains(event.target)) {
                setIsOpen(false)
                // setIsOpenByEnter(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [componentRef])

    useEffect(() => {
        setActiveTab((user?.interfaceSettings ?? {})[team?.id]?.searchCollection ?? 'invoices')
    }, [user, team])

    useEffect(() => {
        searchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    const inputRef = useRef<InputRef>(null)

    return (
        <div ref={componentRef} className="main-search--container">
            <Input.Search
                style={{ marginTop: '15px' }}
                ref={inputRef}
                placeholder={`Buscar...`}
                onKeyDown={(event: any) => keyDownHandler(event)}
                // onSearch={(event: any) => changeCaptureHandler(event)}
                onSearch={(event: any) => changeCaptureHandler(event)}
                enterButton={
                    <Button type="primary">
                        <MagnifyingGlass size={16} />
                    </Button>
                }
            />

            {searchResult && IsOpen && (
                <div className="d-flex flex-column" style={{ position: 'relative' }}>
                    <div className="searchResultsContainer">
                        {!loading ? (
                            <Tabs
                                defaultActiveKey={activeTab}
                                activeKey={activeTab}
                                items={searchResultsItems}
                                onChange={(type) => changeTab(type)}
                            />
                        ) : (
                            <Row align="middle" justify="center" style={{ margin: '15px' }}>
                                <Spin />
                            </Row>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default ComponentSearchAlgolia
