import React, { useEffect, useState } from 'react'
import { Layout, Row, Col, Typography, Switch, Button, Table, message, Modal, Spin, Alert } from 'antd'
import { returnCurrencyValue } from '../functions/helpers'
import { CheckCircle } from '@phosphor-icons/react/dist/ssr'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { collection, getDocs, getFirestore } from 'firebase/firestore'
import { usePostHog } from 'posthog-js/react'
import { getBillingData } from '../functions/Billing'

const CouponModal = () => {
    const posthog = usePostHog()
    const [searchParams] = useSearchParams()
    const code = searchParams.get('code')
    const only = searchParams.get('customPriceId')

    const [openCoupon, setOpenCoupon] = useState(code !== null && code !== undefined && !only)
    const [coupon, setCoupon] = useState<any>(null)
    const requestCoupon = httpsCallable(getFunctions(), 'membershipsapp')

    useEffect(() => {
        if (code && !coupon && openCoupon) {
            // console.log('requesting coupon');

            requestCoupon({ action: 'coupondata', couponId: code })
                .then((r: any) => {
                    // console.log(r);
                    if (r.data?.error) {
                        setOpenCoupon(false)
                        return message.error(r.data.error)
                    }

                    // console.log(r.data);

                    setCoupon(r.data)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }, [code, coupon, requestCoupon, openCoupon])

    const RenderCoupon = () => {
        if (!coupon) {
            return <Spin />
        }
        return (
            <Row>
                <Col xs={24}>
                    <Row justify="center">
                        <Typography.Text
                            className="mediumparagraph "
                            style={{ margin: 0, padding: 0, textAlign: 'center' }}
                        >
                            {coupon.name}
                        </Typography.Text>
                    </Row>
                </Col>
                <Col xs={24}>
                    <ul>
                        <li>
                            <Typography.Text className="smallparagraph" style={{ margin: 0, padding: 0 }}>
                                Duración:{' '}
                                {coupon?.duration === 'forever' ? 'Sin vencimiento' : coupon?.duration_in_months ?? 1}{' '}
                                {coupon?.duration === 'forever' ? '' : 'meses'}
                            </Typography.Text>
                        </li>
                        <li>
                            <Typography.Text className="smallparagraph" style={{ margin: 0, padding: 0 }}>
                                Descuento: {coupon.percent_off ? `${coupon.percent_off}%` : `${coupon.discount}`}
                            </Typography.Text>
                        </li>
                    </ul>
                </Col>

                <Col xs={24}>
                    <Row>
                        <Button
                            block
                            type="primary"
                            onClick={() => {
                                posthog.capture('coupon_used', {
                                    couponId: code,
                                })
                                setOpenCoupon(false)
                            }}
                        >
                            Seleccionar un plan
                        </Button>
                    </Row>
                </Col>
            </Row>
        )
    }
    return (
        <Modal open={openCoupon} onCancel={() => setOpenCoupon(false)} title={null} footer={null}>
            <Row justify="center">
                <Typography.Title level={3}>¡Recibiste un código de descuento!</Typography.Title>
            </Row>
            <RenderCoupon />
        </Modal>
    )
}

const ShowOnlyModal = ({ hire }: { hire: Function }) => {
    const [searchParams] = useSearchParams()
    const only = searchParams.get('customPriceId')
    const code = searchParams.get('code')
    const preventHide = searchParams.get('pvh')
    const posthog = usePostHog()

    const [openCustom, setOpenCustom] = useState(only !== null && only !== undefined)
    const [price, setPrice] = useState<any>(null)
    const [coupon, setCoupon] = useState<any>(null)
    const requestCoupon = httpsCallable(getFunctions(), 'membershipsapp')

    useEffect(() => {
        if (only && !price && openCustom) {
            // console.log('requesting coupon');

            requestCoupon({ action: 'customprice', priceId: only })
                .then((r: any) => {
                    // console.log(r);
                    if (r.data?.error) {
                        setOpenCustom(false)
                        return message.error(r.data.error)
                    }

                    // console.log(r.data);

                    setPrice(r.data)
                })
                .catch((error) => {
                    console.error(error)
                })
        }

        if (code && !coupon && openCustom) {
            // console.log('requesting coupon');

            requestCoupon({ action: 'coupondata', couponId: code })
                .then((r: any) => {
                    // console.log(r);
                    if (r.data?.error) {
                        return
                    }

                    // console.log(r.data);

                    setCoupon(r.data)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }, [only, price, requestCoupon, openCustom, code, coupon])

    const RenderPrice = () => {
        if (!price) {
            return <Spin />
        }
        return (
            <Row>
                <Col xs={24}>
                    <Row justify="center">
                        <Typography.Text
                            className="smallheader "
                            style={{ margin: 0, padding: 0, textAlign: 'center' }}
                        >
                            {price?.product?.name}
                        </Typography.Text>
                    </Row>
                </Col>
                <ul>
                    <li>
                        <Typography.Text className="smallparagraph" style={{ margin: 0, padding: 0 }}>
                            {returnCurrencyValue(price?.unit_amount / 100)} {price?.currency} /{' '}
                            {price?.recurring?.interval === 'year' ? 'anual' : 'mensual'} +IVA
                        </Typography.Text>
                    </li>
                    <li>
                        <Typography.Text className="smallparagraph" style={{ margin: 0, padding: 0 }}>
                            Créditos / {price?.recurring?.interval === 'year' ? 'año' : 'mes'}:{' '}
                            {price?.product?.metadata?.credits}
                        </Typography.Text>
                    </li>
                    {coupon && (
                        <li>
                            <Typography.Text className="smallparagraph" style={{ margin: 0, padding: 0 }}>
                                🚀 Código de descuento: {coupon.name}
                            </Typography.Text>
                        </li>
                    )}
                    {coupon && (
                        <li>
                            <Typography.Text className="smallparagraph" style={{ margin: 0, padding: 0 }}>
                                Duración:{' '}
                                {coupon?.duration === 'forever' ? 'Sin vencimiento' : coupon?.duration_in_months ?? 1}{' '}
                                {coupon?.duration === 'forever' ? '' : 'meses'}
                            </Typography.Text>
                        </li>
                    )}
                    {coupon && (
                        <li>
                            <Typography.Text className="smallparagraph" style={{ margin: 0, padding: 0 }}>
                                Descuento: {coupon.percent_off ? `${coupon.percent_off}%` : `${coupon.discount}`}
                            </Typography.Text>
                        </li>
                    )}
                </ul>

                <Col xs={24}>
                    <Row>
                        <Button
                            block
                            type="primary"
                            onClick={() => {
                                posthog.capture('hire_plan', {
                                    from: 'modal',
                                })
                                // setOpenCustom(false)
                                hire()
                            }}
                        >
                            Seleccionar plan {coupon ? 'y aplicar descuento' : ''}
                        </Button>
                    </Row>
                </Col>
            </Row>
        )
    }

    return (
        <Modal
            width={'50%'}
            open={openCustom}
            closable={preventHide !== 'true'}
            onCancel={() => {
                if (preventHide !== 'true') {
                    setOpenCustom(false)
                }
            }}
            styles={{
                mask: {
                    backgroundColor: preventHide === 'true' ? 'rgba(0, 0, 0, 0.86)' : 'rgba(0, 0, 0, 0.1)',
                    //ADD A BACKGROUND COLOR GRADIENT
                    background:
                        preventHide === 'true'
                            ? 'linear-gradient(180deg, rgba(186, 216, 239, 1) 0%, rgba(134, 102, 255, 0.7) 100%)'
                            : 'rgba(0, 0, 0, 0.1)',
                },
            }}
            maskClosable={preventHide !== 'true'}
            title={null}
            footer={null}
        >
            <Row justify="center">
                <Typography.Title level={3}>¡Plan personalizado!</Typography.Title>
            </Row>
            <RenderPrice />
        </Modal>
    )
}

const Memberships: React.FC = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const posthog = usePostHog()
    const [recurrence, setRecurrence] = useState('monthly')
    const [loading, setLoading] = useState('')
    const [subscriptionPricing, setSubscriptionPricing] = useState<any>([])
    const billingManager = httpsCallable(getFunctions(), 'internalbillingapp')
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const [searchParams] = useSearchParams()
    const customPriceId = searchParams.get('customPriceId')
    const rbca = searchParams.get('rbca')
    const code = searchParams.get('code')
    const { billing } = useSelector((state: any) => state.data)
    const { subscription, plan } = billing ?? { subscription: null, plan: null }

    useEffect(() => {
        if (!subscription && billingAccount?.customerId) {
            getBillingData({ team: billingAccount, user: null, dispatch })
        }

        // eslint-disable-next-line
    }, [])

    // console.log('customPriceId', customPriceId);

    // console.log('only', only);

    const subscriptionButton = async (priceId: string) => {
        try {
            if (subscription?.status === 'active') {
                return navigate('/settings?subtab=billingAccount&open=settings_subscription')
            }
            if (!billingAccount?.id) {
                let red = '/signup?redirect=/memberships'
                if (customPriceId) {
                    red = `${red}&customPriceId=${customPriceId}`
                }
                if (code) {
                    red = `${red}&code=${code}`
                }
                return navigate(red)
            }
            setLoading(priceId)
            try {
                posthog.capture('subscription_started', {
                    priceId,
                    recurrence,
                })
            } catch (error) {}
            const r = await billingManager({
                action: 'subscribe',
                coupon: code,
                priceId,
                rbca,
                successUrl: 'https://app.gigstack.pro/settings?subtab=billingAccount&open=settings_subscription',
                cancelUrl: window.location.href,
                quantity: 1,
            })
            const data = r.data as any
            if (data?.error) {
                setLoading('')
                return message.error(data?.message ?? data.error)
            }
            setLoading('')
            if (data.session) {
                window.open(data.session?.url, '_blank')
            } else if (data.raw?.message) {
                try {
                    posthog.capture('subscription_error', {
                        priceId,
                        recurrence,
                        error: data.raw?.message,
                    })
                } catch (error) {}

                message.error(data.raw?.message)
            }
        } catch (error: any) {
            message.error(error?.message)
        }
    }

    const PriceCard = ({
        priceId,
        priceIdAnnual,
        amount,
        description,
        priceTitle,
        bestValue,
    }: {
        priceId: string
        priceIdAnnual: string
        amount: number
        description: string
        priceTitle: string
        bestValue?: boolean
    }) => {
        const discount = recurrence === 'annual' ? 0.16 : 0
        const price = recurrence === 'annual' ? amount - amount * discount : amount

        return (
            <div
                style={{
                    borderTop: '5px solid #D3F5F7',
                    borderBottom: '1px solid #D8DAE5',
                    borderLeft: '1px solid #D8DAE5',
                    borderRight: '1px solid #D8DAE5',
                    width: '100%',
                    padding: '20px',
                    borderRadius: '10px',
                }}
                className="d-flex flex-column"
            >
                <Typography.Text className="smallheader descriptions" style={{ margin: 0, padding: 0 }}>
                    {priceTitle}
                </Typography.Text>
                <Row align="middle">
                    <Typography.Text className="smallheader" style={{ margin: 0, padding: 0 }}>
                        {returnCurrencyValue(price).split('.')[0]} USD
                    </Typography.Text>
                    <Typography.Text className="smallparagraph" style={{ margin: 0, padding: 0 }}>
                        {' '}
                        /{recurrence === 'monthly' ? 'mes' : 'año'}
                    </Typography.Text>
                </Row>
                <Typography.Text className="smallparagraph descriptions" style={{ margin: 0, padding: 0 }}>
                    facturado {recurrence === 'monthly' ? 'mensual' : 'anual'} + IVA
                </Typography.Text>
                <Typography.Text className="smallparagraph" style={{ margin: 0, padding: 0, marginTop: '10px' }}>
                    {description}
                </Typography.Text>
                <Button
                    onClick={() => {
                        const pid = recurrence === 'monthly' ? priceId : priceIdAnnual

                        subscriptionButton(pid)
                    }}
                    type={bestValue ? 'primary' : 'dashed'}
                    loading={loading === priceId || loading === priceIdAnnual}
                    disabled={loading !== '' && loading !== priceId && loading !== priceIdAnnual}
                    style={{ marginTop: '20px' }}
                >
                    {(recurrence === 'monthly' ? priceId : priceIdAnnual) === plan?.id ? 'Suscrito' : 'Comenzar ahora'}
                </Button>
            </div>
        )
    }

    const features: any[] = [
        {
            feature: 'Clientes',
            team: '100 clientes',
            startup: '250 clientes',
            growing: 'ilimitado',
        },
        {
            feature: 'Eventos disponibles',
            team: '100 eventos / mes',
            startup: '500 eventos / mes',
            growing: '1,000 eventos / mes',
        },
        // ADD [Portal de auto-factura co-brandeado, Todas las integraciones incluidas, Soporte con integraciones, Soporte técnico con integraciones, API, Pago por evento extra, Multi-cuentas de facturación, Canal de soporte dedicado en Slack, Soporte dedicado a tus usuarios] on feature key
        {
            feature: 'Portal de auto-factura co-brandeado',
            team: <CheckCircle className="purple" weight="regular" />,
            startup: <CheckCircle className="purple" weight="regular" />,
            growing: <CheckCircle className="purple" weight="regular" />,
        },
        {
            feature: 'Todas las integraciones incluidas',
            team: <CheckCircle className="purple" weight="regular" />,
            startup: <CheckCircle className="purple" weight="regular" />,
            growing: <CheckCircle className="purple" weight="regular" />,
        },
        {
            feature: 'Soporte con integraciones',
            team: '',
            startup: <CheckCircle className="purple" weight="regular" />,
            growing: <CheckCircle className="purple" weight="regular" />,
        },
        {
            feature: 'Soporte técnico con integraciones',
            team: '',
            startup: '',
            growing: <CheckCircle className="purple" weight="regular" />,
        },
        {
            feature: 'API',
            team: '',
            startup: '',
            growing: <CheckCircle className="purple" weight="regular" />,
        },
        {
            feature: 'Pago por evento extra',
            team: '',
            startup: '',
            growing: <CheckCircle className="purple" weight="regular" />,
        },
        {
            feature: 'Multi-cuentas de facturación',
            team: '',
            startup: '',
            growing: <CheckCircle className="purple" weight="regular" />,
        },
        {
            feature: 'Canal de soporte dedicado en Slack',
            team: '',
            startup: '',
            growing: <CheckCircle className="purple" weight="regular" />,
        },
        {
            feature: 'Soporte dedicado a tus usuarios',
            team: '',
            startup: '',
            growing: <CheckCircle className=" purple " weight="regular" />,
        },
    ]

    const getSubscriptionPricing = async () => {
        const db = getFirestore()
        const subscriptionPricingCollection = collection(db, 'subscriptionPricing')
        const subscriptionPricingSnapshot = await getDocs(subscriptionPricingCollection)

        const subscriptionPricing: any[] = []

        subscriptionPricingSnapshot.forEach((doc) => {
            subscriptionPricing.push({ id: doc.id, ...doc.data() })
        })

        // get a function who order my object by Priority with my property priority
        subscriptionPricing.sort((a, b) => a.priority - b.priority)

        setSubscriptionPricing(subscriptionPricing)
    }

    useEffect(() => {
        getSubscriptionPricing()
    }, [])

    const p = window.innerWidth <= 900 ? '20px' : '10%'
    return (
        <Layout>
            <CouponModal />
            <ShowOnlyModal hire={() => subscriptionButton(customPriceId ?? '')} />
            <Layout.Header>
                <Row justify="space-between">
                    <div></div>
                    {billingAccount?.id && (
                        <div>
                            <Typography.Text
                                className="smallparagraph hover-rectangular clickable"
                                onClick={() => navigate('/')}
                                style={{
                                    padding: '5px 10px',
                                    backgroundColor: '#fafbfd',
                                    borderRadius: '5px',
                                }}
                            >
                                Mi cuenta
                            </Typography.Text>
                        </div>
                    )}
                </Row>
            </Layout.Header>
            <Layout.Content style={{ padding: `10px ${p} ${p} ${p}` }}>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                    {customPriceId && (
                        <Col xs={24} lg={18} style={{ marginBottom: '20px' }}>
                            <div
                                className="d-flex flex-column"
                                style={{
                                    padding: '20px 30px',
                                    backgroundColor: 'white',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '20px',
                                    border: '2px solid #7247FE',
                                }}
                            >
                                <Typography.Title style={{ marginTop: '10px' }} level={3}>
                                    Plan Personalizado
                                </Typography.Title>
                                <Typography.Text
                                    type="secondary"
                                    style={{ textAlign: 'center', minHeight: '32.4px' }}
                                    className=""
                                >
                                    Hemos creado un plan personalizado perfecto para ti.
                                </Typography.Text>
                                <Button
                                    type="primary"
                                    loading={loading === customPriceId}
                                    style={{ marginTop: '20px' }}
                                    onClick={() => subscriptionButton(customPriceId)}
                                >
                                    Contratar plan
                                </Button>
                            </div>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col xs={24}>
                        <Row justify="space-between">
                            <Col xs={24} lg={6} xl={4}>
                                <div className="d-flex flex-column">
                                    <p
                                        className="russoOne primary"
                                        style={{
                                            fontSize: '20px',
                                            margin: 0,
                                            padding: 0,
                                        }}
                                    >
                                        gigstack pro
                                    </p>
                                    <Typography.Text className="mediumheader" style={{ marginTop: '15px' }}>
                                        Automatiza el revenue management de tu empresa en cada uno de nuestros planes
                                    </Typography.Text>
                                    <Row align="middle" style={{ marginTop: '15px' }}>
                                        <Typography.Text
                                            className="smallparagraph"
                                            style={{
                                                marginTop: '0px',
                                                marginRight: '10px',
                                            }}
                                        >
                                            Mensual
                                        </Typography.Text>
                                        <Switch
                                            style={{ margin: 0, padding: 0 }}
                                            defaultChecked={recurrence === 'annual'}
                                            onChange={(checked) => setRecurrence(checked ? 'annual' : 'monthly')}
                                        />
                                        <Typography.Text
                                            className="smallparagraph"
                                            style={{
                                                marginTop: '0px',
                                                marginLeft: '10px',
                                            }}
                                        >
                                            Anual
                                        </Typography.Text>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={24} lg={18} xl={20}>
                                <Row gutter={12}>
                                    {subscriptionPricing.map((price: any, key: any) => (
                                        <Col xs={24} lg={8} xl={8} style={{ marginTop: '15px' }} key={price.id}>
                                            <PriceCard
                                                priceId={price.priceId}
                                                priceIdAnnual={price.priceIdAnnual}
                                                amount={price.amount}
                                                description={price.description}
                                                priceTitle={price.priceTitle}
                                                bestValue={price.bestValue}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {subscription?.status === 'active' && (
                        <Col xs={24} style={{ marginTop: '15px' }}>
                            <Alert
                                type="info"
                                message="Actualmente ya tienes un plan contratado, si deseas cambiarlo por favor contacta con nuestro equipo"
                            />
                        </Col>
                    )}
                    <Col xs={24} style={{ marginTop: '35px' }}>
                        <Table
                            dataSource={features}
                            scroll={{ x: 500 }}
                            pagination={{
                                pageSize: 40,
                                hideOnSinglePage: true,
                            }}
                            columns={[
                                {
                                    title: 'Funcionalidades',
                                    dataIndex: 'feature',
                                    key: 'feature',
                                    fixed: 'left',
                                    render: (text: string) => (
                                        <Typography.Text className="smallheader" style={{ margin: 0, padding: 0 }}>
                                            {text}
                                        </Typography.Text>
                                    ),
                                },
                                {
                                    title: 'Team',
                                    dataIndex: 'team',
                                    key: 'team',
                                    render: (text: any) => {
                                        if (typeof text === 'string') {
                                            return (
                                                <Typography.Text
                                                    className="smallparagraph"
                                                    style={{
                                                        margin: 0,
                                                        padding: 0,
                                                    }}
                                                >
                                                    {text}
                                                </Typography.Text>
                                            )
                                        } else return text
                                    },
                                },
                                {
                                    title: 'Startup',
                                    dataIndex: 'startup',
                                    key: 'startup',
                                    render: (text: any) => {
                                        if (typeof text === 'string') {
                                            return (
                                                <Typography.Text
                                                    className="smallparagraph"
                                                    style={{
                                                        margin: 0,
                                                        padding: 0,
                                                    }}
                                                >
                                                    {text}
                                                </Typography.Text>
                                            )
                                        } else return text
                                    },
                                },
                                {
                                    title: 'Growing',
                                    dataIndex: 'growing',
                                    key: 'growing',
                                    render: (text: any) => {
                                        if (typeof text === 'string') {
                                            return (
                                                <Typography.Text
                                                    className="smallparagraph"
                                                    style={{
                                                        margin: 0,
                                                        padding: 0,
                                                    }}
                                                >
                                                    {text}
                                                </Typography.Text>
                                            )
                                        } else return text
                                    },
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    )
}

export default Memberships
