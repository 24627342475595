import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../../context/dataSlice'
import { openModal } from '../../context/modalsSlice'
import { generateCode, getUniqueTaxes } from '../../functions/helpers'
import { addParamToUrl } from '../../functions/UrlParams'
import Typography from 'antd/es/typography/Typography'

const RelationPaymentComplementOption = ({ record, from }: { record: any; from?: string }) => {
    const dispatch = useDispatch()

    const { invoice, invoiceComplement } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)
    const { loading } = invoice ?? {}

    const addComplement = async () => {
        const complementTaxes = getUniqueTaxes(record?.internalItems ?? [])
        const idk = generateCode(15, 'idpkey')
        dispatch(
            setData({
                item: 'idempotency_key',
                data: idk,
            }),
        )

        const relatedDocument = {
            uuid: record.uuid,
            amount: record.total,
            installment: record.installment ?? 1,
            last_balance: record.lastBalance || record.total,
            currency: record.currency ?? 'MXN',
            exchange: Number(record.exchange ?? 1),
            taxes: complementTaxes,
        }

        await new Promise((resolve) => setTimeout(resolve, 1000))

        dispatch(
            setData({
                item: 'invoice',
                data: {
                    ...invoice,
                    type: 'P',
                    invoiceType: 'P',
                    step: 1,
                    loading: loading?.filter((item: string) => item !== 'gettingFtpiData'),
                    folio_number: team?.invoice_folio_payments ?? 1,
                    forceAutomaticFolioCheck: true,
                },
            }),
        )
        addParamToUrl('invoiceType', 'P')
        dispatch(
            setData({
                item: 'client',
                data: record.client ?? {},
            }),
        )
        dispatch(openModal('invoiceModalVisible'))
        dispatch(openModal('paymentComplementModalVisible'))
        dispatch(
            setData({
                item: 'invoiceComplement',
                data: {
                    ...invoiceComplement,
                    related_documents: [relatedDocument],
                },
            }),
        )
    }

    return (
        <>
            {(record.invoiceType === 'I' || record.type === 'I') &&
            (record.payment_form === '99' || record.payment_method === 'PPD') ? (
                <div>
                    {from === 'invoiceActions' ? (
                        <div onClick={() => addComplement()}>
                            <Typography>Crear Complemento</Typography>
                        </div>
                    ) : (
                        <div onClick={() => addComplement()}>
                            <Typography>Crear complemento</Typography>
                        </div>
                    )}
                </div>
            ) : null}
        </>
    )
}
export default RelationPaymentComplementOption
