import { Avatar, Button, Col, Row, Typography } from 'antd'
import { doc, getDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { MetroSpinner } from 'react-spinners-kit'
import { useAuth, useFirestore, useFirestoreDocData } from 'reactfire'
import PersonalProfileForm from '../components/Forms/PersonalProfileForm'
import { useDispatch, useSelector } from 'react-redux'
// import { getFunctions, httpsCallable } from 'firebase/functions';
import Step1Gif from '../../assets/images/finishSetup1.gif'
import { setBillingAccount } from '../context/billingAccountSlice'
import BillingAccountForm from '../components/Forms/BillingAccountForm'
import { signOut } from 'firebase/auth'
import { setData } from '../context/dataSlice'
import { setTeam } from '../context/teamsSlice'
import { setUser } from '../context/userSlice'
import { useNavigate } from 'react-router-dom'
import TeamForm from '../components/Forms/TeamForm'
// import { getFunctions } from 'firebase/functions';
import PendingInvitesElement from '../components/ComponentIndividual/PendingInvitesElement'
import finishSetup2 from '../../assets/images/finishSetup2.gif'

const Step1 = () => {
    const auth = useAuth()
    const fs = useFirestore()
    const userRef = doc(fs, 'users', auth.currentUser?.uid ?? '')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { status: userStatus, data: userData } = useFirestoreDocData(userRef)
    const { user } = useSelector((state: any) => state.user)
    const [name, setname] = useState(user?.firstName ?? '')

    return (
        <Col
            xs={23}
            lg={19}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                overflow: 'hidden',
            }}
        >
            <Row>
                <Col xs={24} lg={12} style={{ padding: '30px' }} className="shadow-lg rounded-lg bg-white">
                    <div className="d-flex flex-column" style={{ marginTop: '20px', marginBottom: '40px' }}>
                        <Typography.Text className="h5-bold neutral-1">Información Personal</Typography.Text>
                    </div>

                    <PersonalProfileForm
                        buttonTitle="Siguiente"
                        onValuesUpdate={(data: any) => {
                            if (data.firstName) {
                                setname(data.firstName)
                            }
                        }}
                        incomingUser={{
                            email: auth.currentUser?.email,
                            ...userData,
                        }}
                    />
                </Col>

                <Col xs={0} lg={12} style={{ overflow: 'hidden' }}>
                    <div style={{ height: '100%', padding: '0px 20px' }}>
                        <div style={{ padding: '30px', borderRadius: '10px' }} className="d-flex flex-column">
                            <img style={{ width: '110px' }} src={Step1Gif} alt="Information setup animation" />
                            <Typography.Text className="h4-bold neutral-1" style={{ padding: 0, marginTop: '40px' }}>
                                Hola {name}
                            </Typography.Text>
                            <Typography.Text
                                className="h6-regular neutral-2 fade-in-animation"
                                style={{ marginTop: '15px' }}
                            >
                                Nos encanta tenerte aquí en gigstack,
                                <br /> comencemos con tu información personal.
                            </Typography.Text>
                        </div>
                    </div>
                </Col>
            </Row>
        </Col>
    )
}

const Step2 = () => {
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [legalName, setLegalName] = useState(billingAccount?.legalName ?? '')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [rfc, setrfc] = useState(billingAccount?.rfc ?? '')
    const dispatch = useDispatch()
    const { user } = useSelector((state: any) => state.user)
    const [invitesInfo, setInvitesInfo] = useState({
        invites: [], 
        loaded: false
    })

    const fs = useFirestore()

    useEffect(() => {
        if (!billingAccount?.id && user?.lastBillingAccountViewed) {
            getDoc(doc(fs, 'billingAccounts', user?.lastBillingAccountViewed)).then((result) => {
                if (result.exists()) {
                    dispatch(setBillingAccount({ ...result.data(), loaded: true }))
                } else
                    dispatch(
                        setBillingAccount({
                            loaded: true,
                        }),
                    )
            })
        }
    })

    return (
        <Col
            xs={23}
            lg={19}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                overflow: 'hidden',
            }}
        >
            <Row>
                <Col xs={24} lg={12} className="bg-white rounded-lg shadow-lg" style={{ padding: '30px' }}>
                    {!invitesInfo.loaded && <MetroSpinner size={18} color="#8666FF" />}
                    <div className="d-flex flex-column" style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <Typography.Text className="h5-bold neutral-1">Información de Facturación</Typography.Text>
                    </div>
                    {invitesInfo?.invites?.length > 0 || !invitesInfo.loaded ? (
                        <PendingInvitesElement
                            setInvitesInfo={(v: any) =>  setInvitesInfo(v)}
                            invitesInfo={invitesInfo}
                        />
                    ) : (
                        <BillingAccountForm
                            onValuesUpdate={(data: any) => {
                                if (data.legalName) {
                                    setLegalName(data.legalName)
                                }
                                if (data.rfc) {
                                    setrfc(data.rfc)
                                }
                            }}
                        />
                    )}
                </Col>
                <Col xs={0} lg={12} style={{ overflow: 'hidden' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <div style={{ padding: '0 20px', marginLeft: '90px' }} className="d-flex flex-column">
                            <Typography.Text className="h4-bold neutral-1" style={{ padding: 0, marginTop: '40px' }}>
                                Información de Facturación
                            </Typography.Text>
                            <Typography.Text
                                className="h6-regular neutral-2 fade-in-animation"
                                style={{ marginTop: '15px' }}
                            >
                                Esta información será utilizada para poder emitir <br /> y recibir facturas.
                            </Typography.Text>
                            <img className="w-75" src={finishSetup2} alt="Information setup animation" />
                            {/* <Avatar size={45} style={{ backgroundColor: 'white' }}><span className='russoOne primary' style={{ fontSize: '19px', marginTop: '-5px' }}>g</span></Avatar> */}
                            {/* <Typography.Text
                                className="mediumheader"
                                style={{ padding: 0, margin: 0, marginTop: '10px' }}
                            >
                                Factura{' '}
                                <span className="primary" style={{ margin: '0px 4px' }}>
                                    {' '}
                                    inv-1{' '}
                                </span>{' '}
                                emitida por:{' '}
                            </Typography.Text> */}
                        </div>
                    </div>
                </Col>
            </Row>
        </Col>
    )
}

const Step3 = ({ setfinish }: { setfinish: any }) => {
    const { team } = useSelector((state: any) => state.team)

    const [logo, setLogo] = useState(team?.brand?.logo ?? '')
    const [alias, setAlias] = useState(team?.brand?.alias ?? '')
    const [primaryColor, setPrimaryColor] = useState(team?.brand?.primaryColor ?? 'black')

    return (
        <Col
            xs={23}
            lg={19}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                overflow: 'hidden',
            }}
        >
            <Row>
                <Col xs={24} lg={12} className="bg-white rounded-lg shadow-lg" style={{ padding: '30px' }}>
                    <div className="d-flex flex-column" style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <Typography.Text className="h5-bold neutral-1">Información de tu marca</Typography.Text>
                    </div>
                    <TeamForm
                        buttonTitle="Ir a mi cuenta"
                        onValuesUpdate={(data: any) => {
                            if (data.logo && typeof data.logo === 'string') {
                                setLogo(data.logo)
                            }
                            if (data.alias) {
                                setAlias(data.alias)
                            }
                            if (data.primaryColor) {
                                setPrimaryColor(data.primaryColor)
                            }
                        }}
                        onUpdate={(data: any) => {
                            if (setfinish) setfinish()
                        }}
                    />
                </Col>
                <Col xs={0} lg={12} style={{ overflow: 'hidden' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            //move a little bit up
                            // marginTop: '-20px'
                        }}
                    >
                        <div
                            style={{
                                padding: '20px',
                                minWidth: '70%',
                                alignItems: 'start',
                            }}
                            className="d-flex flex-column"
                        >
                            {/* <Typography.Text className='mediumheader' style={{ padding: 0, margin: 0, marginTop: '10px', }}>Factura <span className='primary' style={{ margin: '0px 4px' }}> inv-1 </span> emitida por: </Typography.Text> */}
                            <img
                                src={Step1Gif}
                                className="w-20"
                                alt="Information setup animation"
                                style={{ marginRight: 'auto', marginBottom: '15px' }}
                            />
                            <div
                                style={{
                                    padding: '40px',
                                    backgroundColor: 'white',
                                    marginTop: '5px',
                                }}
                                className="d-flex flex-column shadow-lg rounded-lg"
                            >
                                <Avatar size={70} src={logo} style={{ backgroundColor: 'white', marginBottom: '40px' }}>
                                    <span className="russoOne primary" style={{ fontSize: '19px' }}>
                                        {}
                                    </span>
                                </Avatar>
                                <Typography.Text
                                    className="h5-bold"
                                    style={{ color: primaryColor, marginBottom: '10px' }}
                                >
                                    {alias},
                                </Typography.Text>
                                <Typography.Text className="h6-regular" style={{ marginBottom: '15px' }}>
                                    Este es un ejemplo de como se vará tu marca en gigstack
                                </Typography.Text>
                                <Button
                                    className="btn"
                                    style={{
                                        backgroundColor: primaryColor,
                                        color: 'white',
                                    }}
                                >
                                    Botón de ejemplo
                                </Button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Col>
    )
}

// const Step4 = () => {

//     const navigate = useNavigate()
//     return <Col xs={23} lg={19}
//         style={{
//             backgroundColor: 'white',
//             padding: 0,
//             borderRadius: '10px',
//             marginTop: '10px',
//             marginBottom: '20px',
//             overflow: 'hidden'
//         }}>

//         <Row>
//             <Col xs={24} lg={12}
//                 style={{ padding: '30px' }}
//             >
//                 {<Typography.Text className='russoOne primary'>gigstack pro</Typography.Text>}
//                 <div className='d-flex flex-column' style={{ marginTop: '20px', marginBottom: '20px' }}>
//                     <Typography.Text className='mediumparagraphbold'>Continua a tu cuenta</Typography.Text>

//                 </div>
//                 <Button
//                     onClick={() => {
//                         navigate('/dashboard')
//                         //reload
//                         window.location.reload()
//                     }}
//                 >
//                     Continuar a mi cuenta
//                 </Button>
//             </Col>
//             <Col xs={0} lg={12}
//                 style={{
//                     background: 'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
//                     overflow: 'hidden',
//                 }}
//             >
//                 <div style={{
//                     display: 'flex',
//                     flexDirection: 'column',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     height: '100%',
//                     padding: '0px 20px',
//                     //move a little bit up
//                     // marginTop: '-20px'
//                 }}>

//                     <div style={{ padding: '30px', backgroundColor: '#F1F3FF', minWidth: '70%', borderRadius: '10px', alignItems: 'start' }} className='d-flex flex-column'>

//                         <Row style={{ marginTop: '10px' }} align="middle">

//                             <Typography.Text className='mediumheader'>Está todo listo</Typography.Text>

//                         </Row>
//                         <Typography.Text className='smallparagraph descriptions' style={{ marginTop: '15px' }}>Tu cuenta está activa, prueba explorando el dashboard o agendando un onboarding con nuestro equipo.</Typography.Text>

//                     </div>
//                 </div>

//             </Col>
//         </Row>

//     </Col>
// }

const FinishSetup = ({ endsetup }: { endsetup: any }) => {
    const { user } = useSelector((state: any) => state.user)
    const { team } = useSelector((state: any) => state.team)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const auth = useAuth()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const FormSteps = () => {
        if (!user?.firstName || !user?.lastName || !user?.country) {
            return <Step1 />
        }

        if (
            (user?.billingAccounts ?? []).length <= 0 ||
            !user?.lastBillingAccountViewed ||
            !billingAccount?.legalName ||
            (billingAccount?.teams ?? []).length <= 0
        ) {
            return <Step2 />
        }

        if (!user?.teams?.length || !user?.lastTeamViewed || !team?.brand?.alias || team?.brand?.alias === '') {
            return <Step3 setfinish={() => endsetup()} />
        }

        return <Step3 setfinish={() => endsetup()} />
    }

    return (
        <div className="bg-neutral-6 bg-dots">
            <Row justify="center">
                <Col xs={23} lg={19} style={{ padding: '10px 2px' }}>
                    <Row justify="end">
                        <Typography.Text
                            className="smallparagraph descriptions clickable"
                            onClick={async () => {
                                await signOut(auth)

                                // navigate('/login')
                                dispatch(
                                    setData({
                                        item: 'services',
                                        data: [],
                                    }),
                                )
                                dispatch(
                                    setData({
                                        item: 'client',
                                        data: [],
                                    }),
                                )
                                dispatch(
                                    setData({
                                        item: 'clients',
                                        data: [],
                                    }),
                                )
                                dispatch(
                                    setData({
                                        item: 'taxes',
                                        data: [],
                                    }),
                                )
                                dispatch(setUser(null))
                                dispatch(setBillingAccount(null))
                                dispatch(setTeam(null))
                                navigate('/login')
                            }}
                        >
                            cerrar sesión
                        </Typography.Text>
                    </Row>
                </Col>
                <FormSteps />
            </Row>
        </div>
    )
}

export default FinishSetup
