import { Typography } from 'antd'
import moment from 'moment'

/**
 * It takes a recurringPayment object and returns a message and a nextHit date
 * @returns An object with a message and a nextHit.
 */
export const SummarizeMessage = ({
    startDate,
    endDate,
    onTime,
    temporality,
    onWeekday: wd,
    onDay,
}: {
    startDate: string | number
    endDate: string | number
    onTime: string
    temporality: string
    onWeekday: number
    onDay: number
}) => {
    const onWeekday = wd

    let startDateM = moment(startDate)
    if (startDateM.isBefore(moment())) {
        startDateM = moment()
    }
    const amountOfDays = moment(moment(endDate).add(1, 'day')).diff(moment(startDate), 'days')

    let nextHit: any = null

    let temp: string = temporality
    if (!temp) {
        return {
            nextHit,
            message: <Typography.Text style={{ color: '#000' }}>Selecciona una temporalidad</Typography.Text>,
        }
    }

    let ontime: string = onTime
    if (!onTime) {
        return {
            nextHit,
            message: <Typography.Text style={{ color: '#000' }}>Selecciona una fecha de inicio</Typography.Text>,
        }
    }

    let onday: number = onDay
    if ((temporality === 'monthly' && !onday) || (temporality === 'weekly' && !onWeekday)) {
        return {
            nextHit,
            message: <Typography.Text style={{ color: '#000' }}>Selecciona un día</Typography.Text>,
        }
    }

    let hits = 0
    let message = ''

    if (!startDate || !endDate || !ontime || !temporality) {
        message = 'Selecciona el calendario'
        nextHit = null
        hits = 0
        return { nextHit, message, amountOfHits: hits }
    }

    if (temporality === 'daily') {
        ;[...Array(amountOfDays).keys()].forEach((d) => {
            const a = moment(startDate)
                .add(d, 'days')
                .hours(Number(ontime?.split(':')[0]))
                .minutes(Number(ontime?.split(':')[1]))
            if (!nextHit && a?.isAfter(moment().add(0, 'minutes'))) {
                nextHit = a.valueOf()
            }
        })
        hits = amountOfDays
    } else if (temporality === 'weekly') {
        ;[...Array(amountOfDays).keys()].forEach((d) => {
            const a = moment(startDate)
                .add(d, 'days')
                .hours(Number(ontime?.split(':')[0]))
                .minutes(Number(ontime?.split(':')[1]))
            if (!nextHit && a?.isAfter(moment().add(0, 'minutes')) && onWeekday === Number(a.day())) {
                nextHit = a.valueOf()
            }
        })
        hits = Math.floor(amountOfDays / 7)
    } else if (temporality === 'monthly') {
        let amountOfHits = 0
        for (let i = 0; i <= amountOfDays; i++) {
            const a = moment(startDate)
                .add(i, 'days')
                .hours(Number(ontime?.split(':')[0]))
                .minutes(Number(ontime?.split(':')[1]))
            let canHit = a?.isAfter(moment().add(0, 'minutes')) && onDay === Number(a.format('D'))
            if (!nextHit && canHit) {
                nextHit = a.valueOf()
                amountOfHits++
            } else if (canHit) {
                amountOfHits++
            }
        }
        hits = amountOfHits
    }

    return {
        nextHit,
        message:
            message !== ''
                ? message
                : `De acuerdo a las reglas:\nCrearemos ${hits} eventos.\nSiguiente evento ${moment(nextHit).format('DD MMM YYYY HH:mm')}`,
        amountOfHits: hits,
    }
}
