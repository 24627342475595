import { configureStore } from '@reduxjs/toolkit'
import billingAccountReducer from '../context/billingAccountSlice'
import teamReducer from '../context/teamsSlice'
import userReducer from '../context/userSlice'
import modalsReducer from '../context/modalsSlice'
import dataReducer from '../context/dataSlice'
import folioReducer from '../context/folioSlice'

const store = configureStore({
    reducer: {
        billingAccount: billingAccountReducer,
        team: teamReducer,
        user: userReducer,
        modals: modalsReducer,
        data: dataReducer,
        folio: folioReducer,
    },
})

export default store
