import { createSlice } from '@reduxjs/toolkit'
import { UserDef, userDefSet } from '../../interfaces/userDef'

interface UserState {
    user: UserDef
}

const initialState: UserState = {
    user: userDefSet({}),
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        },
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
