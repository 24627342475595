import { AddressDef, addressSet } from './clientDef'

export interface UserDef {
    address: AddressDef
    billingAccounts: string[]
    email: string
    firstName: string
    lastName: string
    intercomHash: string
    lastBillingAccountViewed: string
    lastTeamViewed: string
    name: string
    onboarded: boolean
    phone: string
    setupAt: number
    teams: string[]
    timestamp: number
    uid: string
    v: string
    cannySSO?: string
    lastUsed?: any
}

export const userDefSet = (object: any) => {
    delete object.lastUsed
    const user: UserDef = {
        address: addressSet(object['address'] ?? {}),
        billingAccounts: object['billingAccounts'] ?? [],
        email: object['email'] ?? null,
        firstName: object['firstName'] ?? null,
        lastName: object['lastName'] ?? null,
        intercomHash: object['intercomHash'] ?? null,
        lastBillingAccountViewed: object['lastBillingAccountViewed'] ?? null,
        lastTeamViewed: object['lastTeamViewed'] ?? null,
        name: object['name'] ?? null,
        onboarded: object['onboarded'] ?? null,
        phone: object['phone'] ?? '',
        setupAt: object['setupAt'] ?? null,
        teams: object['teams'] ?? [],
        timestamp: object['timestamp'] ?? null,
        uid: object['uid'] ?? null,
        v: object['v'] ?? null,
        lastUsed: object['lastUsed'] ?? null,
        cannySSO: object['cannySSO'] ?? null,
    }
    return user
}
