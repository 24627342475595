import { Avatar, Col, Descriptions, Row, Tag, Typography, message } from 'antd'
import { collection, doc, getDoc, getFirestore, orderBy, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Payment, optimizedPaymentDefSet, paymentSet } from '../../interfaces/paymentsDef'
import EmailsTable from '../components/ComponentIndividual/EmailSend'
import { useFirestoreCollectionData, useFirestoreDocData } from 'reactfire'
import { RelationsHandler } from '../components/Relations/RelationsHandler'
import ReceiptActions from '../components/ElementActions/ReceiptsActions'
import ItemsSelection from '../components/ComponentIndividual/ItemsSelection'
import ProductsServicesTotals from '../components/ComponentIndividual/ProductsServicesTotals'
import { SearchParamInURL, readableElementSource, returnCurrencyValue } from '../functions/helpers'
import moment from 'moment'
import { ReceiptStatusTag } from '../components/UI/ElementStatus'
import HeaderForViewDetail from '../components/ComponentIndividual/HeaderForViewDetail'
import { Copy } from '@phosphor-icons/react'
import { receiptDefSet } from '../../interfaces/receiptsDef'

const ReceiptsViewDetail: React.FC = () => {
    const fs = getFirestore()
    const navigate = useNavigate()

    const [receiptData, setReceiptData] = useState<any>(null)
    const [, /*paymentData*/ setPaymentData] = useState<Payment | null>(null)
    const id = SearchParamInURL('id')
    if (!id) {
        message.warning('No se encontró el id del Recibo')
        navigate('payments')
    }

    const receiptRef = doc(fs, 'receipts', id ?? '')
    const { data } = useFirestoreDocData(receiptRef)

    const { status: emailStatus, data: emailsData } = useFirestoreCollectionData(
        query(collection(fs, 'emails'), where('related', '==', `receipts/${id}`), orderBy('timestamp', 'desc')),
    )

    useEffect(() => {
        const fetchReceiptData = async () => {
            try {
                const receiptDocRef = doc(fs, 'receipts', id as string)
                const receiptDocSnapshot = await getDoc(receiptDocRef)
                if (receiptDocSnapshot.exists()) {
                    const receiptDetails = receiptDocSnapshot.data() // Cambio aquí
                    setReceiptData(receiptDetails as any)
                } else {
                    message.error('No se encontró el recibo')
                }
            } catch (error) {
                message.error('Error al obtener los detalles del recibo')
            }
        }

        const fetchPaymentData = async () => {
            try {
                for (const pi of receiptData?.payments ?? []) {
                    const paymentDocRef = doc(fs, 'payments', pi as string)
                    const paymentDocSnapshot = await getDoc(paymentDocRef)
                    if (paymentDocSnapshot.exists()) {
                        const paymentDetails = paymentDocSnapshot.data()
                        setPaymentData(paymentSet(paymentDetails ?? {}))
                    } else {
                        message.error('No se encontró el pago')
                    }
                }
            } catch (error) {}
        }
        fetchPaymentData()
        fetchReceiptData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, fs])

    const ActionsHeader = () => {
        return (
            <Col xs={24}>
                <div
                    style={{
                        borderRadius: '10px',
                        backgroundColor: '#F9FAFC',
                        padding: '10px',
                    }}
                >
                    <Row justify="space-between" align="middle">
                        <Typography.Text className="smallparagraphbold">Acciones</Typography.Text>
                        <ReceiptActions
                            receipt={{ ...data, emails: typeof data?.emails === 'object' ? [] : data?.emails }}
                        />
                    </Row>
                </div>
            </Col>
        )
    }
    const ProductAndServices = () => {
        return (
            <Col xs={24} lg={24} style={{ marginTop: '25px' }}>
                <div className="d-flex flex-column">
                    <Row align="middle">
                        <Typography.Text className="smallparagraph descriptions">Productos / Servicios</Typography.Text>
                        <div
                            style={{ height: '1px', borderBottom: '1px solid #D8DAE5', flex: 1, marginLeft: '15px' }}
                        ></div>
                    </Row>
                    <ItemsSelection incomingServices={data?.items} disabled={true} hideTitle={true} />
                    <Row justify="end">
                        <ProductsServicesTotals incomingItems={data?.items} boxed={false} />
                    </Row>
                </div>
            </Col>
        )
    }

    const ReceiptDetailsGeneral = () => {
        let details = [
            {
                key: 'id',
                label: <Typography.Text className="smallparagraphbold">ID del recibo</Typography.Text>,
                children: <Typography.Text className="smallparagraph ">{data?.id}</Typography.Text>,
            },

            {
                key: 'currency',
                label: <Typography.Text className="smallparagraphbold">Moneda</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">{data?.currency?.toUpperCase()}</Typography.Text>
                ),
            },
            {
                key: 'exchange_rate',
                label: <Typography.Text className="smallparagraphbold">Tipo de cambio</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        1 {data?.currency} = {data?.exchange} MXN
                    </Typography.Text>
                ),
            },
            {
                key: 'total',
                label: <Typography.Text className="smallparagraphbold">Total</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {returnCurrencyValue(data?.total)} {data?.currency}{' '}
                    </Typography.Text>
                ),
            },

            {
                key: 'source',
                label: <Typography.Text className="smallparagraphbold">Fuente</Typography.Text>,
                children: (
                    <Tag color="#D3D9FF">
                        <Typography.Text className="smallparagraphbold blue">
                            {readableElementSource(data?.from ?? '')}
                        </Typography.Text>
                    </Tag>
                ),
            },

            {
                key: 'date',
                label: <Typography.Text className="smallparagraphbold">Fecha</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {moment(data?.timestamp).format('DD MMMM YYYY HH:mm')}{' '}
                    </Typography.Text>
                ),
            },
            {
                key: 'URL',
                label: <Typography.Text className="smallparagraphbold">URL de AutoFactura</Typography.Text>,
                children: (
                    <Typography.Text
                        className="smallparagraph "
                        copyable={{ icon: <Copy weight="regular" className="icon" /> }}
                    >
                        {data?.url}
                    </Typography.Text>
                ),
            },
        ]

        if (data?.global)
            details.push({
                key: 'global',
                label: <Typography.Text className="smallparagraphbold">Factura global</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {data?.global?.periodicity === 'month' ? 'Mensual' : data?.global?.periodicity}{' '}
                        {moment(data?.global?.months, 'MM').format('MMMM')} {data?.global?.year}{' '}
                    </Typography.Text>
                ),
            })
        if (data?.hilosResponse?.conversation) {
            details.push({
                key: 'hilos',
                label: <Typography.Text className="smallparagraphbold">Conversación en Hilos</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">{data?.hilosResponse?.conversation} </Typography.Text>
                ),
            })
        }
        return (
            <Col xs={24} lg={24}>
                <div
                    style={{
                        width: '100%',
                        padding: '20px',
                        borderRadius: '10px',
                        background:
                            'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                    }}
                    className="d-flex flex-column"
                >
                    <Descriptions
                        column={{ xs: 1, sm: 1, md: 1, lg: 3, xl: 3, xxl: 3 }}
                        title={
                            <Row justify="space-between" align="middle">
                                <Typography.Text className="mediumparagraphbold">
                                    Información general del recibo de venta
                                </Typography.Text>
                                <ReceiptStatusTag shape="tag" record={receiptDefSet(data ?? {})} />
                            </Row>
                        }
                        layout="horizontal"
                        size="small"
                        items={details}
                    />
                </div>
            </Col>
        )
    }

    const EmailsRelated = () => {
        return (
            <Col xs={24}>
                <EmailsTable
                    customHeader={
                        <Row align="middle" style={{ marginBottom: '15px' }}>
                            <Typography.Text className="smallparagraph descriptions">Correos enviados</Typography.Text>
                            <div
                                style={{
                                    height: '1px',
                                    borderBottom: '1px solid #D8DAE5',
                                    flex: 1,
                                    marginLeft: '15px',
                                }}
                            ></div>
                        </Row>
                    }
                    emailsData={emailsData}
                    emailStatus={emailStatus}
                />
            </Col>
        )
    }
    return (
        <div>
            <HeaderForViewDetail 
                data={data} 
                title={`Recibo de venta por ${returnCurrencyValue(data?.total)}`}
            />

            <Row gutter={[16, 16]} style={{ padding: '20px 20px' }}>
                <Col xs={24}>
                    <Row>
                        <Avatar
                            style={{
                                verticalAlign: 'middle',
                                marginRight: '10px',
                                background:
                                    'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                            }}
                            size={40}
                        >
                            <Typography.Text className="smallparagraphbold">
                                {(data?.client?.legal_name ?? data?.client?.name ?? '?')[0]}
                            </Typography.Text>
                        </Avatar>
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallheader">
                                {data?.client?.name ?? data?.client?.legal_name ?? ''}
                            </Typography.Text>
                            <Typography.Text className="smallparagraph descriptions">
                                {data?.client?.rfc} - {data?.client?.email}
                            </Typography.Text>
                            <Typography.Text className="smallparagraph descriptions">
                                {typeof data?.client?.address?.country === 'string'
                                    ? data?.client?.address?.country
                                    : data?.client?.address?.country?.label ?? ''}{' '}
                                - C.P. {data?.client?.address?.zip}
                            </Typography.Text>
                        </div>
                    </Row>
                </Col>
                <ActionsHeader />
                <ReceiptDetailsGeneral />
                <ProductAndServices />
                <Col xs={24} lg={24}>
                    <Row align="middle" style={{ marginBottom: '15px' }}>
                        <Typography.Text className="smallparagraph descriptions">Recibos relacionados</Typography.Text>
                        <div
                            style={{ height: '1px', borderBottom: '1px solid #D8DAE5', flex: 1, marginLeft: '15px' }}
                        ></div>
                    </Row>
                    <RelationsHandler
                        title="Recibos relacionados"
                        viewType="table"
                        type="payments"
                        collection="receipts"
                        subcollection="payments"
                        item={optimizedPaymentDefSet(data ?? {})}
                        itemId={data?.id}
                    />
                </Col>
                <Col xs={24} lg={24}>
                    <Row align="middle" style={{ marginBottom: '15px' }}>
                        <Typography.Text className="smallparagraph descriptions">Pagos relacionados</Typography.Text>
                        <div
                            style={{ height: '1px', borderBottom: '1px solid #D8DAE5', flex: 1, marginLeft: '15px' }}
                        ></div>
                    </Row>
                    <RelationsHandler
                        title="Facturas relacionados"
                        viewType="table"
                        type="payments"
                        collection="invoices"
                        subcollection="payments"
                        item={optimizedPaymentDefSet(data ?? {})}
                        itemId={data?.id}
                    />
                </Col>

                <EmailsRelated />
            </Row>

            {/* <Row gutter={[16, 16]}>
                <Col xs={24} md={24} lg={24}>
                    <Client data={receiptData} />
                </Col>
            </Row>
            <ViewDetailReceipt data={receiptData} title={'Recibo de venta'} icon={<Receipt style={{ marginRight: '10px' }} size={20} weight="regular" />} />
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Typography.Title level={5}>Pagos relacionados</Typography.Title>
                            <RelationsHandler
                                title="Pagos relacionados"
                                viewType="table"
                                type="receipts"
                                collection="payments"
                                subcollection="receipts"
                                item={optimizedPaymentDefSet(receiptData ?? {})}
                                itemId={id}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Typography.Title level={5}>Facturas relacionadas</Typography.Title>
                            <RelationsHandler
                                title="Facturas relacionadas"
                                viewType="table"
                                type="receipts"
                                collection="invoices"
                                subcollection="receipts"
                                item={optimizedPaymentDefSet(receiptData ?? {})}
                                itemId={id}
                            />
                        </Col>
                    </Row>

                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <EmailsTable emailsData={emailsData} emailStatus={emailStatus} />
                </Col>
            </Row> */}
        </div>
    )
}

export default ReceiptsViewDetail
