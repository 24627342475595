import { useState, useEffect } from 'react'
import { Modal, Row, Typography, Col, Form, Button, message, Tag, Tooltip, Avatar } from 'antd'
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { CheckBoxInput } from '../components/Forms/Inputs'
import { CheckCircle, Question, X } from '@phosphor-icons/react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { MetroSpinner } from 'react-spinners-kit'
import { returnCurrencyValue } from '../functions/helpers'
import { setTeam } from '../context/teamsSlice'
import { setUser } from '../context/userSlice'
import { setBillingAccount } from '../context/billingAccountSlice'
import { useNavigate } from 'react-router-dom'

const CreateTeamModal: React.FC = () => {
    const navigate = useNavigate()
    const { createTeamVisible } = useSelector((state: any) => state.modals)
    const { user } = useSelector((state: any) => state.user)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    const [state, setstate] = useState<any>({
        primaryColor: '#8666FF',
        secondaryColor: '#9AEDB6',
        alias: '',
    })
    const dispatch = useDispatch()

    const newteamsapp = httpsCallable(getFunctions(), 'newteamsappv2')
    const [nextAction, setNextAction] = useState('status')

    const listStatus = async () => {
        try {
            const result: any = await newteamsapp({ type: 'status' })
            console.log(result.data)
            setstate(result?.data)
            setNextAction(result?.data.nextAction)
            if (result?.data.nextAction === 'create') {
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (nextAction === 'status' && createTeamVisible) {
            listStatus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextAction, createTeamVisible])

    const createTeam = async () => {
        try {
            setstate((prev: any) => ({ ...prev, loading: true }))
            const result: any = await newteamsapp({ type: 'create' })
            if (result.data?.error) {
                throw new Error(result.data.error)
            }
            console.log(result.data)
            const team = await getDoc(doc(getFirestore(), 'teams', result.data.teamId))
            if (!team.exists()) {
                throw new Error('No se pudo crear la marca, intente más tarde')
            }
            await updateDoc(doc(getFirestore(), 'users', user?.uid), {
                lastTeamViewed: team.id,
            })
            dispatch(setTeam(team.data()))
            dispatch(
                setUser({
                    ...user,
                    lastTeamViewed: team.id,
                    teams: [...(user?.teams ?? []), team.id],
                }),
            )
            dispatch(
                setBillingAccount({
                    ...billingAccount,
                    teams: [...(billingAccount?.teams ?? []), team.id],
                }),
            )
            setstate((prev: any) => ({ loading: false }))
            dispatch(closeModal('createTeamVisible'))
            navigate('/finishSetup')

            return
        } catch (error: any) {
            setstate((prev: any) => ({ ...prev, loading: false }))
            console.error(error)
            message.error(error.message ?? error)
        }
    }

    const CreateForm = () => {
        return (
            <Row justify="center">
                <Col
                    xs={24}
                    style={{
                        background:
                            'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                        overflow: 'hidden',
                        padding: '30px',
                        borderRadius: '10px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            padding: '0px 20px',
                            //move a little bit up
                            // marginTop: '-20px'
                        }}
                    >
                        <div
                            style={{
                                padding: '30px',
                                backgroundColor: '#F1F3FF',
                                minWidth: window.innerWidth < 780 ? '70%' : '50%',
                                borderRadius: '10px',
                                alignItems: 'start',
                                minHeight: '100px',
                            }}
                            className="d-flex flex-column"
                        >
                            <Avatar size={45} style={{ backgroundColor: 'white' }}>
                                {' '}
                                <CheckCircle className=" purple" size={30} />
                            </Avatar>
                            {/* <Typography.Text className='mediumheader' style={{ padding: 0, margin: 0, marginTop: '10px', }}>Factura <span className='primary' style={{ margin: '0px 4px' }}> inv-1 </span> emitida por: </Typography.Text> */}

                            <div
                                style={{
                                    padding: '20px 15px',
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    marginTop: '5px',
                                    width: '100%',
                                }}
                                className="d-flex flex-column"
                            >
                                <Typography.Text className="smallheader">Gracias {user?.firstName},</Typography.Text>
                                <Typography.Text className="smallparagraph">
                                    Ahora puedes continuar con la configuración de la nueva marca.
                                </Typography.Text>

                                <Button
                                    loading={state.loading}
                                    onClick={createTeam}
                                    type="primary"
                                    style={{
                                        marginTop: '15px',
                                    }}
                                >
                                    Continuar al setup
                                </Button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }

    const CardElement = ({ card }: { card: any }) => {
        return (
            <Row
                align="middle"
                className="clickable"
                onClick={() => {
                    setstate((prev: any) => ({ ...prev, selectedCard: prev.selectedCard === card.id ? '' : card.id }))
                }}
                justify="space-between"
                key={card.id}
                style={{ border: '1px solid #D8DAE5', padding: '5px 10px', borderRadius: '5px', marginTop: '3px' }}
            >
                <Row align="middle">
                    {state.selectedCard === card.id && (
                        <CheckCircle className="icon primary" style={{ marginRight: '5px' }} size={15} />
                    )}
                    <Typography.Text className={`smallheader ${state.selectedCard === card.id ? 'primary' : ''}`}>
                        {card?.card?.brand?.toUpperCase()}
                    </Typography.Text>
                </Row>
                <Typography.Text className="smallparagraph descriptions">**** {card?.card?.last4}</Typography.Text>
            </Row>
        )
    }

    const ChargeForm = () => {
        return (
            <Row gutter={23}>
                <Col xs={24} lg={12}>
                    <Form
                        layout="vertical"
                        onFinish={async () => {
                            try {
                                setstate((prev: any) => ({ ...prev, loading: true }))
                                const charge: any = await newteamsapp({
                                    type: 'charge',
                                    cardId: state.selectedCard,
                                    priceId: state.price,
                                })
                                console.log(charge)
                                setstate((prev: any) => ({
                                    ...prev,
                                    nextAction: charge.data.nextAction,
                                    loading: false,
                                }))
                                setNextAction(charge.data.nextAction)
                            } catch (error) {
                                console.log(error)
                                setstate((prev: any) => ({ ...prev, loading: false }))
                            }
                        }}
                        className="d-flex flex-column"
                    >
                        <label>Método de pago</label>
                        <div style={{ marginBottom: '15px' }}></div>
                        {state.paymentMethods?.map((method: any) => {
                            return <CardElement card={method} key={method?.id} />
                        })}
                        <CheckBoxInput
                            name={'terms'}
                            label={''}
                            rules={[
                                {
                                    required: true,
                                    message: 'Debes aceptar los términos y condiciones',
                                },
                            ]}
                            content={
                                <p className="smallparagraph" style={{ fontSize: '10px' }}>
                                    Aceptas haber leído los{' '}
                                    <a
                                        href="https://pro-gigstack.s3.us-east-2.amazonaws.com/legal/terms.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {' '}
                                        términos y condiciones{' '}
                                    </a>{' '}
                                    de uso de la plataforma
                                </p>
                            }
                        />
                        <Row justify="end">
                            <Button
                                loading={state.loading}
                                htmlType="submit"
                                disabled={!state.selectedCard}
                                type="primary"
                            >
                                {!state.selectedCard ? 'Selecciona un método de pago' : 'Continuar'}
                            </Button>
                        </Row>
                    </Form>
                </Col>
                <Col
                    xs={24}
                    lg={12}
                    style={{
                        background:
                            'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                        overflow: 'hidden',
                        padding: '30px',
                    }}
                >
                    <Typography.Text className="smallheader" style={{}}>
                        Costo de configuración
                    </Typography.Text>
                    <Row justify="start">
                        <Typography.Text className="mediumheader" style={{}}>
                            US {returnCurrencyValue(5)}
                        </Typography.Text>
                    </Row>
                    <Typography.Text className="smallparagraph" style={{}}>
                        pago único
                    </Typography.Text>
                </Col>
            </Row>
        )
    }

    const Render = () => {
        switch (nextAction) {
            case 'status':
                return (
                    <Row justify="center">
                        <MetroSpinner size={30} color="#8666FF" />
                    </Row>
                )
            case 'create':
                return <CreateForm />

            case 'charge':
                return <ChargeForm />

            default:
                return <></>
        }
    }

    return (
        <Modal
            open={createTeamVisible}
            onCancel={() => dispatch(closeModal('createTeamVisible'))}
            closable={false}
            closeIcon={<></>}
            width={window.innerWidth <= 768 ? '100%' : '80%'}
            style={{ top: 10 }}
            footer={null}
            title={
                <div style={{ position: 'relative', paddingBottom: '15px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            //center the text
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        Nueva marca
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X
                                    className="icon clickable"
                                    size={20}
                                    onClick={() => {
                                        dispatch(closeModal('createTeamVisible'))
                                    }}
                                />
                                <></>
                                <Tooltip title="El costo de configuración de una nueva marca es de $5USD pago único">
                                    <Tag color="#F0F1F3" style={{ color: '#474D66' }} className="smallparagraph">
                                        $5 USD / Configuración <Question style={{ marginLeft: '5px' }} />
                                    </Tag>
                                </Tooltip>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
        >
            <Row justify="center" gutter={12}>
                <Col xs={24}>
                    <Render />
                </Col>
            </Row>
        </Modal>
    )

    // const fs = getFirestore();
    // const [formRef] = Form.useForm();
    // const { billingAccount } = useSelector((state: any) => state.billingAccount)
    // const { user } = useSelector((state: any) => state.user)
    // const { team } = useSelector((state: any) => state.team)
    // const dispatch = useDispatch()

    // const { createTeamVisible } = useSelector((state: any) => state.modals)

    // const handleClose = () => {

    //     dispatch(closeModal('createTeamVisible'))
    // }

    // const initialValues = {
    //     alias: team?.brand?.alias,
    //     legalName: billingAccount?.legal_name ?? billingAccount?.legalName ?? "",
    //     rfc: billingAccount?.rfc ?? "",
    //     supportPhone: billingAccount?.supportPhone ?? "",
    //     zip: billingAccount?.address?.zip ?? "",
    //     currency: billingAccount?.currency ?? { label: "MXN", value: "MXN" },
    //     primaryColor: team?.brand?.primaryColor ?? "#8666FF",
    //     secondaryColor: "#9AEDB6",

    // }

    // const [loading, setLoading] = useState(false);
    // const [brandLogo, setBrandLogo] = useState<string | null>(null);
    // const [useBillingAccountInfo, setUseBillingAccountInfo] = useState(true);
    // const brandCode = generateCode(15, 'team')

    // const handleCreateBrand = async (v: any) => {
    //     try {
    //         setLoading(true);
    //         setBrandLogo(null)
    //         // const charge = await SignedInternalAPIRequest({ billingAccount: billingAccount?.id }, 'createNewTeamRequest', userApi, {}, 'POST')
    //         // const charge = await SignedInternalAPIRequest({ billingAccount: billingAccount?.id }, 'createNewTeamRequest', auth.currentUser!, {}, 'POST');

    //         // if (!charge?.success) {
    //         //     console.error({
    //         //         message: 'Error',
    //         //         description: charge?.message ?? 'No se pudo crear la marca, intente más tarde'
    //         //     })
    //         //     setLoading(false);
    //         //     return;
    //         // }

    //         const brandObj = {
    //             supportPhone: v.supportPhone ?? null,
    //             timestamp: moment().valueOf(),
    //             alias: v.alias ?? null,
    //             contactEmails: arrayUnion(user?.email),
    //             supportEmail: user?.email,
    //             logo: brandLogo,
    //             id: brandCode,
    //             address: billingAccount?.address ?? null,
    //             currency: typeof v.currency === 'string' ? v.currency : v?.currency?.value ?? 'MXN',
    //             legalName: v.legalName ?? null,
    //             owner: user?.uid,
    //             billingAccount: billingAccount?.id,
    //             setupComplete: false,
    //             integrationsSetup: ['stripeSetup', 'invoices', 'importStripeProducts', 'importStripePayments', 'bank', 'setupBrand', 'testInvoice', 'setDefaults', 'automaticInvoicing'],
    //             members: arrayUnion({
    //                 id: user?.uid,
    //                 isAdmin: true,
    //                 payments: "editor",
    //                 invoices: "editor",
    //                 services: "editor",
    //                 expenses: "editor",
    //                 email: user?.email,
    //                 rol: 'admin',
    //             }),
    //             social: {
    //                 website: null,
    //                 instagram: null,
    //                 linkedin: null,
    //             },
    //             membersIds: arrayUnion(user?.uid),
    //             brand: {
    //                 logo: brandLogo,
    //                 alias: v.alias,
    //                 primaryColor: v?.primaryColor ?? '#8666FF',
    //                 secondaryColor: v?.secondaryColor ?? '#9AEDB6',
    //             }
    //         }

    //         await setDoc(doc(fs, `teams/${brandCode}`), { ...brandObj });
    //         //await updateDoc(doc(fs, `users/${user?.uid}`), { teams: arrayUnion(brandCode), lastTeamViewed: brandCode }, { merge: true });
    //         //await updateDoc(doc(fs, `billingAccounts/${billingAccount?.id}`), { teams: arrayUnion(brandCode), pendingTeamConfigurations: increment(-1) }, { merge: true });
    //         message.success("Marca creada con éxito");

    //         setLoading(false);
    //     } catch (error) {
    //         setLoading(false);
    //     }
    // }

    // useEffect(() => {
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [createTeamVisible])

    // const FormToCreate = () => {
    //     return <>
    //         <Form initialValues={initialValues} form={formRef} layout="vertical" onFinish={handleCreateBrand} >
    //             <Row style={{ width: '100%', marginTop: '0px' }} gutter={{ xs: 12, md: 12 }}>
    //                 <Col xs={24} md={12} lg={8} style={{ marginBottom: '10px', marginTop: '10px' }}>
    //                     <Typography.Text className='cLabel' >Logo</Typography.Text>
    //                     <div style={{ marginTop: '5px' }}></div>
    //                     <UploadLogo logo={team?.brand?.logo} />
    //                     <Typography.Text type="secondary" style={{ marginTop: '5px', fontSize: '10px' }}>Imagen en jpg, jpeg o png de máximo 2MB</Typography.Text>
    //                 </Col>

    //                 <Col xs={24} md={12} lg={16} >
    //                     <TextInput name={'alias'} label={'Marca'} placeholder={'Mi marca'} disabled={false} />
    //                 </Col>

    //                 <Col xs={24} lg={12} >
    //                     <ColorInput name={'primaryColor'} label={'Color principal'} />
    //                 </Col>
    //                 <Col xs={24} lg={12}  >
    //                     <ColorInput name={'secondaryColor'} label={'Color secundario'} />

    //                 </Col>

    //                 <Col xs={24} md={24} lg={24} style={{ marginBottom: '10px' }} >
    //                     <Switch checked={useBillingAccountInfo} size="small" onChange={() => setUseBillingAccountInfo(!useBillingAccountInfo)} />
    //                     <Typography.Text style={{ marginLeft: '10px', marginTop: '0px' }}>¿Usar la misma información legal de la cuenta de facturación?</Typography.Text>
    //                 </Col>

    //                 <Col xs={24} >
    //                     <TextInput name={'legalName'} label={'Nombre legal'} placeholder='Empresa SA DE CV' disabled={useBillingAccountInfo} />

    //                 </Col>
    //                 <Col xs={24} md={12}>
    //                     <TextInput name={'rfc'} placeholder='RFC' label={'RFC'} disabled={useBillingAccountInfo} />
    //                 </Col>
    //                 <Col xs={24} md={12}>
    //                     <TextInput name={'supportPhone'} placeholder='' label={'Teléfono'} disabled={useBillingAccountInfo} />
    //                 </Col>
    //                 <Col xs={12} md={6} lg={6}>
    //                     <TextInput placeholder='zip' name={'zip'} label={'Zip'} disabled={useBillingAccountInfo} />
    //                 </Col>

    //             </Row>
    //             <Row justify='center'>
    //                 <Button loading={loading} type="primary" htmlType="submit">Crear marca</Button>
    //             </Row>
    //         </Form>
    //     </>
    // }

    // const isMobile = window.innerWidth <= 768;
    // const bodyStyle: React.CSSProperties = {
    //     display: 'flex',
    //     flexDirection: 'row',
    //     alignItems: 'center',
    //     justifyContent: 'space-between',
    //     maxHeight: isMobile ? 'auto' : '900px',
    //     padding: isMobile ? '20px' : '0',
    // };

    // return (
    //     <Modal
    //         open={createTeamVisible}
    //         onCancel={handleClose}
    //         title={<Typography.Title level={4} style={{ margin: 0 }}>Crea una nueva marca</Typography.Title>}
    //         footer={null}
    //         width={isMobile ? '100%' : '95%'}
    //         style={{ top: 10 }}
    //         bodyStyle={bodyStyle}
    //     >
    //         <Row>
    //             <Col xs={24} md={12}>
    //                 <FormToCreate />
    //             </Col>
    //             <Col xs={0} md={12} style={{ borderLeft: '0.5px solid #fafbfd' }}>
    //                 <div style={{ justifyContent: 'center', alignItems: 'center' }} className="d-flex flex-column">
    //                     <div style={{ width: '90%', backgroundColor: '#fafbfd', padding: '10px', borderRadius: '10px' }}>
    //                         <Row justify="space-between" align="middle">
    //                             {/* <img src={team} */}
    //                         </Row>
    //                     </div>
    //                 </div>
    //             </Col>
    //         </Row>
    //     </Modal>
    // )
}

export default CreateTeamModal
