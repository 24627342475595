import { CloudX } from '@phosphor-icons/react'
import { Alert, FormInstance, Typography } from 'antd'
import React from 'react'

const ErrorsInFormComponent = ({
    formErrors,
    title,
    form,
    errorMessage,
}: {
    formErrors?: any[]
    title: string
    form?: FormInstance
    errorMessage?: string | null
}) => {
    if ((formErrors ?? [])?.length <= 0 && !errorMessage) return <></>
    return (
        <Alert
            style={{ marginBottom: '15px' }}
            message={<Typography.Text className="dangerHeading">{title}</Typography.Text>}
            description={
                <div className="d-flex flex-column">
                    <ul
                        style={{
                            padding: 0,
                            margin: 0,
                            listStyleType: 'none',
                        }}
                    >
                        {!errorMessage &&
                            formErrors?.map((error, index) => {
                                return (
                                    <li
                                        className=""
                                        onClick={() => {
                                            form?.scrollToField(error.name)
                                        }}
                                        key={index}
                                    >
                                        <Typography.Text className="smallparagraph link danger">
                                            {error.errors[0]}
                                        </Typography.Text>
                                    </li>
                                )
                            })}
                        {errorMessage && (
                            <Typography.Text className="smallparagraph link danger">{errorMessage}</Typography.Text>
                        )}
                    </ul>
                    <Typography.Text className="smallparagraph descriptions" style={{ marginTop: '15px' }}>
                        Si necesitas ayuda, no dudes en escribirnos.
                    </Typography.Text>
                </div>
            }
            type="error"
            showIcon
            icon={<CloudX size={23} className="red" weight="regular" />}
        />
    )
}

export default ErrorsInFormComponent
