import React, { useEffect, useState } from 'react'
import { Form, FormInstance, Row, Col, Typography, Button, message, Tag, Divider } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { CheckCircle } from '@phosphor-icons/react'
import { returnCurrencyValue } from '../../functions/helpers'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { usePermissions } from '../../customHooks/usePermissions'
import { getBillingData } from '../../functions/Billing'

interface SuscriptionFormProps {
    incomingForm?: FormInstance
}

const SuscriptionForm: React.FC<SuscriptionFormProps> = ({ incomingForm }) => {
    const dispatch = useDispatch()
    const { billing } = useSelector((state: any) => state.data)
    const { subscription, plan } = billing ?? {
        subscription: null,
        plan: null,
    }
    const navigate = useNavigate()

    const [openingSub, setOpeningSub] = useState(false)
    const { isBillingAccountAdmin } = usePermissions()
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    // Your component logic here
    const [form] = Form.useForm()
    const usingForm = incomingForm ?? form
    const functions = getFunctions()
    if (window.location.hostname === 'localhost') {
        functions.customDomain = 'http://127.0.0.1:5001/gigstackpro/us-central1'
    }
    const billingManager = httpsCallable(functions, 'internalbillingapp')

    useEffect(() => {
        if (!subscription && billingAccount?.customerId) {
            getBillingData({ team: billingAccount, user: null, dispatch })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription])

    const activeSubscription = subscription

    const PlanHeader = () => {
        return (
            <Row>
                <Row>
                    {/* {!loaded && <MetroSpinner size={15} color='#8666FF' />} */}
                    <Typography.Text className="mediumheader" style={{ marginLeft: '0px' }}>
                        Plan {activeSubscription ? activeSubscription?.plan?.nickname : ''}
                    </Typography.Text>
                </Row>
                <Tag color={activeSubscription?.status === 'active' ? 'green' : 'red'} style={{ marginLeft: '10px' }}>
                    {activeSubscription?.status === 'active' ? 'Activo' : 'Inactivo'}
                </Tag>
            </Row>
        )
    }

    const NoSubscription = () => {
        return (
            <Row justify="center">
                <Col xs={24}>
                    <Divider />
                </Col>
                <Col xs={24} md={12} lg={12}>
                    <div className="d-flex flex-column">
                        <Typography.Text className="smallheader" style={{ marginBottom: '10px' }}>
                            Actualiza tu cuenta
                        </Typography.Text>
                        <Row style={{ marginTop: '5px' }}>
                            <CheckCircle size={19} weight="regular" className="primary" />
                            <Typography.Text className="mediumparagraph descriptions" style={{ marginLeft: '10px' }}>
                                Acceso a API
                            </Typography.Text>
                        </Row>
                        <Row style={{ marginTop: '5px' }}>
                            <CheckCircle size={19} weight="regular" className="primary" />
                            <Typography.Text className="mediumparagraph descriptions" style={{ marginLeft: '10px' }}>
                                1,000 créditos al mes
                            </Typography.Text>
                        </Row>
                        <Row style={{ marginTop: '5px' }}>
                            <CheckCircle size={19} weight="regular" className="primary" />
                            <Typography.Text className="mediumparagraph descriptions" style={{ marginLeft: '10px' }}>
                                Pago por eventos
                            </Typography.Text>
                        </Row>
                        <Row style={{ marginTop: '5px' }}>
                            <CheckCircle size={19} weight="regular" className="primary" />
                            <Typography.Text className="mediumparagraph descriptions" style={{ marginLeft: '10px' }}>
                                Soporte prioritario
                            </Typography.Text>
                        </Row>
                        <Row style={{ marginTop: '5px' }}>
                            <CheckCircle size={19} weight="regular" className="primary" />
                            <Typography.Text className="mediumparagraph descriptions" style={{ marginLeft: '10px' }}>
                                Clientes ilimitados
                            </Typography.Text>
                        </Row>
                    </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                    <div
                        style={{
                            border: '1px solid #E5E5E5',
                            padding: '10px 15px',
                            borderRadius: '10px',
                        }}
                        className="d-flex flex-column"
                    >
                        <Row justify="center">
                            <Typography.Text className="smallheader">Growing</Typography.Text>
                        </Row>
                        <Row justify="center" style={{ marginTop: '5px' }}>
                            <Typography.Text className="smallparagraph">
                                El plan para empresas que están despegando alto
                            </Typography.Text>
                        </Row>
                        <Row justify="center" style={{ marginTop: '14px' }}>
                            <Typography.Text className="mediumheader bold">$199 usd / mes</Typography.Text>
                        </Row>
                        <Row justify="center" style={{ marginTop: '15px' }}>
                            <Button
                                type="primary"
                                loading={openingSub}
                                onClick={async () => {
                                    try {
                                        setOpeningSub(true)
                                        const r = await billingManager({
                                            // TODO: CAMBIAR PRICE ID
                                            action: 'subscribe',
                                            priceId: billingAccount?.forceTest
                                                ? 'price_1OVJ7OIr7OeAkMH9u8IeLWx4'
                                                : 'price_1NQvVrIr7OeAkMH9x2nfczbi',
                                            successUrl: billingAccount?.forceTest
                                                ? 'http://localhost:3000/settings?subtab=billingAccount&open=settings_subscription'
                                                : 'https://alpha.gigstack.pro/settings?subtab=billingAccount&open=settings_subscription',

                                            quantity: 1,
                                        })

                                        console.log(r)

                                        const data = r.data as any
                                        console.log(data)
                                        if (data?.error) {
                                            setOpeningSub(false)
                                            return message.error(data?.message, 5000)
                                        }

                                        setOpeningSub(false)
                                        if (data.session) {
                                            window.open(data.session?.url, '_blank')
                                        } else if (data.raw?.message) {
                                            message.error(data.raw?.message)
                                        }
                                    } catch (error: any) {
                                        message.error(error?.message)
                                    }
                                }}
                            >
                                Suscribirme
                            </Button>
                        </Row>
                        {/* TODO: ENVIAR A PAGINA DE PLANES  */}
                        <Row justify="center" style={{ marginTop: '15px' }}>
                            <Typography.Text
                                onClick={() => {
                                    navigate('/memberships')
                                }}
                                className="smallparagraph descriptions clickable"
                            >
                                ¿Buscas algo más pequeño?
                            </Typography.Text>
                        </Row>
                    </div>
                </Col>
            </Row>
        )

        // return <div className='d-flex flex-column' style={{ marginLeft: '10px', flex: 1 }}>
        //     <Row>
        //         <Col xs={24} className='d-flex flex-column' style={{ marginBottom: '10px' }}>
        //             <label>Suscribirme</label>
        //             <Button onClick={async () => {
        //                 try {
        //                     const r = await billingManager({
        //                         action: 'subscribe',
        //                         priceId: 'price_1OVJ7OIr7OeAkMH9u8IeLWx4',
        //                         successUrl: 'https://localhost:3000/settings?subtab=billing',
        //                         quantity: 1,

        //                     })

        //                     const data = r.data as any

        //                     if (data.raw?.message) {
        //                         return message.error(data.raw?.message)
        //                     }

        //                     if ((data as any)?.session) {
        //                         window.open((data as any)?.session?.url, '_blank')
        //                     }
        //                 } catch (error) {
        //                     console.log("🚀 ~ file: SuscriptionForm.tsx:80 ~ ", error)

        //                 }
        //             }}>Generar suscripción</Button>
        //             <Typography.Text className='smallparagraph descriptions' style={{ marginTop: '2px' }}>Genera suscripción</Typography.Text>
        //         </Col>

        //     </Row>
        // </div>
    }
    const ActiveSubscription = () => {
        return (
            <div className="d-flex flex-column">
                <Row style={{ marginTop: '20px' }}>
                    <Col xs={24} lg={12}>
                        <Typography.Text className="smallheader">Monto de la suscripción</Typography.Text>
                    </Col>
                    <Col xs={24} lg={12}>
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallparagraph">
                                {subscription?.status === 'active'
                                    ? `${returnCurrencyValue(plan?.amount / 100)} ${plan?.currency} / ${plan?.interval === 'year' ? 'año' : 'mes'}`
                                    : 'Sin plan activo'}
                            </Typography.Text>
                            <Typography.Text className="smallparagraph descriptions">
                                {subscription?.status === 'active'
                                    ? `Siguiente cobro ${activeSubscription ? moment(activeSubscription?.current_period_end * 1000).format('DD MMM YYYY HH:mm') : moment().add(1, 'month').startOf('month').format('DD MMM YYYY')}`
                                    : 'Contacta a soporte para retomar tu plan'}
                            </Typography.Text>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    // const SubscriptionCard = () => {
    //     return <Row style={{
    //         backgroundColor: '#fafbfd',
    //         border: '1px solid #E5E5E5',
    //         padding: '10px 15px',
    //         borderRadius: '10px',
    //     }}>
    //         {loaded ? <Star size={20} weight='regular' className='icon' /> : <MetroSpinner size={20} color='#5C5C5C' />}

    //     </Row>
    // }

    return (
        <Form form={usingForm}>
            <Row>
                <Col xs={24}>
                    <PlanHeader />
                </Col>
                <Col xs={24}>{!subscription ? <></> : activeSubscription && <ActiveSubscription />}</Col>
                <Col xs={24}>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={24} lg={12}>
                            <Typography.Text className="smallheader">Eventos disponibles</Typography.Text>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div className="d-flex flex-column">
                                <Typography.Text className="smallparagraph">
                                    {billingAccount?.credits} eventos restantes.
                                </Typography.Text>
                                <Typography.Text className="smallparagraph descriptions">
                                    Siguiente renovación{' '}
                                    {activeSubscription
                                        ? moment(activeSubscription?.current_period_end * 1000).format(
                                              'DD MMM YYYY HH:mm',
                                          )
                                        : moment().add(1, 'month').startOf('month').format('DD MMM YYYY')}
                                </Typography.Text>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={24} lg={12}>
                            <Typography.Text className="smallheader">Último evento utilizado</Typography.Text>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div className="d-flex flex-column">
                                <Typography.Text className="smallparagraph">
                                    {!billingAccount?.lastUsed
                                        ? ''
                                        : typeof billingAccount?.lastUsed !== 'number'
                                          ? moment(billingAccount?.lastUsed).format('DD MMMM YYYY HH:mm')
                                          : moment(billingAccount?.lastUsed).format('DD MMM YYYY HH:mm')}
                                </Typography.Text>
                            </div>
                        </Col>
                    </Row>
                </Col>
                {/* <Col xs={24}>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={24} lg={12}>
                            <Typography.Text className='smallheader'>Domiciliación</Typography.Text>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div className='d-flex flex-column'>
                                <Typography.Text className='smallparagraph'>{billingAccount?.credits} eventos restantes.</Typography.Text>
                                <Typography.Text className='smallparagraph descriptions'>Siguiente renovación {activeSubscription ? moment(activeSubscription?.current_period_end * 1000).format('DD MMM YYYY HH:mm') : moment().add(1, 'month').startOf('month').format('DD MMM YYYY')}</Typography.Text>
                            </div>
                        </Col>
                    </Row>
                </Col> */}

                {activeSubscription && (
                    <Col xs={24} style={{ marginTop: '25px' }}>
                        <Row justify="end">
                            <Button
                                disabled={!isBillingAccountAdmin}
                                type="primary"
                                htmlType="button"
                                onClick={async () => {
                                    if (!isBillingAccountAdmin) return
                                    const r = await billingManager({
                                        action: 'session',
                                    })
                                    const data = r.data as any
                                    if (data?.session) {
                                        window.open(data.session, '_blank')
                                    }
                                }}
                            >
                                {isBillingAccountAdmin
                                    ? 'Actualizar suscripción y/o tarjeta'
                                    : 'Pide a un administrador realizar cambios'}
                            </Button>
                        </Row>
                    </Col>
                )}
                {(!activeSubscription || activeSubscription?.status !== 'active') && (
                    <Col xs={24} style={{ marginTop: '25px' }}>
                        <NoSubscription />
                    </Col>
                )}
                {/* <Element title='Eventos Disponibles' description='La cantidad de automatizaciones o recursos a los que puede acceder tu cuenta en el periodo actual' value={`${billingAccount?.payAsYouGo ? `Pago por evento` : `${billingAccount?.credits} créditos disponibles`}`} /> */}
            </Row>
        </Form>
    )
}

export default SuscriptionForm
