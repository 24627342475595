import { FunctionComponent } from 'react'
import { SuspenseWithPerf, useSigninCheck } from 'reactfire'
import NotSignedRoutes from './NotSignedRoutes'
import { Route, Routes, useNavigate } from 'react-router-dom'
import MainLayout from '../Layouts/MainLayout'
import AuthScreensWrapper from '../auth/AuthScreensWrapper'
import Loader from '../appStates/LoadingView'
import Memberships from '../pages/Memberships'
import FinishSetup from '../pages/FinishSetup'
import { OAuthView } from '../pages/OAuthView'
// import DesignSystem from "../designSystem/DesignSystem";

type AuthWrapperProps = {
    children: JSX.Element
    fallback: JSX.Element
}

export const AuthWrapper: FunctionComponent<AuthWrapperProps> = ({ children, fallback }) => {
    const { data: signInCheckResult } = useSigninCheck()
    // console.log('', signInCheckResult)

    if (!children) {
        throw new Error('Children must be provided')
    }

    if (signInCheckResult?.signedIn === true) {
        return children
    } else {
        return fallback
    }
}
const MainRoutes = () => {
    const navigate = useNavigate()

    return (
        <SuspenseWithPerf traceId={'firebase-user-wait'} fallback={<Loader loading={true} />}>
            <AuthWrapper fallback={<NotSignedRoutes />}>
                {/* <>PASE AUTH </> */}
                <AuthScreensWrapper>
                    <Routes>
                        <Route path="/oauth" element={<OAuthView />} />
                        <Route path="/subscription" element={<Memberships />} />
                        <Route path="/memberships" element={<Memberships />} />
                        <Route
                            path="/finishSetup"
                            element={
                                <FinishSetup
                                    endsetup={() => {
                                        navigate('/gettingStarted')
                                    }}
                                />
                            }
                        />

                        <Route path="/:tab" element={<MainLayout element="home" />} />
                        {/* <Route path="/dashboard" element={<MainLayout element="home" />} />
                        <Route path="/payments" element={<MainLayout element="payments" />} />
                        <Route path="/paymentDetails" element={<MainLayout element="paymentDetails" />} />
                        <Route path="/invoices" element={<MainLayout element="invoices" />} />
                        <Route path="/invoiceDetails" element={<MainLayout element="invoiceDetails" />} />
                        <Route path="/receipts" element={<MainLayout element="receipts" />} />
                        <Route path="/collections" element={<MainLayout element="collections" />} />
                        <Route path="/products" element={<MainLayout element="products" />} />
                        <Route path="/clients" element={<MainLayout element="clients" />} />
                        <Route path="/integrations" element={<MainLayout element="integrations" />} />
                        <Route path="/settings" element={<MainLayout element="settings" />} /> */}
                        {/* <Route path="/designSys" element={<DesignSystem />} /> */}
                        {/* <Route path="/receiptDetails" element={<MainLayout element="receiptsDetail" />} />
                        <Route path="/clientDetails" element={<MainLayout element="clientDetails" />} /> */}

                        <Route path="*" element={<MainLayout element="404" />} />
                    </Routes>
                </AuthScreensWrapper>
            </AuthWrapper>
        </SuspenseWithPerf>
    )
}

export default MainRoutes
