import { Col, Divider, Radio, Row } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SettingsDivider from './Components/SettingsDivider'
import { SettingCard } from './Components/SettingCard'
import { LockKey, Notebook, Palette, Strategy, UserCircle, UsersFour } from '@phosphor-icons/react'
import { setData } from '../../context/dataSlice'
import { openModal } from '../../context/modalsSlice'
import { usePermissions } from '../../customHooks/usePermissions'
import { addParamToUrl } from '../../functions/UrlParams'

const GeneralSettings: React.FC = () => {
    const { subtab } = useSelector((state: any) => state.data)

    const dispatch = useDispatch()
    const { isTeamAdmin, isBillingAccountAdmin } = usePermissions()

    const BrandSettings = () => {
        return (
            <Row
                gutter={{
                    xs: 10,
                    md: 12,
                }}
            >
                <Col xs={24}>
                    {' '}
                    <Divider />{' '}
                </Col>

                <SettingsDivider
                    title={'Información Pública'}
                    description={'Configuración de la información pública de la marca que verán tus clientes.'}
                    options={[
                        <SettingCard
                            title="Marca"
                            description="Edita los colores, logo y nombre de tu marca"
                            icon={<Palette size={20} color="#6B7CF9" weight="regular" />}
                            onClick={() => {
                                dispatch(
                                    setData({
                                        item: 'settingsDrawerConfig',
                                        data: {
                                            type: 'brand',
                                            title: 'Información de la marca',
                                            description:
                                                'Edita el logo, colores y comunicación pública para tus clientes.',
                                        },
                                    }),
                                )
                                dispatch(openModal('settingsDrawerVisible'))
                                addParamToUrl('open', 'settings_brand')
                            }}
                        />,
                    ]}
                />
                <Col xs={24}>
                    {' '}
                    <Divider />{' '}
                </Col>
                <SettingsDivider
                    title={'Portal de cliente'}
                    description={
                        'Configura el portal donde todos tus clientes podrán entrar a ver los detalles de sus transacciones.'
                    }
                    options={[
                        <SettingCard
                            title="Portal de clientes"
                            description="Configura todas las opciones del portal de clientes."
                            icon={<UsersFour size={20} color="#6B7CF9" weight="regular" />}
                            onClick={() => {
                                dispatch(
                                    setData({
                                        item: 'settingsDrawerConfig',
                                        data: {
                                            type: 'customerPortal',
                                            title: 'Portal de clientes',
                                            description: 'Edita el acceso al portal de cliente.',
                                        },
                                    }),
                                )
                                dispatch(openModal('settingsDrawerVisible'))
                                addParamToUrl('open', 'settings_portal')
                            }}
                        />,
                    ]}
                />
            </Row>
        )
    }
    const PersonalInfoSettings = () => {
        return (
            <Row
                gutter={{
                    xs: 10,
                    md: 12,
                }}
            >
                <Col xs={24}>
                    {' '}
                    <Divider />{' '}
                </Col>

                <SettingsDivider
                    title={'Información personal'}
                    description={'Configura la información visible dentro de la plataforma.'}
                    options={[
                        <SettingCard
                            onClick={() => {
                                dispatch(
                                    setData({
                                        item: 'settingsDrawerConfig',
                                        data: {
                                            type: 'personal',
                                            title: 'Mi información',
                                            description: 'Edita nombre, correo, etc.',
                                        },
                                    }),
                                )
                                dispatch(openModal('settingsDrawerVisible'))
                                addParamToUrl('open', 'settings_personal')
                            }}
                            title="Mi información"
                            description="Edita nombre, correo, etc."
                            icon={<UserCircle size={20} color="#6B7CF9" weight="regular" />}
                        />,
                    ]}
                />
            </Row>
        )
    }

    const BillingAccountSettings = () => {
        return (
            <Row
                gutter={{
                    xs: 10,
                    md: 12,
                }}
            >
                <Col xs={24}>
                    {' '}
                    <Divider />{' '}
                </Col>

                <SettingsDivider
                    title={'Facturación'}
                    description={'Configura la información de tu cuenta de facturación.'}
                    options={[
                        <SettingCard
                            title="Cuenta de facturación"
                            description="Edita nombre, correo, etc."
                            icon={<Notebook size={20} color="#6B7CF9" weight="regular" />}
                            onClick={() => {
                                // if (!isBillingAccountAdmin) return message.warning('No tienes permisos para administrar la cuenta de facturación')
                                dispatch(
                                    setData({
                                        item: 'settingsDrawerConfig',
                                        data: {
                                            type: 'billing',
                                            title: 'Facturación',
                                            description: 'Configura la información de tu cuenta de facturación',
                                        },
                                    }),
                                )
                                dispatch(openModal('settingsDrawerVisible'))
                                addParamToUrl('open', 'settings_billing')
                            }}
                        />,
                    ]}
                />
                <Col xs={24}>
                    {' '}
                    <Divider />{' '}
                </Col>

                <SettingsDivider
                    title={'Suscripción '}
                    description={'Genera, actualiza o cancela la suscripción a gigstack.pro'}
                    options={[
                        <SettingCard
                            title="Plan"
                            description="Genera, actualiza o cancela la suscripción a gigstack.pro"
                            icon={<Strategy size={20} color="#6B7CF9" weight="regular" />}
                            onClick={() => {
                                // if (!isBillingAccountAdmin) return message.warning('No tienes permisos para administrar la cuenta de facturación')
                                dispatch(
                                    setData({
                                        item: 'settingsDrawerConfig',
                                        data: {
                                            type: 'subscription',
                                            title: 'Suscripción',
                                            description: 'Genera, actualiza o cancela la suscripción a gigstack.pro',
                                        },
                                    }),
                                )
                                dispatch(openModal('settingsDrawerVisible'))
                                addParamToUrl('open', 'settings_subscription')
                            }}
                        />,
                    ]}
                />
            </Row>
        )
    }

    const SecuritySettings = () => {
        return (
            <Row
                gutter={{
                    xs: 10,
                    md: 12,
                }}
            >
                <Col xs={24}>
                    {' '}
                    <Divider />{' '}
                </Col>

                <SettingsDivider
                    title={'Acceso'}
                    description={'Actualiza la sesión y los accesos a la plataforma.'}
                    options={[
                        <SettingCard
                            title="Mis accesos"
                            description="Configura y actualiza los accesos a la plataforma."
                            icon={<LockKey size={20} color="#6B7CF9" weight="regular" />}
                            onClick={() => {
                                dispatch(
                                    setData({
                                        item: 'settingsDrawerConfig',
                                        data: {
                                            type: 'security',
                                            title: 'Mis accesos',
                                            description: 'Consulta las sesiones y detalles de acceso.',
                                        },
                                    }),
                                )
                                dispatch(openModal('settingsDrawerVisible'))
                                addParamToUrl('open', 'settings_security')
                            }}
                        />,
                    ]}
                />
            </Row>
        )
    }

    return (
        // Your JSX code goes here
        <div>
            <Radio.Group
                value={subtab ?? 'team'}
                onChange={(v: any) => {
                    // setSelectedSubtab(v.target.value)
                    addParamToUrl('subtab', v.target.value)
                    dispatch(setData({ item: 'subtab', data: v.target.value }))
                }}
                defaultValue={subtab}
                style={{ marginBottom: 16 }}
            >
                <Radio.Button disabled={!isTeamAdmin} value="team" className="customRButton">
                    Marca
                </Radio.Button>
                <Radio.Button value="personal" className="customRButton">
                    Info. Personal
                </Radio.Button>
                <Radio.Button disabled={!isBillingAccountAdmin} value="billingAccount" className="customRButton">
                    Cuenta de facturación
                </Radio.Button>
                <Radio.Button value="security" className="customRButton">
                    Seguridad
                </Radio.Button>
            </Radio.Group>
            {/* Component content */}

            {(subtab === 'team' || !subtab) && <BrandSettings />}
            {subtab === 'personal' && <PersonalInfoSettings />}
            {subtab === 'billingAccount' && <BillingAccountSettings />}
            {subtab === 'security' && <SecuritySettings />}
        </div>
    )
}

export default GeneralSettings
