import { Row, Space, Tooltip, Typography, message } from 'antd'
import { CircleNotch, PauseCircle, PlayCircle, ShootingStar } from '@phosphor-icons/react'
import { getItemsAmounts } from '../../../functions/helpers'
import moment from 'moment'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { useState } from 'react'
import EditAction from '../../ElementActions/SingleActions/EditAction'

import { openModal } from '../../../context/modalsSlice'
import { useDispatch } from 'react-redux'
import { setData } from '../../../context/dataSlice'
import { ElementRelations } from '../../Relations/ElementRelations'
import RecurringEventsActions from '../../ElementActions/SingleActions/RecurringEventsActions'
import { CopyableElementId } from '../../UI/CopyableElementId'

type TypePaymentsColumnName =
    | 'client'
    | 'status'
    | 'amount'
    | 'create'
    | 'temporality'
    | 'relations'
    | 'nextRun'
    | 'Acciones'

export const RecurringPaymentsColumns = ({
    record,
    columnName,
}: {
    record: any
    columnName: TypePaymentsColumnName
}) => {
    const [actionOnPayment, setactionOnPayment] = useState(null)
    const fs = getFirestore()
    const dispatch = useDispatch()
    const PauseRecurringEvent = async (event: any) => {
        setactionOnPayment(event?.id)

        try {
            await updateDoc(doc(fs, `recurringEvents/${event?.id}`), {
                status: event?.status === 'paused' ? 'active' : 'paused',
                pausedReason: null,
            })
            setactionOnPayment(null)
        } catch (error: any) {
            message.error(error.message ?? 'Error al pausar evento')
            setactionOnPayment(null)
        }
    }
    switch (columnName) {
        case 'client':
            const name = record.client.name ?? record.client?.legal_name ?? record.client?.company
            const nameTruncated = name?.length > 25 ? name?.substring(0, 25) + '...' : name

            return (
                <Space direction="vertical" size="small">
                    <Row>
                        <Tooltip title={name}>
                            <Typography.Text
                                strong
                                className="p-base-regular neutral-1"
                                style={{ margin: 0, padding: 0 }}
                            >
                                {nameTruncated}{' '}
                                {!record.replicateElement?.livemode && (
                                    <Tooltip title="Modo de prueba">
                                        <ShootingStar
                                            size={16}
                                            className="icon"
                                            style={{
                                                marginLeft: '5px',
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </Typography.Text>
                        </Tooltip>
                    </Row>
                    {record.client.company && (
                        <Typography.Text
                            className="p-base-regular"
                            style={{ margin: 0, padding: 0, fontSize: '10px' }}
                        >
                            {record.client.company}
                        </Typography.Text>
                    )}
                    <CopyableElementId id={record?.id} idName="ID" />
                </Space>
            )

        case 'status':
            return (
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title={record.status === 'active' ? 'Pausar' : 'Activar'}>
                        <div
                            onClick={() => {
                                PauseRecurringEvent(record)
                            }}
                            className="customCircledTag"
                            style={{ width: '30px', height: '30px', marginTop: '5px' }}
                        >
                            {actionOnPayment === record?.id ? (
                                <CircleNotch size={20} weight="regular" className="icon" />
                            ) : record?.status === 'active' ? (
                                <PauseCircle className="icon" size={20} weight="regular" />
                            ) : (
                                <PlayCircle size={20} weight="regular" className="icon" />
                            )}
                        </div>
                    </Tooltip>
                </Row>
            )
        case 'amount':
            return (
                <Row align="middle">
                    <Typography.Text className="p-base-regular" style={{ margin: 0, padding: 0 }}>
                        {getItemsAmounts(record?.replicateElement?.items, record?.paidIn === 'bank').totalString}{' '}
                        {record?.replicateElement?.currency}
                    </Typography.Text>
                    {record?.replicateElement?.addTransactionFee && (
                        <Tooltip title="Se añadirá el fee de transacción">
                            {/* <PlusCircleOutlined style={{ marginLeft: '5px', fontSize: '10px', color: '#333' }} /> */}
                        </Tooltip>
                    )}
                </Row>
            )
        case 'create':
            return (
                <div>
                    <div>
                        <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }}>
                            {record.temporality === 'daily'
                                ? `Diario a las ${record.onTime}`
                                : record.temporality === 'weekly'
                                  ? `Semanal, los ${moment()
                                        .day(record.onWeekday ?? '01')
                                        .format('dddd')} ${record.onTime}`
                                  : `Mensual los días ${record.onDay} ${record.onTime}`}
                        </Typography.Text>
                    </div>

                    <div>
                        {' '}
                        <Typography.Text className="secondary" style={{ fontSize: '8px', marginBottom: '0px' }}>
                            {`Iniciando ${moment(record.startDate).format('DD/MM/YY')}`} terminando{' '}
                            {moment(record.endDate).format('DD/MM/YY')}
                        </Typography.Text>
                    </div>
                </div>
            )
        case 'temporality':
            return (
                <Typography.Text className="p-base-regular" style={{ marginBottom: '0px' }}>
                    {record.temporality === 'daily'
                        ? `Diario a las ${record.onTime}`
                        : record.temporality === 'weekly'
                          ? `Semanal, los ${moment()
                                .day(record.onWeekday ?? '01')
                                .format('dddd')} ${record.onTime}`
                          : `Mensual los días ${record.onDay} ${record.onTime}`}
                </Typography.Text>
            )
        case 'relations':
            // const relations = record.relations || []
            // return <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }} >{relations?.length > 0 ? `${relations.length} creados` : ''} </Typography.Text>

            return <ElementRelations record={record} elementType="recurringPayments" hideAdd={true} />
        case 'nextRun':
            const active = record.status === 'active'
            return (
                <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }}>
                    {active
                        ? record.nextRun
                            ? moment(record.nextRun).format('ddd D MMMM YYYY HH:mm ')
                            : record.pausedReason
                              ? record.pausedReason
                              : '-'
                        : '-'}
                </Typography.Text>
            )
        case 'Acciones':
            return (
                <>
                    <Space size="small">
                        <RecurringEventsActions
                            record={record}
                            customEdit={
                                <EditAction
                                    action={() => {
                                        dispatch(
                                            setData({
                                                item: 'payment',
                                                data: {
                                                    // from: 'recurringEvent',
                                                    ...record.replicateElement,
                                                    from: 'recurringEvent',
                                                    incomingFrom: record?.from,
                                                },
                                            }),
                                        )
                                        dispatch(setData({ item: 'recurringEventData', data: { ...record } }))
                                        dispatch(setData({ item: 'client', data: record?.replicateElement?.client }))
                                        dispatch(
                                            setData({
                                                item: 'selectedServices',
                                                data: record?.replicateElement?.items,
                                            }),
                                        )
                                        dispatch(openModal('paymentModalVisible'))
                                    }}
                                    loading={false}
                                />
                            }
                        />
                    </Space>
                </>
            )
        default:
            return <></>
    }
}
