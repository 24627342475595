import { Avatar, Button, Row, Tooltip, Typography, message } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from 'reactfire'
import { TeamDef } from '../../../interfaces/teamDef'
import { Question, Users } from '@phosphor-icons/react'
import { SearchInput } from '../Forms/Inputs'
import {
    GetBATeamsHelper,
    GetBillingAccountHelper,
    GetClients,
    GetServices,
    GetTeamHelper,
} from '../../context/databaseContextHelpers'
import { doc, updateDoc } from 'firebase/firestore'
import { RingSpinner } from 'react-spinners-kit'
import { setData } from '../../context/dataSlice'
import { openModal } from '../../context/modalsSlice'
import { getBillingData } from '../../functions/Billing'
// import { openModal } from "../../context/modalsSlice"

function TeamsSelectorPopover({ closePop }: { closePop?: Function }) {
    const [loading, setLoading]: any[] = useState([])
    const [search, setSearch] = useState('')
    const [showAll, setShowAll] = useState(false)
    const { teams } = useSelector((state: any) => state.team)
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const fs = useFirestore()
    const dispatch = useDispatch()
    const [messageApi, contextHolder] = message.useMessage()

    var teamsFiltered = teams.filter((team: TeamDef) =>
        team.brand?.alias?.toLowerCase().includes(search?.toLowerCase()),
    )
    if (!showAll) {
        teamsFiltered = teamsFiltered.slice(0, 3)
    } else {
        teamsFiltered = teamsFiltered.slice(0, teamsFiltered.length)
    }

    return (
        <div className="d-flex flex-column" style={{ minWidth: '200px', maxWidth: '200px' }}>
            {contextHolder}
            <Row
                justify="space-between"
                align="middle"
                style={{ borderBottom: '0.5px solid transparent', paddingBottom: '5px' }}
            >
                <Typography.Text style={{ color: '#474D66', fontSize: '10px', fontWeight: 600 }}>
                    Cambiar de cuenta
                </Typography.Text>
                <Question size={12} weight="regular" className="icon" />
            </Row>
            <SearchInput
                styleProps={{ margin: 0, padding: 0, marginBottom: '10px' }}
                name="search"
                placeholder="Busca un equipo"
                label={null}
                onChange={(v: any) => {
                    setSearch(v.target.value)
                }}
            />
            <div className="d-flex flex-column" style={{ marginTop: '5px', maxHeight: '420px', overflow: 'auto' }}>
                {teamsFiltered?.map((team: TeamDef) => {
                    return (
                        <Row
                            key={team.id}
                            style={{ marginTop: '3px', cursor: 'pointer' }}
                            className="teamRowSelector"
                            align="middle"
                            onClick={async (_: any) => {
                                try {
                                    setLoading([...loading, team.id])
                                    GetBATeamsHelper(user?.uid, dispatch)
                                    await updateDoc(doc(fs, 'users', user?.uid), {
                                        lastTeamViewed: team.id,
                                        lastBillingAccountViewed: team?.billingAccount,
                                    })
                                    dispatch(
                                        setData({
                                            item: 'clients',
                                            data: [],
                                        }),
                                    )
                                    GetTeamHelper(team.id, dispatch)
                                    GetBillingAccountHelper(team?.billingAccount, dispatch)
                                    GetServices(dispatch, team.id)
                                    GetClients(dispatch, team.id)
                                    //wait 2 seconds
                                    await new Promise((r) => setTimeout(r, 500))
                                    setLoading(loading.filter((id: string) => id !== team.id))
                                    messageApi.open({
                                        type: 'success',
                                        content: 'Cambiaste de cuenta',
                                    })
                                    getBillingData({ team: team, user: user, dispatch })

                                    // return window.location.reload();
                                } catch (error) {}
                            }}
                        >
                            {loading.includes(team?.id) ? (
                                <RingSpinner
                                    size={18}
                                    color={team?.brand?.primaryColor}
                                    loading={loading.includes(team?.id)}
                                />
                            ) : (
                                <Avatar size={18} src={team.brand?.logo} />
                            )}
                            <div className="d-flex flex-column" style={{ paddingLeft: '10px', flex: 1 }}>
                                <Tooltip title={(team.brand?.alias ?? '')?.length > 26 ? team.brand?.alias : null}>
                                    <Typography.Text
                                        style={{
                                            fontSize: '11px',
                                            maxLines: 1,
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {team.brand?.alias ?? '¿'}
                                    </Typography.Text>
                                </Tooltip>
                                <Typography.Text className="smallparagraph descriptions" style={{ fontSize: '10px' }}>
                                    {team?.id?.substring(0, 10)}..
                                </Typography.Text>
                            </div>
                            {team.membersIds?.length - 1 > 1 && (
                                <Tooltip
                                    title={`
                                ${team.membersIds.length} miembros
                                `}
                                >
                                    <Row align="middle">
                                        <Users size={12} weight="regular" />
                                        <Typography.Text
                                            style={{
                                                fontSize: '10px',
                                                padding: 0,
                                                margin: 0,
                                                paddingLeft: '1px',
                                                color: '#333',
                                            }}
                                        >
                                            {team.membersIds?.length}
                                        </Typography.Text>
                                    </Row>
                                </Tooltip>
                            )}
                        </Row>
                    )
                })}
            </div>
            {teams.length > 3 ? (
                <Button
                    type="link"
                    style={{ textAlign: 'left', padding: 0, fontSize: '10px' }}
                    onClick={() => setShowAll(!showAll)}
                >
                    {showAll ? 'Mostrar menos' : `Mostrar todos (${teams.length})`}
                </Button>
            ) : (
                <></>
            )}

            <Button
                hidden
                type="primary"
                disabled={billingAccount.price_id === 'free'}
                size="small"
                onClick={() => {
                    dispatch(openModal('createTeamVisible'))
                    closePop && closePop()
                }}
            >
                Nueva cuenta
            </Button>
            <Typography.Text
                className="smallparagraph descriptions"
                style={{ fontSize: '10px', marginTop: '5px' }}
                onClick={() => {
                    //copy to clipboard
                    navigator.clipboard.writeText(team.id)
                }}
            >
                {team.id}
            </Typography.Text>
            {/* <Row justify="end" align="middle"
                style={{
                    cursor: 'pointer',
                    marginTop: '5px',
                    borderTop: '0.5px solid #cecece',
                    paddingTop: '5px'
                }}
            >
                <Typography.Text style={{ color: '#474D66', fontSize: '10px', marginRight: '5px' }}>Cerrar sesión</Typography.Text>
                <SignOut size={10} weight="regular" />
            </Row> */}
        </div>
    )
}

export default TeamsSelectorPopover
