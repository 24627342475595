import {
    Button,
    Checkbox,
    Col,
    Empty,
    Form,
    FormInstance,
    Input,
    InputNumber,
    Modal,
    Popover,
    Row,
    Space,
    Table,
    Typography,
    message,
} from 'antd'
import { DateInput, NumberInput, SelectInput, TextInput } from '../Forms/Inputs'
import { CaretDown, CaretUp, Pencil, Plus, Tray } from '@phosphor-icons/react'
import {
    InvoiceEmailsComponent,
    InvoiceMetadata,
    InvoiceMoreOptionsSection,
    InvoiceTestMode,
} from './InvoiceComponents'
import { useDispatch, useSelector } from 'react-redux'
import { currecies } from '../../datasets/Currencies'
import { useEffect, useState } from 'react'
import { validateUUID } from '../../../helpers/helperFunctions'
import { collection, getDocs, where } from 'firebase/firestore'
import { useFirestore } from 'reactfire'
import { query } from 'firebase/firestore'
import { setData } from '../../context/dataSlice'
import { closeModal, openModal } from '../../context/modalsSlice'
import { relatedDocumentsColumns } from '../Tables/Columns/ComplementRelatedColumns'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { complementColumns } from '../Tables/Columns/ComplementColumns'
import { paymentForms } from '../../datasets/Fiscal'
import { generateCode, returnCurrencyValue } from '../../functions/helpers'
import { setFolio } from '../../context/folioSlice'
import { taxElementSet } from '../../../interfaces/invoicesDef'
import TaxesTable from '../Tables/TaxesTable'

dayjs.extend(utc)
dayjs.extend(timezone)

export const PaymentComplementRelationsModal = () => {
    const { paymentComplementRelationsModalVisible } = useSelector((state: any) => state.modals)

    const fs = useFirestore()
    const [formComp] = Form.useForm()
    const dispatch = useDispatch()

    const { team } = useSelector((state: any) => state.team)
    const {
        taxes,
        exchangeRates,
        invoiceComplement,
        selectedRelatedDocument,
        invoice: ctxInvoice,
    } = useSelector((state: any) => state.data)

    const [state, setState] = useState({
        search: null as string | null,
        loading: false as boolean,
        invoiceFound: null as any,
    })

    useEffect(() => {
        if (selectedRelatedDocument && paymentComplementRelationsModalVisible) {
            formComp.setFieldsValue({
                ...selectedRelatedDocument,
                lastBalance: selectedRelatedDocument.last_balance,
            })

            dispatch(
                setData({
                    item: 'taxes',
                    data: selectedRelatedDocument.taxes,
                }),
            )

            setState({
                ...state,
                search: selectedRelatedDocument.uuid,
                invoiceFound: selectedRelatedDocument,
            })

            handleSearchRelatedInvoice(selectedRelatedDocument.uuid ?? '')
        }

        // eslint-disable-next-line
    }, [paymentComplementRelationsModalVisible, selectedRelatedDocument])

    const handleSearchRelatedInvoice = async (value: string) => {
        try {
            if (!validateUUID(value)) {
                message.error('El folio fiscal es no tiene el formato correcto, asegurate de copiarlo correctamente')
                return
            }

            setState({
                ...state,
                loading: true,
            })
            const q = query(collection(fs, 'invoices'), where('uuid', '==', value), where('team', '==', team.id))
            const inv = await getDocs(q)
            if (!inv.empty) {
                const invData = inv.docs[0].data()
                setState({
                    ...state,
                    loading: false,
                    search: value,
                    invoiceFound: invData,
                })

                const complements = (
                    (ctxInvoice?.complements ?? [])
                        ?.map((c: any) => (c?.data ?? [])?.map((d: any) => d?.related_documents ?? []))
                        ?.flat() ?? []
                ).flat()
                const invoiceComplement = (complements ?? [])?.filter((c: any) => c.uuid === value)
                const complementsAmount = (invoiceComplement ?? [])?.reduce((acc: any, c: any) => acc + c.amount, 0)

                const amount = invData.total - complementsAmount

                formComp.setFieldsValue({
                    amount,
                    installment: invData.installment
                        ? invData.installment + ((invoiceComplement ?? []).length + 1)
                        : invoiceComplement?.length + 1,
                    lastBalance:
                        invData.lastBalance !== null && invData.lastBalance !== undefined
                            ? invData?.lastBalance - complementsAmount
                            : invData?.total,
                    currency: invData.currency ?? 'MXN',
                    exchange: (1 / exchangeRates[invData.currency ?? 'MXN']).toFixed(4),
                })

                if (invoiceComplement?.length > 0) {
                    dispatch(
                        setData({
                            item: 'taxes',
                            data: (selectedRelatedDocument?.taxes ?? []).map((t: any) => {
                                return {
                                    ...t,
                                    base: Number(amount / (1 + Number(t.rate))),
                                }
                            }),
                        }),
                    )
                }
            } else {
                setState({
                    ...state,
                    loading: false,
                    search: value,
                    invoiceFound: null,
                })
            }
        } catch (error) {
            message.error('Ocurrió un error al buscar el CFDI, intenta de nuevo')
            setState({
                ...state,
                search: value,
                loading: false,
            })
        }
    }

    const InvoiceFoundInfo = () => {
        return <div></div>
    }

    const InvoiceNotFoundInfo = () => {
        return <div></div>
    }

    const handleAddComplementRelation = async () => {
        try {
            formComp.submit()
            try {
                await formComp.validateFields()
            } catch (error) {
                return
            }
            const values = formComp.getFieldsValue()

            const relatedDocument = {
                uuid: state.search,
                amount: values.amount,
                installment: values.installment,
                last_balance: values.lastBalance,
                currency: values.currency ?? 'MXN',
                folio_number: state.invoiceFound?.folio_number,
                series: state.invoiceFound?.series,
                exchange: Number(values.exchange ?? 1),
                taxes: (taxes || []).map((t: any) => {
                    return {
                        ...t,
                        base: Number(values.amount / (1 + Number(t.rate))),
                    }
                }),
            }

            const relatedDocuments = invoiceComplement?.related_documents ?? []
            const found = (relatedDocuments ?? []).find((rd: any) => rd?.uuid === relatedDocument?.uuid)

            dispatch(
                setData({
                    item: 'invoiceComplement',
                    data: {
                        ...invoiceComplement,
                        related_documents: found
                            ? relatedDocuments.map((rd: any) => {
                                  if (rd.uuid === relatedDocument.uuid) {
                                      return relatedDocument
                                  }
                                  return rd
                              })
                            : [...relatedDocuments, relatedDocument],
                    },
                }),
            )

            dispatch(closeModal('paymentComplementRelationsModalVisible'))
            formComp.resetFields()
        } catch (error: any) {
            message.error(error?.message || 'Ocurrió un error al agregar el CFDI, intenta de nuevo')
        }
    }

    return (
        <Modal
            className="relevant-modal"
            zIndex={1002}
            title="Relaciones de CFDI's"
            width={window.innerWidth < 700 ? '100%' : 700}
            open={paymentComplementRelationsModalVisible}
            onCancel={() => {
                dispatch(closeModal('paymentComplementRelationsModalVisible'))
            }}
            onOk={handleAddComplementRelation}
        >
            <Form layout="vertical" form={formComp}>
                <Form.Item label="Buscar CFDI" name="uuid">
                    <Input.Search
                        placeholder="Ingresa el folio fiscal del CFDI"
                        enterButton
                        loading={state.loading}
                        onSearch={handleSearchRelatedInvoice}
                        style={{ width: '92%' }}
                    />
                </Form.Item>
                {!state.search && (
                    <Col xs={24}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Ninguna factura agregada" />
                    </Col>
                )}
                {state.search && state.invoiceFound ? <InvoiceFoundInfo /> : <InvoiceNotFoundInfo />}
                {/* TODO: Agregar los datos de la factura */}
                {state.search && (
                    <Col xs={24}>
                        <Row align="middle" style={{ marginBottom: '15px' }}>
                            <Typography.Text className="smallparagraph descriptions">Detalles del pago</Typography.Text>
                            <div
                                style={{
                                    height: '1px',
                                    borderBottom: '1px solid #D8DAE5',
                                    flex: 1,
                                    marginLeft: '15px',
                                }}
                            ></div>
                        </Row>
                        <Row gutter={10} style={{ marginTop: '10px' }}>
                            <Col xs={8} md={8} xl={8} xxl={8}>
                                <NumberInput
                                    name="amount"
                                    label="Monto del pago"
                                    min={0}
                                    max={100000000000}
                                    step={0.01}
                                    rules={[{ required: true, message: 'Es necesario capturar el monto del pago' }]}
                                    onBlur={(e: any) => {
                                        const newAmount = e?.target?.value
                                        if (isNaN(Number(newAmount))) return
                                        dispatch(
                                            setData({
                                                item: 'taxes',
                                                data: taxes.map((t: any) => {
                                                    return {
                                                        ...t,
                                                        base: Number(newAmount / (1 + Number(t.rate))),
                                                    }
                                                }),
                                            }),
                                        )
                                    }}
                                />
                            </Col>
                            <Col xs={8} md={8} xl={8} xxl={8}>
                                <NumberInput
                                    name="installment"
                                    label="Número de parcialidad"
                                    min={0}
                                    max={100000000000}
                                    step={1}
                                    rules={[
                                        { required: true, message: 'Es necesario capturar el número de parcialidad' },
                                    ]}
                                />
                            </Col>
                            <Col xs={8} md={8} xl={8} xxl={8}>
                                <NumberInput
                                    name="lastBalance"
                                    label="Último balance"
                                    min={0}
                                    max={100000000000}
                                    step={0.01}
                                    rules={[{ required: true, message: 'Es necesario capturar el último balance' }]}
                                />
                            </Col>
                            <Col xs={12}>
                                <SelectInput
                                    name="currency"
                                    label="Moneda"
                                    options={currecies}
                                    rules={[{ required: true, message: 'Debes capturar la moneda' }]}
                                    onChange={() => {
                                        formComp.setFieldsValue({
                                            exchange: (1 / exchangeRates[formComp.getFieldValue('currency')]).toFixed(
                                                4,
                                            ),
                                        })
                                    }}
                                />
                            </Col>
                            <Col xs={12}>
                                <NumberInput
                                    name="exchange"
                                    label="Tipo de cambio"
                                    min={0}
                                    max={100000000000}
                                    step={0.01}
                                    rules={[{ required: true, message: 'Debes capturar el tipo de cambio' }]}
                                />
                            </Col>
                            <Col xs={24}>
                                <Row align="middle" style={{ marginBottom: '15px' }}>
                                    <Typography.Text className="smallparagraph descriptions">
                                        Impuestos del pago
                                    </Typography.Text>
                                    <div
                                        style={{
                                            height: '1px',
                                            borderBottom: '1px solid #D8DAE5',
                                            flex: 1,
                                            marginLeft: '15px',
                                        }}
                                    ></div>
                                </Row>
                                <Row justify="end" align="middle">
                                    <Space direction="horizontal" style={{ margin: '10px 0px' }}>
                                        <Button
                                            icon={<Plus />}
                                            onClick={() => {
                                                dispatch(
                                                    setData({
                                                        item: 'taxes',
                                                        data: [
                                                            ...taxes,
                                                            {
                                                                type: 'IVA',
                                                                factor: 'Tasa',
                                                                rate: 0.16,
                                                                base: formComp.getFieldValue('amount') / 1.16,
                                                                withholding: false,
                                                            },
                                                        ],
                                                    }),
                                                )
                                            }}
                                        >
                                            IVA 16%
                                        </Button>
                                        <Button
                                            icon={<Plus />}
                                            onClick={() => {
                                                dispatch(
                                                    setData({
                                                        item: 'taxes',
                                                        data: [
                                                            ...taxes,
                                                            {
                                                                type: 'IVA',
                                                                factor: 'Tasa',
                                                                rate: 0,
                                                                base: formComp.getFieldValue('amount'),
                                                                withholding: false,
                                                            },
                                                        ],
                                                    }),
                                                )
                                            }}
                                        >
                                            IVA 0%
                                        </Button>
                                        <Button
                                            icon={<Plus />}
                                            onClick={() => {
                                                dispatch(openModal('addTaxModalVisible'))
                                            }}
                                        >
                                            Otro impuesto
                                        </Button>
                                    </Space>
                                </Row>
                                <TaxesTable
                                    customDelete={(record: any) => {
                                        dispatch(
                                            setData({
                                                item: 'taxes',
                                                data: taxes
                                                    .map((t: any) => taxElementSet(t))
                                                    .filter((t: any) => t.id !== record.id),
                                            }),
                                        )
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                )}
            </Form>
        </Modal>
    )
}

export const PaymentComplementModal = () => {
    const dispatch = useDispatch()
    const [formComp] = Form.useForm()

    const [showMore, setShowMore] = useState(false)
    const { paymentComplementModalVisible } = useSelector((state: any) => state.modals)
    const { invoiceComplement, invoice } = useSelector((state: any) => state.data)

    const handleAddPaymentComplement = async () => {
        try {
            formComp.submit()
            try {
                await formComp.validateFields()
            } catch (error) {
                return
            }
            if ((invoiceComplement?.related_documents ?? [])?.length === 0) {
                message.error('Debes agregar al menos un CFDI relacionado')
                return
            }

            const values = formComp.getFieldsValue()

            const complementData = {
                payment_form: values.paymentForm,
                related_documents: invoiceComplement?.related_documents ?? [],
                currency: values.currency,
                exchange: values.exchange,
                date: values.paymentDate
                    ? values.paymentDate.tz('America/Mexico_City').toISOString()
                    : dayjs().tz('America/Mexico_City').toISOString(),
                numOperacion: values.numOperacion || null,
                rfcEmisorCtaOrd: values.rfcEmisorCtaOrd || null,
                nomBancoOrdExt: values.nomBancoOrdExt || null,
                ctaOrdenante: values.ctaOrdenante || null,
                rfcEmisorCtaBen: values.rfcEmisorCtaBen || null,
                ctaBeneficiario: values.ctaBeneficiario || null,
            }

            console.log('complementData', complementData)

            const complement = {
                type: 'pago',
                data: [complementData],
            }

            let invoiceComplements = invoice?.complements ?? []
            if (!Array.isArray(invoiceComplements)) invoiceComplements = []

            dispatch(
                setData({
                    item: 'invoice',
                    data: {
                        ...invoice,
                        complements: invoiceComplement?.edit
                            ? [
                                  ...invoiceComplements?.slice(0, invoiceComplement?.index),
                                  complement,
                                  ...invoiceComplements?.slice(invoiceComplement?.index + 1),
                              ]
                            : [...(invoiceComplements), complement],
                    },
                }),
            )

            dispatch(
                setData({
                    item: 'invoice',
                    data: {
                        ...invoice,
                        complements: invoiceComplement?.edit
                            ? [
                                  ...invoiceComplements?.slice(0, invoiceComplement?.index),
                                  complement,
                                  ...invoiceComplements?.slice(invoiceComplement?.index + 1),
                              ]
                            : [...(invoiceComplements), complement],
                    },
                }),
            )

            dispatch(
                setData({
                    item: 'invoiceComplement',
                    data: null,
                }),
            )
            dispatch(closeModal('paymentComplementModalVisible'))
            formComp.resetFields()
        } catch (error: any) {
            console.log(error)
            message.error(error?.message || 'Ocurrió un error al agregar el complemento de pago, intenta de nuevo')
        }
    }

    return (
        <Modal
            title="Detalles del complemento de pago"
            className="ant-modal"
            width={800}
            zIndex={1001}
            open={paymentComplementModalVisible}
            onCancel={() => {
                dispatch(closeModal('paymentComplementModalVisible'))
            }}
            onOk={handleAddPaymentComplement}
        >
            <div style={{ marginTop: '15px' }}>
                {/* <PaymentComplementRelationsModal /> */}
                <Form
                    layout="vertical"
                    form={formComp}
                    initialValues={{
                        currency: 'MXN',
                        exchange: 1,
                        paymentDate: dayjs().tz('America/Mexico_City'),
                        ...invoiceComplement,
                        paymentForm: invoiceComplement?.payment_form ?? null,
                    }}
                >
                    <Col>
                        {/* <Typography.Text className="mediumparagraphbold">1. Agrega los CFDI's relacionados</Typography.Text> */}
                        <Row align="middle" style={{ marginBottom: '15px' }}>
                            <Typography.Text className="smallparagraph descriptions">
                                Agrega los CFDI's relacionados al pago
                            </Typography.Text>
                            <div
                                style={{
                                    height: '1px',
                                    borderBottom: '1px solid #D8DAE5',
                                    flex: 1,
                                    marginLeft: '15px',
                                }}
                            ></div>
                        </Row>
                        <Col>
                            <Row align="middle" justify="end" style={{ margin: '10px' }}>
                                <Button
                                    icon={<Plus />}
                                    onClick={() => {
                                        dispatch(openModal('paymentComplementRelationsModalVisible'))
                                    }}
                                >
                                    Agregar CFDI
                                </Button>
                            </Row>
                            <Table
                                size="small"
                                locale={{
                                    emptyText: (
                                        <div
                                            className="d-flex flex-column"
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Tray size={25} weight="regular" />
                                            <Typography.Text style={{ color: '#cecece' }}>
                                                No hay impuestos registrados para este producto / servicio
                                            </Typography.Text>
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    dispatch(openModal('paymentComplementRelationsModalVisible'))
                                                }}
                                                style={{ marginTop: '20px' }}
                                            >
                                                Añadir CFDI
                                            </Button>
                                        </div>
                                    ),
                                }}
                                columns={relatedDocumentsColumns.map((rc) => {
                                    if (rc.key === 'amount')
                                        return {
                                            ...rc,
                                            title: 'Total pagado',
                                            width: 150,
                                            render: (amount: any, record: any) => {
                                                return (
                                                    <Popover
                                                        content={
                                                            <div>
                                                                <InputNumber
                                                                    max={record.last_balance}
                                                                    defaultValue={amount}
                                                                    onPressEnter={(value: any) => {
                                                                        var e = Number(value.target.value)
                                                                        if (isNaN(e)) return
                                                                        dispatch(
                                                                            setData({
                                                                                item: 'invoiceComplement',
                                                                                data: {
                                                                                    ...invoiceComplement,
                                                                                    related_documents: (
                                                                                        invoiceComplement?.related_documents ??
                                                                                        []
                                                                                    ).map((doc: any) => {
                                                                                        if (doc.uuid === record.uuid) {
                                                                                            return {
                                                                                                ...doc,
                                                                                                amount: e,
                                                                                            }
                                                                                        }
                                                                                        return doc
                                                                                    }),
                                                                                },
                                                                            }),
                                                                        )
                                                                    }}
                                                                    onBlur={(value) => {
                                                                        var e = Number(value.target.value)
                                                                        if (isNaN(e)) return

                                                                        dispatch(
                                                                            setData({
                                                                                item: 'invoiceComplement',
                                                                                data: {
                                                                                    ...invoiceComplement,
                                                                                    related_documents: (
                                                                                        invoiceComplement?.related_documents ??
                                                                                        []
                                                                                    ).map((doc: any) => {
                                                                                        if (doc.uuid === record.uuid) {
                                                                                            return {
                                                                                                ...doc,
                                                                                                amount: e,
                                                                                            }
                                                                                        }
                                                                                        return doc
                                                                                    }),
                                                                                },
                                                                            }),
                                                                        )
                                                                    }}
                                                                    onChange={(e) => {
                                                                        // let newAmount = e?.replace(/[^0-9.]/g, '')
                                                                        // if (isNaN(Number(newAmount))) return
                                                                        // wait for the user to finish typing
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                    >
                                                        <Typography.Text className="smallparagraph descriptions">
                                                            {returnCurrencyValue(amount)}{' '}
                                                            <Pencil className="icon" style={{ marginLeft: '5px' }} />
                                                        </Typography.Text>
                                                    </Popover>
                                                )
                                            },
                                        }
                                    else {
                                        return rc
                                    }
                                })}
                                dataSource={invoiceComplement?.related_documents ?? []}
                                scroll={{ x: 1300 }}
                            />
                        </Col>
                    </Col>
                    <Col style={{ marginTop: '15px' }}>
                        {/* <Typography.Text className="mediumparagraphbold">2. Información del pago</Typography.Text> */}
                        <Row align="middle" style={{ marginBottom: '15px' }}>
                            <Typography.Text className="smallparagraph descriptions">Detalles del pago</Typography.Text>
                            <div
                                style={{
                                    height: '1px',
                                    borderBottom: '1px solid #D8DAE5',
                                    flex: 1,
                                    marginLeft: '15px',
                                }}
                            ></div>
                        </Row>
                        <Row gutter={10} style={{ marginTop: '15px' }}>
                            <Col xs={12} md={12} xl={12} xxl={12}>
                                <SelectInput
                                    name="paymentForm"
                                    label="Forma de pago"
                                    options={paymentForms}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecciona la forma de pago',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col xs={12} md={12} xl={12} xxl={12}>
                                <DateInput
                                    name="paymentDate"
                                    label="Fecha de pago"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecciona la fecha de pago',
                                        },
                                    ]}
                                    disabledDate={(currentDate: any) => {
                                        return currentDate > dayjs().endOf('day')
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <Col xs={12} md={12} xl={12} xxl={12}>
                                <SelectInput
                                    name="currency"
                                    label="Moneda"
                                    options={currecies}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecciona la moneda',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col xs={12} md={12} xl={12} xxl={12}>
                                <NumberInput
                                    name="exchange"
                                    label="Tipo de cambio"
                                    min={0}
                                    max={100000000000}
                                    step={0.01}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor escribe el tipo de cambio',
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <Button
                                type="link"
                                className="primary"
                                style={{ padding: '0px', cursor: 'pointer' }}
                                onClick={() => setShowMore(!showMore)}
                            >
                                Ver más opciones
                                {showMore ? (
                                    <CaretUp
                                        className="primary"
                                        size={16}
                                        weight="regular"
                                        style={{ marginLeft: '5px' }}
                                    />
                                ) : (
                                    <CaretDown
                                        className="primary"
                                        size={16}
                                        weight="regular"
                                        style={{ marginLeft: '5px' }}
                                    />
                                )}
                            </Button>
                            {showMore && (
                                <Row gutter={10} style={{ marginTop: '15px' }}>
                                    <Col xs={12} md={12} xl={12} xxl={12}>
                                        <TextInput
                                            name="numOperacion"
                                            label="Número de operación"
                                            description="Número de operación, de referencia, o clave SPEI que permita identificar la transacción."
                                        />
                                    </Col>
                                    <Col xs={12} md={12} xl={12} xxl={12}>
                                        <TextInput
                                            name="rfcEmisorCtaOrd"
                                            label="RFC cuenta ordenante"
                                            description="RFC de la entidad emisora de la cuenta de origen, es decir, la operadora, banco o institución financiera."
                                        />
                                    </Col>
                                    <Col xs={12} md={12} xl={12} xxl={12}>
                                        <TextInput name="nomBancoOrdExt" label="Nombre del banco ordenante." />
                                    </Col>
                                    <Col xs={12} md={12} xl={12} xxl={12}>
                                        <TextInput
                                            name="ctaOrdenante"
                                            label="Número de cuenta origen"
                                            description="Número de cuenta con la que se realizó el pago."
                                        />
                                    </Col>
                                    <Col xs={12} md={12} xl={12} xxl={12}>
                                        <TextInput
                                            name="rfcEmisorCtaBen"
                                            label="RFC cuenta beneficiaria"
                                            description="RFC de la entidad emisora de la cuenta de destino, es decir, la operadora, banco o institución financiera."
                                        />
                                    </Col>
                                    <Col xs={12} md={12} xl={12} xxl={12}>
                                        <TextInput
                                            name="ctaBeneficiario"
                                            label="Número de cuenta destino"
                                            description="Número de cuenta donde se recibió el pago."
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Row>
                    </Col>
                </Form>
            </div>
        </Modal>
    )
}

export const PaymentComplement = ({ pState, pSetState, form }: { pState: any; pSetState: any; form: FormInstance }) => {
    const dispatch = useDispatch()

    const { invoice } = useSelector((state: any) => state.data)
    const { recurringInvoice } = useSelector((state: any) => state.modals)
    const { folio } = useSelector((state: any) => state.folio)
    const [forceAutomaticFolioCheck, setForceAutomaticFolioCheck] = useState(folio.forceAutomaticFolio)
    const [automaticFolioValue] = useState(form.getFieldValue('folio'))

    const manageForceAutomaticFolioCheck = (v: boolean) => {
        setForceAutomaticFolioCheck(v)
        dispatch(
            setFolio({
                ...folio,
                forceAutomaticFolio: v,
            }),
        )
    }

    useEffect(() => {
        if (folio.forceAutomaticFolio && forceAutomaticFolioCheck) {
            form.setFieldsValue({
                folio: automaticFolioValue,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceAutomaticFolioCheck])

    return (
        <>
            <Row justify="center" style={{ marginTop: '30px' }}>
                <Col xs={24}>
                    <Typography.Title className="bigparagraph" level={5}>
                        2. Información General
                    </Typography.Title>
                    <Row justify="space-between" gutter={10}>
                        <Col xs={24} lg={recurringInvoice ? 24 : 12}>
                            <TextInput
                                name="series"
                                label="Serie"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor escribe la serie de la factura',
                                    },
                                ]}
                            />
                        </Col>
                        {!recurringInvoice && (
                            <>
                                <Col xs={12} style={{ marginBottom: '1rem' }}>
                                    <NumberInput
                                        name="folio"
                                        label="Folio"
                                        min={0}
                                        max={100000000000}
                                        disabled={forceAutomaticFolioCheck}
                                        propsStyle={{ marginBottom: '.5rem' }}
                                        step={1}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor escribe el folio de la factura',
                                            },
                                        ]}
                                    />
                                    <Form.Item
                                        name="forceAutomaticFolioCheck"
                                        valuePropName="checked"
                                        initialValue={forceAutomaticFolioCheck}
                                    >
                                        <Checkbox
                                            checked={forceAutomaticFolioCheck}
                                            onChange={(v) => {
                                                manageForceAutomaticFolioCheck(v.target.checked)
                                            }}
                                        >
                                            Folio Automático
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </Row>
                    <Row justify="start" style={{ width: 'auto' }}>
                        <Button
                            type="link"
                            className="primary"
                            style={{ padding: '0px' }}
                            onClick={() =>
                                pSetState({
                                    ...pState,
                                    showMoreOptions: pState?.showMoreOptions ? false : true,
                                })
                            }
                        >
                            Ver opciones adicionales
                            {pState.showMoreOptions ? (
                                <CaretUp className="primary" size={16} weight="regular" style={{ marginLeft: '5px' }} />
                            ) : (
                                <CaretDown
                                    className="primary"
                                    size={16}
                                    weight="regular"
                                    style={{ marginLeft: '5px' }}
                                />
                            )}
                        </Button>
                    </Row>
                    {pState.showMoreOptions && (
                        <InvoiceMoreOptionsSection state={pState} setState={pSetState} form={form} />
                    )}
                </Col>
                <Col xs={24}>
                    <Typography.Title className="bigparagraph" level={5}>
                        3. Detalles de los pagos
                    </Typography.Title>
                    <Table
                        size="small"
                        columns={complementColumns}
                        dataSource={invoice?.complements?.map((c: any) => c.data)}
                        scroll={{ x: 300 }}
                    />
                    <Button
                        icon={<Plus />}
                        style={{ width: '100%' }}
                        onClick={() => {
                            const idk = generateCode(15, 'idpkey')
                            dispatch(
                                setData({
                                    item: 'idempotency_key',
                                    data: idk,
                                }),
                            )
                            dispatch(openModal('paymentComplementModalVisible'))
                        }}
                    >
                        Agregar complemento de pago
                    </Button>
                </Col>
            </Row>
            <InvoiceEmailsComponent />
            <InvoiceTestMode />
            <InvoiceMetadata />
        </>
    )
}
