import React, { useState } from 'react'
import { Form, FormInstance, Row, Col, message, Button, Tag, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'
import { SelectInput, TextAreaInput } from './Inputs'
import { setTeam } from '../../context/teamsSlice'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import SwitchHolder from './SwitchHolder'
import { PaperPlaneTilt } from '@phosphor-icons/react'

interface ElementEmailTemplateFormProps {
    incomingForm?: FormInstance // Replace 'any' with the appropriate type for incomingForm
    buttonTitle?: string
    template: string
    label: string
    replacers?: string[]
}

const ElementEmailTemplateForm: React.FC<ElementEmailTemplateFormProps> = ({
    incomingForm,
    buttonTitle,
    template,
    label,
    replacers,
}) => {
    const { settingsDrawerConfig } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const [messageApi, contextHolder] = message.useMessage()

    const [focusingOn, setFocusingOn] = useState(template)

    const usingForm = incomingForm ?? form

    // Your component logic here

    return (
        <Form
            form={usingForm}
            layout="vertical"
            initialValues={{ ...team?.defaults }}
            onFinish={async (values: any) => {
                try {
                    messageApi.open({
                        type: 'loading',
                        content: 'Actualizando información...',
                        duration: 0,
                    })
                    const tup = {
                        [`defaults.${template}`]: values[template] ?? '',
                        [`defaults.receiptsRemindersTemplate`]:
                            values['receiptsRemindersTemplate'] ?? team?.defaults?.receiptsRemindersTemplate ?? '',
                    }
                    if (values['forceInvoiceBcc']) {
                        tup[`defaults.forceInvoiceBcc`] = values['forceInvoiceBcc']
                    }

                    if (values['invoiceMessageComplements']) {
                        tup[`defaults.invoiceMessageComplements`] =
                            values['invoiceMessageComplements'] ?? team?.defaults?.invoiceMessageComplements ?? ''
                    }

                    if (values['invoiceMessageEgress']) {
                        tup[`defaults.invoiceMessageEgress`] =
                            values['invoiceMessageEgress'] ?? team?.defaults?.invoiceMessageEgress ?? ''
                    }

                    dispatch(setTeam({ ...team, defaults: { ...team?.defaults, ...values } }))
                    await updateDoc(doc(getFirestore(), 'teams', team?.id), tup)
                    messageApi.destroy()
                    message.success('Información actualizada correctamente')
                    dispatch(closeModal('settingsDrawerVisible'))
                } catch (error: any) {
                    message.error('Ocurrió un error al actualizar la información.')
                }
            }}
        >
            {contextHolder}
            <Row>
                <Col xs={24} className="d-flex flex-column">
                    <label>Variables disponibles</label>
                    <Row wrap style={{ marginTop: '5px' }}>
                        {replacers?.map((item, index) => {
                            return (
                                <Tag
                                    className="clickable"
                                    onClick={() => {
                                        usingForm.setFieldsValue({
                                            [focusingOn]: (usingForm.getFieldValue(focusingOn) ?? '') + ` {${item}} `,
                                        })

                                        usingForm.getFieldInstance(focusingOn)?.focus()
                                    }}
                                    color="blue"
                                    key={item}
                                >
                                    {item}
                                </Tag>
                            )
                        })}
                    </Row>
                </Col>
                <Col xs={24} style={{ marginTop: '25px' }}>
                    <TextAreaInput name={template} label={label} placeholder="Estimado .... " />
                </Col>

                {settingsDrawerConfig.type === 'defaults.invoiceMessage' && (
                    <Col xs={24} style={{ marginTop: '25px' }}>
                        <TextAreaInput
                            onFocus={() => {
                                setFocusingOn('invoiceMessageComplements')
                            }}
                            name={'invoiceMessageComplements'}
                            label="Plantilla para complementos de pago"
                            placeholder="Estimado .... "
                        />
                    </Col>
                )}

                {settingsDrawerConfig.type === 'defaults.invoiceMessage' && (
                    <Col xs={24} style={{ marginTop: '25px' }}>
                        <TextAreaInput
                            onFocus={() => {
                                setFocusingOn('invoiceMessageEgress')
                            }}
                            name={'invoiceMessageEgress'}
                            label="Plantilla para notas de crédito"
                            placeholder="Estimado .... "
                        />
                    </Col>
                )}

                {settingsDrawerConfig?.type === 'defaults.receiptsEmailsTemplate' && (
                    <Col xs={24} style={{ marginTop: '25px' }}>
                        <TextAreaInput
                            description="Este mensaje se enviará en los últimos días del mes para recordar que pueden realizar su factura"
                            onFocus={() => {
                                setFocusingOn('receiptsRemindersTemplate')
                            }}
                            name={'receiptsRemindersTemplate'}
                            label="Plantilla para recordatorios de recibos"
                            placeholder="Estimado .... "
                        />
                    </Col>
                )}

                {settingsDrawerConfig?.type === 'defaults.invoiceMessage' && (
                    <Col xs={24} style={{ marginTop: '25px' }}>
                        <SelectInput
                            mode="tags"
                            options={[
                                ...(team?.defaults?.forceInvoiceCc ?? []).map((item: any) => ({
                                    label: item,
                                    value: item,
                                })),
                                { label: user?.email, value: user?.email },
                            ]}
                            placeholder="Enviar copia a"
                            name="forceInvoiceBcc"
                            label="Forzar BCC en envío de correo de facturas"
                            description="Enviaremos los correos con copia a los correos seleccionados"
                        />
                    </Col>
                )}

                <Col xs={24} style={{ marginTop: '15px' }}>
                    <Row justify="end">
                        <Button type="primary" htmlType="submit">
                            {buttonTitle ? buttonTitle : 'Actualizar mensajes'}
                        </Button>
                    </Row>
                </Col>

                <Col xs={24} style={{ marginTop: '20px' }}>
                    <Typography.Text className="mediumparagraphbold">
                        Envío de correos electrónicos en recursos test
                    </Typography.Text>
                    <Col xs={24} style={{ marginTop: '15px' }}>
                        <SwitchHolder
                            switchState={team?.defaults?.avoidGlobalInvoiceEmailsOnTest}
                            title="Deshabilita el envío de correos en facturas de prueba"
                            description="No enviaremos correos de facturas de prueba"
                            icon={<PaperPlaneTilt weight="regular" />}
                            changeState={async (value: boolean) => {
                                try {
                                    dispatch(
                                        setTeam({
                                            ...team,
                                            defaults: { ...team?.defaults, avoidGlobalInvoiceEmailsOnTest: value },
                                        }),
                                    )
                                    await updateDoc(doc(getFirestore(), 'teams', team?.id), {
                                        'defaults.avoidGlobalInvoiceEmailsOnTest': value,
                                    })
                                    message.success('Configuración actualizada correctamente')
                                } catch (error: any) {
                                    message.error(error?.message ?? 'Ocurrió un error al actualizar la configuración.')
                                }
                            }}
                        />
                    </Col>
                </Col>
            </Row>
        </Form>
    )
}

export default ElementEmailTemplateForm
