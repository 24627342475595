import { Button, Col, Drawer, Form, Row, Switch, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { useEffect, useState } from 'react'
import { GetClients, GetExchangeRates, GetServices } from '../context/databaseContextHelpers'
import { useAnalytics, useAuth } from 'reactfire'
import moment from 'moment'
import SelectClientCard from '../components/Cards/SelectClientCard'
import PaymentForm from '../components/ComponentIndividual/PaymentForm'
import ItemsSelection from '../components/ComponentIndividual/ItemsSelection'
import { logEvent } from 'firebase/analytics'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
import { setData } from '../context/dataSlice'
import useSetContextCloseDrawers from '../context/setContext/SetContextCloseDrawers'
import { CaretDoubleRight, X } from '@phosphor-icons/react'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { getItemsAmounts } from '../functions/helpers'

export const CreateReceipt: React.FC = () => {
    const dispatch = useDispatch()
    const { receiptModalVisible } = useSelector((state: any) => state.modals)
    const { receipt } = useSelector((state: any) => state.modals)
    const auth = useAuth()
    const { team } = useSelector((state: any) => state.team)
    const { services, clients, exchangeRates, client, periodicity, testmode, servicesLoaded, clientsLoaded } =
        useSelector((state: any) => state.data)
    const { selectedServices } = useSelector((state: any) => state.data)
    const [loading, setloading] = useState(false)
    const [, /*apiloading*/ setapiloading] = useState(false)
    const [, /*error*/ seterror] = useState(null)
    const [, /*receiptOk*/ setreceiptOk] = useState(null)
    const analytics = useAnalytics()
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const [form] = Form.useForm()
    const setContextCloseDrawers = useSetContextCloseDrawers({ form })

    useEffect(() => {
        form.setFieldsValue({
            ...receipt,
            periodicity: receipt?.periodicity ?? 'month',
            currency: receipt?.currency ?? 'MXN',
            livemode: receipt ? receipt?.livemode : true,
            test: receipt ? !receipt?.livemode : false,
            payment_form: receipt?.payment_form ?? '01',
            exchange: receipt?.exchange ?? 1,
            exchange_rate: receipt?.exchange_rate ?? 1,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receipt])

    const GenerateReceipt = async (v: any) => {
        if (selectedServices.length === 0) {
            message.error('Por favor selecciona al menos un servicio')
            return
        }
        if (!client) {
            message.error('Por favor selecciona un cliente')
            return
        }
        const validDays = validTime()

        const body = {
            ...v,
            ...form.getFieldsValue(),
            validDays,
            validUntil: validDays,
            type: 'create_receipt',
            livemode: !testmode,
            team: team?.id,
            billingAccount: billingAccount?.id,
            forcedId: receipt?.id ?? null,
            timestamp: receipt?.timestamp ?? null,
            items: selectedServices,
            client,
            from: receipt ? receipt.from : 'manual',
        }

        if (receipt) {
            body.validUntil = !periodicity ? receipt.validUntil : validTime()
            setloading(true)
            await updateDoc(doc(getFirestore(), 'receipts', receipt.id), {
                id: receipt.id,
                total: getItemsAmounts(selectedServices).total,
                amount: getItemsAmounts(selectedServices).total * 100,
                ...body,
            })
            message.success('Recibo editado correctamente')
            setloading(false)
            dispatch(closeModal('receiptModalVisible'))
            setContextCloseDrawers()
            dispatch(
                setData({
                    item: 'selectedServices',
                    data: [],
                }),
            )
            dispatch(
                setData({
                    item: 'receipt',
                    data: null,
                }),
            )
        } else {
            try {
                setloading(true)
                setapiloading(true)
                logEvent(analytics, 'receiptCreated', {})
                const resp = await SignedInternalAPIRequest(body, 'receiptsApp/v1/create', auth.currentUser)
                message.success(receipt ? 'Recibo editado correctamente' : 'Recibo creado correctamente')
                setreceiptOk({ ...resp.receipt, hasError: false })

                setloading(false)
                setapiloading(false)
                dispatch(closeModal('receiptModalVisible'))
                setContextCloseDrawers()
            } catch (error: any) {
                message.error(
                    receipt
                        ? `Ocurrio un error al editar el recibo: ${error}`
                        : `Ocurrió un error al crear el recibo: ${error}`,
                )
                seterror(error.message)
                setapiloading(false)
                setreceiptOk(null)
                setloading(false)
            }
        }
    }
    const validTime = () => {
        var validDays = moment().endOf('day').valueOf()
        switch (periodicity) {
            case 'day':
                validDays = moment().endOf('day').valueOf()
                break
            case 'week':
                validDays = moment().day(7).endOf('day').valueOf()
                break
            case 'fortnight':
                //VALID DAYS IS EQUAL TO MIDDLE OR END OF THE CURRENT MONTH
                validDays = moment().day(15).endOf('day').valueOf()
                break
            case 'month':
                validDays = moment().endOf('month').valueOf()

                break
            case 'two_months':
                validDays = moment().endOf('month').add(1, 'month').valueOf()

                break
            default:
                validDays = moment().endOf('day').valueOf()
                break
        }

        return validDays
    }

    useEffect(() => {
        if (receiptModalVisible) {
            if (services.length === 0 && !servicesLoaded) {
                GetServices(dispatch, team.id)
            }

            if (clients.length === 0 && !clientsLoaded) {
                GetClients(dispatch, team.id, clientsLoaded)
            }

            if (Object.keys(exchangeRates).length === 0) {
                GetExchangeRates(dispatch)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receiptModalVisible])

    return (
        <Drawer
            style={{ marginTop: testmode ? '32px' : '0px', paddingBottom: testmode ? '32px' : '0px' }}
            title={
                <div style={{ position: 'relative', paddingBottom: '0px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            //center the text
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        {receipt ? 'Editar recibo de venta' : 'Crear recibo de venta'}
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X
                                    className="icon clickable"
                                    size={20}
                                    onClick={() => {
                                        dispatch(closeModal('receiptModalVisible'))
                                        dispatch(
                                            setData({
                                                item: 'client',
                                                data: null,
                                            }),
                                        )
                                        form.resetFields()
                                        setContextCloseDrawers()
                                    }}
                                />
                                <></>
                                <Button
                                    type="primary"
                                    disabled={loading}
                                    onClick={async () => {
                                        await GenerateReceipt(form.getFieldsValue())
                                    }}
                                >
                                    {receipt ? 'Editar' : 'Crear'}
                                    <CaretDoubleRight
                                        size={15}
                                        style={{ marginLeft: '5px' }}
                                        className=""
                                        weight="regular"
                                    />
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            // onClose={() => {
            //     dispatch(closeModal('receiptModalVisible'))
            //     setContextCloseDrawers()
            // }
            // }
            closable={false}
            open={receiptModalVisible}
            width={'100%'}
        >
            <div>
                <Form form={form} onFinish={GenerateReceipt} layout="vertical">
                    <Row justify="center">
                        <Col xs={23} lg={17}>
                            <Typography.Title className="bigparagraph" level={5}>
                                1. Cliente
                            </Typography.Title>
                            <SelectClientCard />
                            <div style={{ height: '10px' }}></div>
                            <Typography.Title className="bigparagraph" level={5} style={{ marginTop: '60px' }}>
                                2. Selecciona items
                            </Typography.Title>
                            <ItemsSelection />
                            <Typography.Title className="bigparagraph" level={5}>
                                3. Forma de pago
                            </Typography.Title>
                            <PaymentForm form={form} />
                            <Col xs={23} style={{ marginTop: '15px' }}>
                                <Typography.Title className="bigparagraph" level={5}>
                                    4. ¿Deseas crear el recibo en modo prueba?
                                </Typography.Title>
                                <Row gutter={{ xs: 12, lg: 12 }}>
                                    <Switch
                                        checked={testmode}
                                        onChange={(v) => {
                                            dispatch(
                                                setData({
                                                    item: 'testmode',
                                                    data: v,
                                                }),
                                            )
                                        }}
                                    />
                                </Row>
                                {testmode && (
                                    <Typography.Text className="smallparagraph" style={{ marginTop: '15px' }}>
                                        {' '}
                                        El recibo se realizará en modo pruebas.
                                    </Typography.Text>
                                )}
                            </Col>

                            <Col xs={23}>
                                <Row justify="end">
                                    <div>
                                        <Button
                                            htmlType="submit"
                                            id="continueWithReceipt"
                                            loading={loading}
                                            type="primary"
                                            style={{ flex: 1 }}
                                        >
                                            {receipt ? 'Editar' : 'Crear'}
                                            <CaretDoubleRight
                                                size={15}
                                                style={{ marginLeft: '5px' }}
                                                className=""
                                                weight="regular"
                                            />
                                        </Button>
                                    </div>
                                </Row>
                            </Col>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Drawer>
    )
}
