import { Button, Col, Form, Row, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { SelectInput, TextInput } from './Inputs'
import { useDispatch, useSelector } from 'react-redux'
import { countryLabeledListWithCodeAlpha3 } from '../../datasets/Countries'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import ErrorsInFormComponent from './ErrorsInFormComponent'
import { setUser } from '../../context/userSlice'
import { CompanyRoles } from '../../datasets/CompanyRoles'
import { RemoveUndefined } from '../../functions/helpers'
import { usePostHog } from 'posthog-js/react'
import moment from 'moment'

interface PersonalProfileFormProps {
    buttonTitle?: string
    onUpdate?: (data: any) => void
    incomingUser?: any
    onValuesUpdate?: (data: any) => void
}

const PersonalProfileForm: React.FC<PersonalProfileFormProps> = ({
    buttonTitle,
    onUpdate,
    incomingUser,
    onValuesUpdate,
}) => {
    const dispatch = useDispatch()
    const posthog = usePostHog()
    const { user } = useSelector((state: any) => state.user)
    const [form] = Form.useForm()
    const [errors, setErrors] = useState<any>([])
    const [loading, setLoading] = useState(false)

    const [messageApi, contextHolder] = message.useMessage()

    useEffect(() => {}, [])

    const onFinish = async (values: any) => {
        try {
            form.validateFields()
        } catch (error: any) {
            setErrors(error.errorFields)
            return
        }

        try {
            const db = doc(getFirestore(), 'users', user?.uid)
            setLoading(true)

            const update = RemoveUndefined({ ...user, ...values })
            await updateDoc(db, update)
            try {
                const firstTime = window.location?.pathname?.includes('finishSetup')
                posthog?.capture(firstTime ? 'first_time_user_setup' : 'user_updated_profile', {
                    [firstTime ? 'personalProfileCompleted' : 'profileUpdated']: moment().format('DD/MM/YYYY HH:mm'),
                })

                posthog?.identify(update.uid, {
                    email: update.email,
                    firstName: update.firstName,
                    lastName: update.lastName,
                    companyRole: update.companyRole,
                    country: update.country,
                    phone: update.phone ?? '',
                    name: `${update.firstName} ${update.lastName}`,
                })
            } catch (error) {}
            dispatch(setUser(update))
            if (onUpdate) {
                onUpdate({ ...user, ...update })
            }
            setLoading(false)
            messageApi.success('Información actualizada correctamente')
        } catch (error) {
            console.log(error)
            messageApi.error('Ocurrio un error al actualizar la información')
            setLoading(false)
        }
    }

    return (
        <Form
            layout="vertical"
            onValuesChange={(changedValues, allValues) => {
                if (onValuesUpdate) {
                    onValuesUpdate(allValues)
                }
            }}
            onFinish={onFinish}
            onError={(e: any) => {
                setErrors(e.errorFields)
            }}
            onErrorCapture={(e: any) => {
                setErrors(e.errorFields)
            }}
            form={form}
            initialValues={{ ...user, ...incomingUser }}
        >
            {contextHolder}
            <Row gutter={[32, { xs: 12, lg: 12 }]}>
                {/* <Col xs={24} lg={24}>
                    <Divider />
                </Col> */}
                <Col xs={24} md={24} lg={12}>
                    <TextInput
                        label="Nombre"
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: 'Este campo es requerido',
                            },
                        ]}
                    />
                </Col>
                <Col xs={24} md={24} lg={12}>
                    <TextInput
                        label="Apellidos"
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: 'Este campo es requerido',
                            },
                        ]}
                    />
                </Col>
                <Col xs={24}>
                    <TextInput
                        label="Correo electrónico"
                        // description='Por el momento no se puede cambiar el correo, contact a soporte si necesitas hacer cambios.'
                        readOnly
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Este campo es requerido',
                            },
                        ]}
                    />
                </Col>
                <Col xs={24} lg={24}>
                    <TextInput
                        label="Teléfono"
                        rules={[
                            {
                                //pattern can contain a + at the beginning, and then only numbers
                                required: true,
                                pattern: new RegExp(/^[+]?[0-9]*$/),
                                message: 'El teléfono debe contener el area y el número, sin espacios ni guiones.',
                            },
                        ]}
                        placeholder="Número de 10 digitos"
                        name="phone"
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <SelectInput
                        label="País de residencia"
                        rules={[
                            {
                                required: true,
                                message: 'Este campo es requerido',
                            },
                        ]}
                        placeholder="Selecciona tu país de residencia"
                        options={countryLabeledListWithCodeAlpha3.map((c) => ({ label: c.name, value: c.code }))}
                        name="country"
                    />
                </Col>

                <Col xs={24} lg={12}>
                    <SelectInput
                        label="Rol en la compañía"
                        rules={[
                            {
                                required: true,
                                message: 'Este campo es requerido',
                            },
                        ]}
                        placeholder="Selecciona el area a la que perteneces"
                        options={CompanyRoles.map((c) => ({ label: c.label, value: c.value }))}
                        name="companyRole"
                    />
                </Col>

                <Col xs={24}>
                    <ErrorsInFormComponent title={''} form={form} formErrors={errors} />
                </Col>

                <Col xs={24}>
                    <Row>
                        <Button type="primary" className="btn-primary w-100" loading={loading} htmlType="submit">
                            {buttonTitle ?? 'Actualizar información'}
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default PersonalProfileForm
