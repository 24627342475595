import { Button, Col, Divider, Form, FormInstance, Modal, Row, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'
import { integrations } from '../../datasets/Integrations'
import { IntegrationOnboarding } from '../../components/Integrations/IntegrationOnboarding'
import { ImagesSquare, Question } from '@phosphor-icons/react'
import { useEffect, useState } from 'react'
import { addParamToUrl } from '../../functions/UrlParams'
import { SelectInput, TextAreaInput, TextInput } from '../../components/Forms/Inputs'
import { countryLabeledListWithCodeAlpha3 } from '../../datasets/Countries'
import { clabe } from 'clabe-validator'
import SwitchHolder from '../../components/Forms/SwitchHolder'
import ErrorsInFormComponent from '../../components/Forms/ErrorsInFormComponent'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { GetTeamHelper } from '../../context/databaseContextHelpers'
import { usePostHog } from 'posthog-js/react'
import moment from 'moment'

const SpeiConnectionForm = ({
    form,
    state,
    setState,
}: {
    form: FormInstance
    state: any
    setState: (v: any) => void
}) => {
    const posthog = usePostHog()
    const fs = getFirestore()
    const dispatch = useDispatch()
    const { team } = useSelector((state: any) => state.team)

    const handleBankConnection = async (values: any) => {
        try {
            setState(() => {
                return {
                    ...state,
                    loading: true,
                    error: null,
                }
            })
            let clabeCheck = {} as any
            if (values.country === 'MEX') {
                clabeCheck = clabe.validate(values.clabe)
                if (!clabeCheck?.ok) {
                    throw new Error('La CLABE no es válida')
                }
            }
            try {
                const firstTime = !team?.bank?.completed
                posthog.capture(firstTime ? 'spei_completed' : 'spei_changed', {
                    date: moment().format('DD/MM/YYYY HH:mm'),
                })
            } catch (error) {}

            await updateDoc(doc(fs, 'teams', team.id), {
                bank: {
                    bic: values.bicCode ?? null,
                    clabe: values.clabe,
                    completed: true,
                    country: values.country,
                    holder_name: values.holderName,
                    iban: values.ibanCode ?? null,
                    help: values.help ?? null,
                    swift: values.swiftCode ?? null,
                    voucherRequired: state.voucherRequired,
                    ...clabeCheck,
                },
            })

            setState(() => {
                return {
                    ...state,
                    error: null,
                    loading: false,
                }
            })
            GetTeamHelper(team.id, dispatch)
            dispatch(closeModal('speiModalVisible'))
            return message.success('Cuenta bancaria guardada correctamente')
        } catch (error: any) {
            setState(() => {
                return {
                    ...state,
                    loading: false,
                    error: error?.message,
                }
            })
        }
    }

    return (
        <div style={{ padding: '20px 24px' }}>
            <Typography.Text className="mediumparagraphbold">Conecta tu cuenta bancaria</Typography.Text>
            <Typography.Text className="smallparagraph descriptions">
                Para poder recibir pagos mediante SPEI, necesitamos que nos proporciones tu información bancaria.
            </Typography.Text>

            <Form form={form} layout="vertical" style={{ marginTop: '15px' }} onFinish={handleBankConnection}>
                <TextInput
                    label="Beneficiario"
                    name="holderName"
                    rules={[{ required: true, message: 'Es necesario capturar el beneficiario' }]}
                />

                <SelectInput
                    label="País"
                    options={countryLabeledListWithCodeAlpha3.map((c) => {
                        return {
                            label: c.name,
                            value: c.code,
                        }
                    })}
                    name="country"
                    rules={[{ required: true, message: 'Necesitas seleccionar el país de tu cuenta bancaria' }]}
                />

                <TextInput
                    label="Cuenta CLABE"
                    name="clabe"
                    onChange={(v: any) => {
                        const clabeV = clabe.validate(v.target.value)
                        console.log(clabeV)
                        if (clabeV.ok) {
                            form.setFieldsValue({ bank: clabeV.tag })
                        } else {
                            form.setFieldsValue({ bank: '' })
                        }
                    }}
                    rules={[{ required: true, message: 'Es necesario capturar la cuenta CLABE' }]}
                />

                <Row gutter={12}>
                    <Col xs={24} md={12}>
                        <TextInput label="Banco" name="bank" description="Nombre del banco o institución financiera" />
                    </Col>
                    <Col xs={24} md={12}>
                        <TextInput
                            label="Código SWIFT"
                            name="swiftCode"
                            description="Society for Worldwide Interbank Financial Telecommunication"
                        />
                    </Col>
                </Row>

                <Row gutter={12}>
                    <Col xs={24} md={12}>
                        <TextInput
                            label="Código IBAN"
                            name="ibanCode"
                            description="International Bank Account Number"
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <TextInput label="Código BIC" name="bicCode" description="Bank Identifier Code" />
                    </Col>
                </Row>

                <Divider />

                <SwitchHolder
                    changeState={async (v: any) => {
                        setState(() => {
                            return {
                                ...state,
                                voucherRequired: v,
                            }
                        })
                    }}
                    switchState={state.voucherRequired}
                    icon={<ImagesSquare size={20} className="icon" weight="regular" />}
                    title="¿Requerir captura de la transferencia?"
                    description="Solicitaremos a tus clientes que adjunten una captura de la transferencia"
                />

                <Col style={{ marginTop: '10px' }}>
                    <TextAreaInput
                        label="Instrucciones extra"
                        name="help"
                        description="Le mostraremos estas instrucciones al usuario en el portal de pagos"
                    />
                </Col>

                {state.error && (
                    <Col xs={24}>
                        <ErrorsInFormComponent form={form} title="Error en los datos" errorMessage={state.error} />
                    </Col>
                )}
            </Form>
        </div>
    )
}

export const SpeiModal = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const { speiModalVisible } = useSelector((state: any) => state.modals)
    const { team } = useSelector((state: any) => state.team)
    const speiIntegration = integrations(team).find((i) => i.id === 'spei')

    const [state, setState] = useState({
        step: team?.bank?.completed ? 1 : 0,
        error: null as string | null,
        loading: false,
        voucherRequired: false,
    })

    useEffect(() => {
        if (speiModalVisible) {
            addParamToUrl('open', 'spei')
            form.setFieldsValue({
                holderName: team?.bank?.holder_name ?? '',
                country: team?.bank?.country ?? '',
                clabe: team?.bank?.clabe ?? '',
                bank: team?.bank?.tag ?? '',
                swiftCode: team?.bank?.swift ?? '',
                ibanCode: team?.bank?.iban ?? '',
                bicCode: team?.bank?.bic ?? '',
                help: team?.bank?.help ?? '',
            })

            setState(() => {
                return {
                    ...state,
                    step: team?.bank?.completed ? 1 : 0,
                    voucherRequired: team?.bank?.voucherRequired ?? false,
                }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [speiModalVisible])

    const ToRender = () => {
        switch (state.step) {
            case 0:
                return (
                    <IntegrationOnboarding
                        logo={speiIntegration?.logo ?? ''}
                        title="Conecta SPEI con Gigstack"
                        description="Cobra y factura automáticamente con SPEI"
                        bullets={[
                            { type: 'positive', description: 'Cobra mediante transferencia bancaria' },
                            { type: 'positive', description: 'Crea links de pago personalizados' },
                            { type: 'positive', description: 'Aprueba las transferencias manualmente' },
                        ]}
                        IntegrationFooter={<></>}
                    />
                )
            case 1:
                return <SpeiConnectionForm form={form} state={state} setState={(v: any) => setState(v)} />
            default:
                return <></>
        }
    }

    return (
        <Modal
            open={speiModalVisible}
            onCancel={() => {
                dispatch(closeModal('speiModalVisible'))
            }}
            className="integrationModal"
            title={null}
            footer={null}
            style={{ top: 20 }}
        >
            <ToRender />

            <div style={{ padding: '20px 24px 20px 24px' }}>
                <Row justify="space-between">
                    <Row>
                        <Button icon={<Question className="icon clickable" weight="regular" size={16} />}>
                            <Typography.Text className="mediumparagraphbold">¿Cómo funciona?</Typography.Text>
                        </Button>
                    </Row>
                    <Row>
                        <Button
                            loading={state.loading}
                            type="primary"
                            onClick={() => {
                                if (state.step === 0) {
                                    setState(() => {
                                        return {
                                            ...state,
                                            step: 1,
                                        }
                                    })
                                } else {
                                    form.submit()
                                }
                            }}
                        >
                            {state.step === 0 ? 'Conectar' : 'Guardar'}
                        </Button>
                    </Row>
                </Row>
            </div>
        </Modal>
    )
}
