import { CircleNotch, Envelope, EnvelopeOpen } from '@phosphor-icons/react'
import { Divider, Row, Tag, Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import { HandleEmailStatus } from '../../functions/helpers'

interface Email {
    id: string
    timestamp: number
    subject: string
    lastEvent: string
    lastEventAt: number
    to: string[]
}

interface EmailsTableProps {
    emailStatus: string
    emailsData: any
    customHeader?: React.ReactNode
}
interface EmailCardProps {
    email: Email
}

const EmailsTable: React.FC<EmailsTableProps> = ({ emailStatus, emailsData, customHeader }) => {
    return (
        <div style={{ marginTop: '5px' }}>
            {customHeader ? (
                customHeader
            ) : (
                <Row justify="space-between" align="middle" style={{ marginLeft: '10px' }}>
                    <Typography.Title level={5} style={{ display: 'flex', alignItems: 'center' }}>
                        <Envelope style={{ marginRight: '10px' }} size={20} weight="regular" />
                        <span>Correos enviados</span>
                    </Typography.Title>
                </Row>
            )}
            {emailStatus === 'loading' ? (
                <CircleNotch size={15} weight="regular" />
            ) : (
                emailsData?.map((email: any) => <EmailCard key={email.id} email={email} timestamp={email.timestamp} />)
            )}
        </div>
    )
}

interface EmailCardProps {
    email: Email
    timestamp: number
}

const EmailCard: React.FC<EmailCardProps> = ({ email }) => {
    return (
        <div style={{ marginTop: '5px', marginLeft: '0px', backgroundColor: '#fafbfd', padding: '10px' }}>
            <Row align="middle" justify="space-between" key={email.id} style={{ width: '100%' }}>
                <Row>
                    <Row align="middle" style={{ flex: 1 }}>
                        {email?.lastEvent === 'open' ? (
                            <EnvelopeOpen size={20} weight="regular" />
                        ) : (
                            <Envelope size={20} weight="regular" />
                        )}
                        <Divider type="vertical" />
                        <div style={{ alignItems: 'start', marginLeft: '5px' }} className="d-flex flex-column">
                            <Typography.Text style={{ margin: 0, padding: 0, marginTop: '10px', fontSize: '8px' }}>
                                {moment(email.timestamp).format('DD MMM YYYY HH:mm')}
                            </Typography.Text>
                            <Typography.Text
                                style={{
                                    margin: 0,
                                    padding: 0,
                                    marginBottom: '5px',
                                    marginTop: '5px',
                                    fontSize: '12px',
                                }}
                            >
                                "{email.subject}"
                            </Typography.Text>
                        </div>
                    </Row>
                </Row>
                <Tag color={HandleEmailStatus(email.lastEvent).color} style={{ fontSize: '11px', marginLeft: '20px' }}>
                    {HandleEmailStatus(email.lastEvent).message}{' '}
                    {moment(email.lastEventAt ? email.lastEventAt * 1000 : email.timestamp).fromNow(false)}
                </Tag>
            </Row>
            <Row wrap>
                {email?.to?.map((to, index) => (
                    <Tag style={{ fontSize: '10px', marginTop: '5px' }} key={index}>
                        {to}
                    </Tag>
                ))}
            </Row>
        </div>
    )
}

export default EmailsTable
