import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { Automation } from '../interfaces/automationsDef'
import { optimizedInvoiceDefSet } from '../interfaces/invoicesDef'
import { optimizedPaymentDefSet } from '../interfaces/paymentsDef'
import { optimizedReceiptDefSet } from '../interfaces/receiptsDef'
import { ClientDef, clientSet } from '../interfaces/clientDef'

//CREATE AND EXPORT A FUNCTION TO GENERATE A RANDOM CODE WITH THE OPTION OF A PREFIX
export function generateCode(length: number = 10, prefix: string = '') {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return `${prefix}${prefix ? '_' : ''}${result}`
}

export function generateMetadataKeys(metadata: any) {
    if (!metadata) return []
    return Object.keys(metadata).map((key) => {
        return {
            value: metadata[key]?.label,
            key,
        }
    })
}

export const generateObjectFromKeys = (arr: any) => {
    return arr?.reduce((acc: any, curr: any) => {
        acc[curr.key] = curr.value
        return acc
    }, {})
}

export const manageAutomations = ({
    automations,
    toCreate,
    from,
}: {
    automations?: [Automation]
    toCreate: 'payment' | 'invoice'
    from: 'payments' | 'invoices'
}) => {
    const automation: Automation = {
        type: toCreate,
        from,
        ref: from,
        action: 'create',
    }

    return [...(automations || []), { ...automation }]
}

export const validateUUID = (uuid: string) => {
    const regexUUID = /^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}$/
    return regexUUID.test(uuid)
}

export const handleDef = (type: 'payments' | 'invoices' | 'receipts') => {
    switch (type) {
        case 'payments':
            return optimizedPaymentDefSet
        case 'invoices':
            return optimizedInvoiceDefSet
        case 'receipts':
            return optimizedReceiptDefSet
    }
}

export const teamCompleteGettingStarted = (team: any) => {
    const conditions = [
        { name: 'defaults', value: team?.defaults?.completed },
        { name: 'facturapi', value: team?.facturapi?.completed },
    ]

    const optionalConditions = [
        { name: 'bank', value: team?.bank?.completed },
        { name: 'whmcs', value: team?.whmcs?.completed },
        { name: 'stripe', value: team?.stripe?.completed },
        { name: 'zapier', value: team?.zapier?.completed },
        { name: 'openpay', value: team?.openpay?.completed },
        { name: 'googlesheets', value: team?.googlesheets?.completed },
    ]

    const mandatoryConditionsMet = conditions.every((condition) => condition.value)
    const atLeastOneOptionalConditionMet = optionalConditions.some((condition) => condition.value)

    const completedSteps = [
        ...conditions.filter((condition) => condition.value).map((condition) => condition.name),
        ...optionalConditions.filter((condition) => condition.value).map((condition) => condition.name),
    ]

    let nextStep = ''
    if (!completedSteps.includes('facturapi')) {
        nextStep = 'facturapi'
    } else if (!completedSteps.includes('defaults') && completedSteps.includes('facturapi')) {
        nextStep = 'defaults'
    } else {
        nextStep = 'integrations'
    }

    return {
        nextStep,
        completedSteps,
        isComplete: mandatoryConditionsMet && atLeastOneOptionalConditionMet,
        integrationIsConnected: atLeastOneOptionalConditionMet,
    }
}

export async function clientDataHelper(clientId?: string): Promise<ClientDef | null> {
    try {
        const fs = getFirestore()
        const docRef = doc(fs, 'clients', clientId ?? 'client_id')
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            return clientSet(docSnap.data())
        } else {
            return null
        }
    } catch (error) {
        return null
    }
}
