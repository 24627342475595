import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RecurringEventCalendarDef, RecurringEventCalendarDefSet } from '../../../interfaces/RecurringDef'
import { Button, Col, DatePicker, Descriptions, Divider, Form, Row, Space, Tooltip, Typography } from 'antd'
import moment from 'moment'
import dayjs from 'dayjs'
import { Calendar, CalendarBlank, PencilSimple } from '@phosphor-icons/react'
import type { DescriptionsProps } from 'antd'
import { SelectInput } from '../Forms/Inputs'
import { FormInstance } from 'antd/lib'
import { setData } from '../../context/dataSlice'
import { SummarizeMessage } from '../Calendarize/SummarizeRecurring'
interface DivStyle {
    backgroundColor: string
}

const RecurringEventDetails = ({ style, form }: { style?: DivStyle; form?: FormInstance }) => {
    const dispatch = useDispatch()
    const { recurringEventData } = useSelector((state: any) => state.data)
    const event: RecurringEventCalendarDef = RecurringEventCalendarDefSet(recurringEventData ?? {})
    const { temporality } = recurringEventData ?? {}
    const [loaded /*setLoaded*/] = useState<boolean>(true)
    const [calendarError, setCalendarError] = useState<any>(null)
    const start = dayjs(event?.startDate ?? moment().valueOf()).valueOf()
    const end = dayjs(event?.endDate ?? moment().add(1, 'day').valueOf()).valueOf()

    const HaveCalendar = () => {
        var timely: { label: any; labelUnit: string; unit: 'day' | 'month' | 'week' | 'day' } =
            event?.temporality === 'monthly'
                ? { label: 'Mensual', labelUnit: 'meses', unit: 'month' }
                : event?.temporality === 'daily'
                  ? { label: 'Diario', labelUnit: 'días', unit: 'day' }
                  : event.temporality === 'weekly'
                    ? { label: 'Semanal', labelUnit: 'semanas', unit: 'week' }
                    : { label: 'Único', labelUnit: 'días', unit: 'day' }
        const items: DescriptionsProps['items'] = [
            {
                key: '1',
                label: 'Temporalidad',
                children: (
                    <Typography.Text className="smallparagraph">
                        {event?.temporality === 'monthly'
                            ? 'Mensual'
                            : event?.temporality === 'daily'
                              ? 'Diario'
                              : event.temporality === 'weekly'
                                ? 'Semanal'
                                : 'Único'}
                    </Typography.Text>
                ),
            },
            {
                key: '2',
                label: 'Fecha de inicio',
                children: (
                    <Typography.Text className="smallparagraph">
                        {dayjs(event.startDate).locale('es_MX').format('DD MMM YYYY HH:mm')}
                    </Typography.Text>
                ),
            },
            {
                key: '3',
                label: 'Fecha de fin',
                children: (
                    <Typography.Text className="smallparagraph">
                        {dayjs(event.endDate).locale('es_MX').format('DD MMM YYYY HH:mm')}{' '}
                        <span className="descriptions" style={{ marginLeft: '5px' }}>
                            {' '}
                            ({dayjs(end).diff(start, timely.unit, false)} {timely.labelUnit})
                        </span>
                    </Typography.Text>
                ),
            },
            {
                key: '4',
                label: 'Día de ejecución',
                children: (
                    <Typography.Text className="smallparagraph">
                        {event?.temporality !== 'daily'
                            ? `Día ${event.onDay} del mes`
                            : `Todos los días a las ${event.onTime}`}
                    </Typography.Text>
                ),
            },
            {
                key: '5',
                label: 'Siguiente ejecución',
                children: (
                    <Typography.Text className="smallparagraph">
                        {dayjs(event.nextRun).format('DD MMM YYYY HH:mm')}{' '}
                        <span className="descriptions" style={{ marginLeft: '5px' }}>
                            ({moment(event.nextRun).fromNow()})
                        </span>
                    </Typography.Text>
                ),
            },
            {
                key: '6',
                label: 'Ejecutado',
                children: (
                    <Typography.Text className="smallparagraph">
                        {recurringEventData?.relations?.length}{' '}
                        {recurringEventData?.relations?.length > 1 ? 'veces' : 'vez'}
                    </Typography.Text>
                ),
            },
        ]
        return (
            <div className="d-flex flex-column">
                <Descriptions column={3} title="" layout="vertical" bordered items={items} size="small" />
            </div>
        )
    }
    const MonThlyCal = () => {
        const elementStyle = {
            borderRadius: '50%',
            height: '30px',
            width: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            fontSize: '12px',
        }
        const click = (val: any) => {
            dispatch(
                setData({
                    item: 'recurringEventData',
                    data: {
                        ...recurringEventData,
                        onDay: val,
                        localChanges: true,
                    },
                }),
            )
        }
        const element = (d: any) => {
            return (
                <div
                    onClick={() => click(d)}
                    className={recurringEventData.onDay === d ? 'calendarselecteditem' : 'calendarselectionitem'}
                    key={d}
                    style={elementStyle}
                >
                    {d}
                </div>
            )
        }
        return (
            <Space
                style={{
                    border: calendarError === 'monthly' ? '1px solid red' : 'none',
                    borderRadius: '4px',
                    marginLeft: '10px',
                    marginTop: '10px',
                }}
                wrap
            >
                {Array.from(Array(30).keys()).map((d) => {
                    return element(d + 1)
                })}
            </Space>
        )
    }
    const WeeklyCal = () => {
        const weekdays = Array.from(Array(7).keys()).map((d) => {
            return {
                label: moment().day(d).format('dddd'),
                value: d,
            }
        })
        return (
            <Space
                style={{
                    border: calendarError === 'weekly' ? '1px solid red' : 'none',
                    borderRadius: '4px',
                    marginLeft: '10px',
                }}
                wrap
            >
                {weekdays.map((d) => {
                    return (
                        <Tooltip key={d.label} title={d.label}>
                            <div
                                onClick={() => {
                                    dispatch(
                                        setData({
                                            item: 'recurringEventData',
                                            data: {
                                                ...recurringEventData,
                                                onWeekday: d.value,
                                                localChanges: true,
                                            },
                                        }),
                                    )
                                }}
                                className={
                                    event.onWeekday === d.value ? 'calendarselecteditem' : 'calendarselectionitem'
                                }
                                style={{
                                    borderRadius: '50%',
                                    height: '30px',
                                    width: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'white',
                                    fontSize: '12px',
                                }}
                            >
                                {d.label.at(0)}
                            </div>
                        </Tooltip>
                    )
                })}
            </Space>
        )
    }
    const ToSetCalendar = () => {
        const SumM = SummarizeMessage({
            startDate: recurringEventData.startDate,
            endDate: recurringEventData.endDate,
            onTime: recurringEventData.onTime ?? '',
            temporality: recurringEventData.temporality ?? '',
            onWeekday: recurringEventData.onWeekday ?? null,
            onDay: recurringEventData.onDay ?? null,
        })

        return (
            <Row gutter={{ xs: 10 }}>
                <Col xs={23} lg={23} style={{ marginBottom: '0px' }}>
                    <SelectInput
                        placeholder="Tipo de recurrencia"
                        rules={[{ required: true, message: 'Por favor selecciona la temporalidad' }]}
                        name="temporality"
                        onChange={(v: any) => {}}
                        options={[
                            {
                                label: 'Diario',
                                value: 'daily',
                            },
                            {
                                label: 'Semanal',
                                value: 'weekly',
                            },
                            {
                                label: 'Mensual',
                                value: 'monthly',
                            },
                        ]}
                        label="Temporalidad"
                    />
                </Col>
                <Col xs={23} lg={temporality === 'daily' ? 23 : 12}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Por favor selecciona una fecha',
                            },
                        ]}
                        style={{ width: '100%' }}
                        label="Fecha de inicio y de fin"
                        name="startEndDate"
                    >
                        <DatePicker.RangePicker
                            style={{ width: '100%' }}
                            suffixIcon={<CalendarBlank size={17} weight="regular" className="icon" />}
                            format="DD MMM YYYY HH:mm"
                            disabledDate={(c: any) => {
                                return false
                                // return c?.isBefore(dayjs().add(-1, 'days'));
                            }}
                            presets={[
                                { label: '7 Días', value: [dayjs(), dayjs().add(7, 'd')] },
                                { label: '14 Días', value: [dayjs(), dayjs().add(14, 'd')] },
                                { label: '30 Días', value: [dayjs(), dayjs().add(30, 'd')] },
                                { label: '90 Días', value: [dayjs(), dayjs().add(90, 'd')] },
                                { label: '1 año', value: [dayjs(), dayjs().add(1, 'y')] },
                            ]}
                            showHour
                            showTime
                        />
                    </Form.Item>
                </Col>

                {temporality === 'monthly' && (
                    <Col xs={23} lg={12}>
                        <div className="d-flex flex-column">
                            <label className="labelrequired">Día de ejecución</label>
                            <MonThlyCal />
                        </div>
                    </Col>
                )}
                {temporality === 'weekly' && (
                    <Col xs={23} lg={12}>
                        <div className="d-flex flex-column">
                            <label className="labelrequired" style={{ marginBottom: '10px' }}>
                                Día de ejecución
                            </label>
                            <WeeklyCal />
                        </div>
                    </Col>
                )}

                <Col xs={24}>
                    <Row>
                        <Calendar
                            size={17}
                            weight="regular"
                            className="icon"
                            style={{
                                marginRight: '5px',
                            }}
                        />
                        <Typography.Text style={{ whiteSpace: 'pre-wrap' }} className="smallparagraph descriptions">
                            {' '}
                            {SumM?.message}
                        </Typography.Text>
                    </Row>
                </Col>
            </Row>
        )
    }

    return (
        <Col
            xs={24}
            className="d-flex flex-column"
            style={{
                width: '100%',
                borderRadius: '8px',
                backgroundColor: style?.backgroundColor ?? '#fafbfd',
                padding: '10px',
                marginTop: '15px',
            }}
        >
            {/* Add content here */}
            <Row justify="space-between" align="middle">
                <label>
                    Calendario de recurrencia{' '}
                    {recurringEventData?.localChanges && recurringEventData?.id ? '(cambios sin guardar)' : ''}
                </label>
                {recurringEventData?.id && !recurringEventData.edit && (
                    <Button
                        icon={<PencilSimple size={15} weight="regular" className="icon" />}
                        onClick={() => {
                            form?.setFieldsValue({
                                ...event,
                                startEndDate: [dayjs(recurringEventData.startDate), dayjs(recurringEventData.endDate)],
                                temporality: recurringEventData?.temporality,
                            })

                            dispatch(
                                setData({
                                    item: 'recurringEventData',
                                    data: {
                                        ...recurringEventData,
                                        edit: true,
                                    },
                                }),
                            )
                        }}
                    >
                        Editar Calendario
                    </Button>
                )}
            </Row>
            <Divider style={{ padding: 0, margin: '8px 0px' }}></Divider>
            {loaded ? (
                <Form
                    onError={(e: any) => {
                        setCalendarError(e.errorFields)
                    }}
                    onErrorCapture={(e: any) => {
                        setCalendarError(e.errorFields)
                    }}
                    onValuesChange={(changedFields, allFields) => {
                        // if (!allFields.startEndDate[0] || !allFields.startEndDate[1]) return
                        dispatch(
                            setData({
                                item: 'recurringEventData',
                                data: {
                                    ...recurringEventData,
                                    startDate: allFields.startEndDate[0]?.valueOf() ?? '',
                                    endDate: allFields.startEndDate[1]?.valueOf() ?? '',
                                    onTime: allFields.startEndDate[0]?.format('HH:mm') ?? '',
                                    temporality: allFields.temporality ?? '',
                                    onWeekday: recurringEventData.onWeekday ?? null,
                                    onDay: recurringEventData.onDay ?? null,
                                },
                            }),
                        )
                    }}
                    form={form}
                    initialValues={{
                        ...recurringEventData,
                        startEndDate: recurringEventData.startDate
                            ? [dayjs(recurringEventData.startDate), dayjs(recurringEventData.endDate)]
                            : [],
                    }}
                    layout="vertical"
                    onFinish={(v) => {}}
                >
                    {
                        recurringEventData?.id && !recurringEventData?.edit ? HaveCalendar() : ToSetCalendar()
                        // ToSetCalendar()
                    }
                </Form>
            ) : (
                <></>
            )}
        </Col>
    )
}

export default RecurringEventDetails
