import { Col, Divider, Row, Typography } from 'antd'
import gigstackLogo from '../../../assets/images/logogigstack.png'
import { ArrowsLeftRight, CheckCircle, XCircle } from '@phosphor-icons/react'
import { tagsColors } from '../Forms/TagsColors'

interface IntegrationBulletPoint {
    type: 'positive' | 'negative'
    description: string
}

interface IntegrationOnboardingTypes {
    logo: string
    title: string
    description: string
    bullets: IntegrationBulletPoint[]
    IntegrationFooter: React.ReactNode
}

export const IntegrationOnbHeader = ({
    logo,
    title,
    description,
    justify,
    width = 22,
}: {
    logo: string
    title: string
    description: React.ReactNode
    justify: 'center' | 'start' | 'end'
    width?: number
}) => {
    return (
        <Row justify="center" style={{ marginTop: '20px' }}>
            <Col xs={width}>
                <Row justify="center" align="middle">
                    <div
                        style={{
                            height: '40px',
                            width: '40px',
                            backgroundImage: `url(${logo})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100%',
                            borderRadius: '10px',
                        }}
                    ></div>
                    <ArrowsLeftRight className="icon" size={28} weight="regular" style={{ margin: '10px 20px' }} />
                    <div
                        style={{
                            height: '40px',
                            width: '40px',
                            backgroundImage: `url(${gigstackLogo})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100%',
                            borderRadius: '10px',
                        }}
                    ></div>
                </Row>
                <Row justify={justify} style={{ margin: '20px 0px 5px 0px' }}>
                    <Typography.Text className="mediumparagraphbold">{title}</Typography.Text>
                </Row>
                <Row justify={justify}>{description}</Row>
            </Col>
        </Row>
    )
}

const IntegrationOnbBenefits = ({ bullets }: { bullets: IntegrationBulletPoint[] }) => {
    return (
        <Row>
            <Col xs={20}>
                <Row style={{ marginBottom: '15px' }}>
                    <Typography.Text className="mediumparagraphbold">¿Qué hace la integración?</Typography.Text>
                </Row>
                {bullets.map((bp) => {
                    return (
                        <Row justify="start" align="middle" style={{ margin: '15px 0px' }}>
                            {bp.type === 'positive' ? (
                                <CheckCircle
                                    color={tagsColors.find((t) => t.name === 'lightgreen')?.text}
                                    weight="regular"
                                    size={20}
                                />
                            ) : (
                                <XCircle
                                    color={tagsColors.find((t) => t.name === 'gray')?.bg}
                                    weight="regular"
                                    size={20}
                                />
                            )}
                            <Typography.Text className="mediumparagraph" style={{ marginLeft: '10px' }} ellipsis>
                                {bp.description}
                            </Typography.Text>
                        </Row>
                    )
                })}
            </Col>
        </Row>
    )
}

export const IntegrationOnboarding: React.FC<IntegrationOnboardingTypes> = ({
    logo,
    title,
    description,
    bullets,
    IntegrationFooter,
}: IntegrationOnboardingTypes) => {
    return (
        <div>
            <div style={{ padding: '20px 24px' }}>
                <IntegrationOnbHeader
                    logo={logo}
                    title={title}
                    description={<Typography.Text className="mediumparagraph">{description}</Typography.Text>}
                    justify="center"
                />
            </div>
            <Divider style={{ margin: 0 }} />

            <div style={{ padding: '20px 24px' }}>
                <IntegrationOnbBenefits bullets={bullets} />
            </div>
            <Divider style={{ margin: 0 }} />

            {IntegrationFooter}
        </div>
    )
}

export const IntegrationSucceededView = ({
    logo,
    description,
    title,
}: {
    logo: string
    description: string
    title: string
}) => {
    return (
        <div style={{ padding: '20px 24px' }}>
            <IntegrationOnbHeader logo={logo ?? ''} title="" description={<></>} justify="start" width={24} />

            <Row justify="center" align="middle" style={{ margin: '20px 0px' }}>
                <CheckCircle color={tagsColors.find((t) => t.name === 'lightgreen')?.text} weight="regular" size={40} />
            </Row>

            <Row justify="center" align="middle">
                <Typography.Text className="mediumparagraphbold">{title}</Typography.Text>
            </Row>

            <Row justify="center" align="middle" style={{ margin: '20px 0px' }}>
                <Typography.Text style={{ textAlign: 'center' }} className="mediumparagraph">
                    {description}
                </Typography.Text>
            </Row>
        </div>
    )
}
