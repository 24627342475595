import { Form, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { setData } from '../context/dataSlice'
import TaxesForm from '../components/Forms/TaxesForm'

const CreateAddTaxModal = () => {
    const dispatch = useDispatch()
    const { addTaxModalVisible } = useSelector((state: any) => state.modals)
    const { taxes } = useSelector((state: any) => state.data)
    const [form] = Form.useForm()

    return (
        <Modal
            title={null}
            open={addTaxModalVisible}
            footer={null}
            zIndex={1003}
            onCancel={() => {
                dispatch(closeModal('addTaxModalVisible'))
            }}
        >
            <div className="d-flex flex-column">
                <TaxesForm
                    incomingForm={form}
                    returnCreated={async (v) => {
                        try {
                            await form.validateFields()
                            const taxTypesOptions = [
                                { key: 'iva_16', type: 'IVA', rate: '0.16', inclusive: true },
                                { key: 'iva_no_16', type: 'IVA', rate: '0.16', inclusive: false },
                                { key: 'isr_30', type: 'ISR', rate: '0.30', inclusive: true },
                                { key: 'isr_no_30', type: 'ISR', rate: '0.30', inclusive: false },
                            ]

                            const taxesToAdd = taxTypesOptions
                                .filter((taxType) => v[taxType.key])
                                .map((taxType) => ({
                                    type: taxType.type,
                                    rate: taxType.rate,
                                    factor: 'Tasa',
                                    inclusive: taxType.inclusive,
                                    withholding: false,
                                }))

                            if (v.customTax) {
                                taxesToAdd.push({
                                    type: v.type,
                                    rate: v.rate,
                                    factor: v.factor,
                                    inclusive: v.inclusive,
                                    withholding: v.withholding,
                                })
                            }

                            dispatch(
                                setData({
                                    item: 'taxes',
                                    data: [...taxes, ...taxesToAdd],
                                }),
                            )
                        } catch (error) {
                            return
                        }

                        dispatch(closeModal('addTaxModalVisible'))
                        form.resetFields()
                    }}
                />
            </div>
        </Modal>
    )
}

export default CreateAddTaxModal
