import { Row, Space, Tag, Tooltip, Typography } from 'antd'
import { InvoiceDef, OptimizedInvoiceDef } from '../../../../interfaces/invoicesDef'
import { returnCurrencyValue } from '../../../functions/helpers'
import moment from 'moment'
import { Pencil } from '@phosphor-icons/react'
import InvoiceActions from '../../ElementActions/InvoiceActions'
import { InvoiceStatusTag } from '../../UI/ElementStatus'
import { paymentForms } from '../../../datasets/Fiscal'
import RelationPayment from '../../Relations/RelationPaymentWithInvoice'
import { ElementFromTag } from '../../UI/ElementFrom'
import { CopyableElementId } from '../../UI/CopyableElementId'
// import { useSelector } from "react-redux"
import { CheckIfRelating } from '../../Relations/RelatingElementsSignal'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'

export type TypeInvoicesColumnName =
    | 'id'
    | 'amount'
    | 'clientInfo'
    | 'clientEmail'
    | 'rfc'
    | 'status'
    | 'timestamp'
    | 'from'
    | 'invoiceType'
    | 'series'
    | 'relations'
    | 'actions'
    | 'paymentForm'
    | 'paymentMethod'
    | 'paymentFormAndMethod'

export const InvoicesColumns = ({
    from,
    record,
    columnName,
    navigate,
}: {
    from?: string
    record: InvoiceDef | OptimizedInvoiceDef
    columnName?: TypeInvoicesColumnName
    navigate?: Function
}) => {
    const type = record.invoiceType
    const amount =
        type === 'I'
            ? record.total
            : type === 'P'
              ? // SUM IN THE NEXT JSON
                /*
          record.complements = []
          complements[0] = {
              data:[]
          }
          complements[0].data[0] = {
              related_documents:[]
          }
          complements[0].data[0].related_documents[0] = {
              amount: 100
          }

          */

                record?.complements?.reduce((acc1, complement) => {
                    return (
                        acc1 +
                        complement.data.reduce((acc2: any, data: any) => {
                            return (
                                acc2 +
                                data.related_documents.reduce((acc3: any, doc: any) => {
                                    return acc3 + doc.amount
                                }, 0)
                            )
                        }, 0)
                    )
                }, 0)
              : record.total
    switch (columnName) {
        case 'id':
            return <Typography.Link>{record.id}</Typography.Link>
        case 'amount':
            return (
                <Row justify="end">
                    <Typography.Text className="p-base-bold neutral-1">
                        {returnCurrencyValue(amount)} {record.currency?.toLocaleUpperCase()}
                    </Typography.Text>
                </Row>
            )
        case 'paymentForm':
            return (
                <Row justify="center">
                    <Typography.Text className="p-base-regular">{record.payment_form}</Typography.Text>
                </Row>
            )
        case 'paymentMethod':
            return (
                <Row justify="center">
                    <Typography.Text className="p-base-regular">{record.payment_method}</Typography.Text>
                </Row>
            )
        case 'paymentFormAndMethod':
            const pf = paymentForms.find((c) => c.value === record.payment_form)
            return (
                <Row justify="center">
                    <div>
                        <Typography.Text className="p-base-regular ">{record?.payment_method}</Typography.Text>{' '}
                        <Tooltip title={pf?.label}>
                            <Typography.Text className="p-base-regular ">{pf?.value}</Typography.Text>
                        </Tooltip>
                    </div>
                </Row>
            )
        case 'timestamp':
            return (
                <Space direction="vertical" size={1}>
                    <Typography.Text className="p-base-regular ">
                        {moment(record.timestamp).format('DD/MM/YY')}
                    </Typography.Text>
                    <Typography.Text className="p-base-regular ">
                        {moment(record.timestamp).format('HH:mm')}
                    </Typography.Text>
                </Space>
            )
        case 'clientInfo':
            const name = record?.customer?.legal_name ?? record?.client?.legal_name ?? record.client?.name ?? ''
            // const nameTruncated = name?.length > 25 ? name?.substring(0, 25) + '...' : name
            return (
                <Space direction="vertical" size={0}>
                    <Row>
                        <Typography.Text
                            className="p-base-regular neutral-1 clickable"
                            onClick={() => (navigate ? navigate() : () => {})}
                            style={{ margin: 0, padding: 0, flex: 1 }}
                        >
                            {name.toUpperCase()}
                        </Typography.Text>
                    </Row>
                    <Typography.Text
                        className="p-base-regular "
                        style={{ fontSize: '10px' }}
                        editable={{
                            icon: <Pencil size={10} />,
                            onChange: async (e) => {
                                let toUpdate = {}
                                const value = e.replace('Order Id: ', '')
                                toUpdate = { metadata: { ...record.metadata, orderId: value } }

                                if (record.whmcsInvoiceId) {
                                    const value = e.replace('WHMCS: ', '')
                                    toUpdate = { ...toUpdate, whmcsInvoiceId: value }
                                }
                                try {
                                    await updateDoc(doc(getFirestore(), 'invoices', record.id), toUpdate)
                                } catch (error) {
                                    console.log(error)
                                }
                            },
                        }}
                    >
                        {record?.whmcsInvoiceId
                            ? `WHMCS: ${record?.whmcsInvoiceId}`
                            : `Order Id: ${record?.metadata?.orderId || ''}`}
                    </Typography.Text>
                    <CopyableElementId id={record?.uuid} idName="UUID" />
                    <CheckIfRelating element={record} collection="invoices" />
                </Space>
            )
        case 'clientEmail':
            return <span>{record.client?.email}</span>
        case 'rfc':
            return <span>{record.client?.rfc}</span>
        case 'from':
            return (
                <Row style={{ margin: 'auto' }}>
                    <ElementFromTag record={record} recordType="invoice" />
                </Row>
            )
        case 'invoiceType':
            const type = record.invoiceType === 'I' ? 'Ingreso' : record.invoiceType === 'P' ? 'Pago' : 'Egreso'
            return (
                <Row align="middle" justify="center">
                    <Tag className="bg-white rounded-full" onClick={() => {
                        console.log('clicked', record)
                    }}>
                        <Typography.Text className="p-small-medium">
                            {type}
                        </Typography.Text>
                    </Tag>
                </Row>
            )
        case 'status':
            return <InvoiceStatusTag shape="circle" record={record} />
        case 'series':
            return (
                <Row justify="center" align="middle">
                    <Typography.Text className="p-base-regular ">
                        {record.series} {record.folio_number}
                    </Typography.Text>
                </Row>
            )
        case 'relations':
            return <RelationPayment record={record} hide={'complement'} />

        case 'actions':
            return <InvoiceActions invoice={record} from={from} />
    }
}
