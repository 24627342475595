import {
    CaretDoubleRight,
    Checks,
    DownloadSimple,
    Intersect,
    MinusCircle,
    PlusCircle,
    SelectionSlash,
    Trash,
    UploadSimple,
    X,
} from '@phosphor-icons/react'
import { Button, Col, Dropdown, Modal, Row, Space, Tooltip, Typography, message } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { GetClients } from '../context/databaseContextHelpers';
import TablesContainer from '../components/Tables/TablesContainer'
import ClientsTable from '../components/Tables/ClientsTable'
import { deleteData, setData } from '../context/dataSlice'
import { ClientDef } from '../../interfaces/clientDef'
import { RingSpinner } from 'react-spinners-kit'
import { deleteDoc, doc, getFirestore } from 'firebase/firestore'
import { openModal } from '../context/modalsSlice'
import DynamicTabs from '../components/Menu/Tabs'
import { ClientsJTDContainer } from '../components/JobsToBeDone/ClientsJTBDContainer'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
import { useAuth } from 'reactfire'
import moment from 'moment'

// type FilterByType = 'Todos' | 'Principales Clientes' | 'Clientes Nuevos' | 'Sin info. fiscal';

const ConfirmDeleteModal = ({
    visible,
    onConfirm,
    onCancel,
}: {
    visible: boolean
    onConfirm: Function
    onCancel: Function
}) => {
    const [deleted, setDeleted] = useState<string[]>([])
    const [loading, setLoading] = useState<string[]>([])

    const { selectedClients } = useSelector((state: any) => state.data)
    const dispatch = useDispatch()

    const ConfirmDelete = async () => {
        setDeleted([])
        setLoading([...loading, 'deleteAll'])
        // wait 500ms
        var alreadyDelt: string[] = []
        await new Promise((r) => setTimeout(r, 100))
        for (const client of selectedClients) {
            await new Promise((r) => setTimeout(r, 100))
            try {
                try {
                    await deleteDoc(doc(getFirestore(), 'clients', client.id))
                    if (alreadyDelt.includes(client.id)) continue
                    alreadyDelt.push(client.id)
                    setDeleted(alreadyDelt)
                    dispatch(deleteData({ item: 'clients', id: client.id }))
                } catch (error) {}
            } catch (error) {}

            await new Promise((r) => setTimeout(r, 100))
        }

        setLoading(loading.filter((l) => l !== 'deleteAll'))
    }

    useEffect(() => {}, [deleted])

    return (
        <Modal
            open={visible}
            closeIcon={<></>}
            closable={false}
            title={
                <div style={{ position: 'relative', paddingBottom: '15px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            //center the text
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    ></Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X className="icon clickable" size={20} onClick={() => onCancel()} />
                                <></>
                                <Button
                                    danger
                                    disabled={
                                        loading.includes('deleteAll') || deleted?.length === selectedClients?.length
                                    }
                                    onClick={(e) => {
                                        ConfirmDelete()
                                    }}
                                >
                                    Eliminar
                                    <CaretDoubleRight
                                        size={15}
                                        style={{ marginLeft: '5px' }}
                                        className=""
                                        weight="regular"
                                    />
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            footer={null}
        >
            <div className="d-flex flex-column">
                <Typography.Text className="mediumheader" style={{ marginBottom: '5px' }}>
                    ¿Deseas eliminar {selectedClients?.length} cliente{selectedClients?.length > 1 ? 's' : ''}{' '}
                    seleccionado{selectedClients?.length > 1 ? 's' : ''}?
                </Typography.Text>
                <Typography.Text className="mediumparagraph">
                    Esta acción es irreversible y no se podrán recuperar los clientes eliminados
                </Typography.Text>
                <ul
                    style={{
                        listStyle: 'none',
                        margin: 0,
                        padding: 0,
                        marginTop: '15px',
                    }}
                >
                    {selectedClients.map((c: ClientDef) => {
                        return (
                            <li key={c.id}>
                                <Row
                                    style={{
                                        width: '100%',
                                        padding: '10px 15px',
                                        backgroundColor: '#FAFBFF',
                                        borderRadius: '10px',
                                        marginBottom: '5px',
                                    }}
                                    justify="space-between"
                                    align="middle"
                                >
                                    <div className="d-flex flex-column">
                                        <Typography.Text className="smallparagraphbold" style={{ marginBottom: '5px' }}>
                                            {c.name || 'Sin nombre'} {c.company ?? '-'}
                                        </Typography.Text>
                                        <Typography.Text className="smallparagraph">
                                            {c.email || 'Sin email'} | {c.id}
                                        </Typography.Text>
                                    </div>
                                    {deleted.includes(c.id) ? (
                                        <Tooltip title="Eliminado de forma correcta">
                                            <Checks color="#52BD94" size={20} weight="regular" />
                                        </Tooltip>
                                    ) : loading.includes('deleteAll') ? (
                                        <RingSpinner color="#FFB020" size={20} />
                                    ) : (
                                        <Tooltip title="Remover selección">
                                            <MinusCircle
                                                size={20}
                                                weight="regular"
                                                className="icon clickable"
                                                onClick={() => {
                                                    const newSelectedClients = selectedClients.filter(
                                                        (sc: ClientDef) => sc.id !== c.id,
                                                    )
                                                    dispatch(
                                                        setData({ item: 'selectedClients', data: newSelectedClients }),
                                                    )
                                                    if (newSelectedClients?.length === 0) onCancel()
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </Row>
                            </li>
                        )
                    })}
                </ul>
                <Row justify="center" style={{ marginTop: '10px' }}>
                    {deleted?.length === selectedClients?.length && (
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallparagraph">
                                Se han eliminado los clientes seleccionados de forma correcta
                            </Typography.Text>
                            <Button
                                style={{ marginTop: '10px' }}
                                onClick={() => {
                                    setDeleted([])
                                    onCancel()
                                }}
                            >
                                Cerrar
                            </Button>
                        </div>
                    )}
                </Row>
            </div>
        </Modal>
    )
}

const ClientsPage: React.FC = () => {
    const dispatch = useDispatch()
    const auth = useAuth()
    // const { team } = useSelector((state: any) => state.team)
    // const { clients, clientsLoaded } = useSelector((state: any) => state.data)
    // const [filterBy, setFilterBy] = useState<FilterByType>('Todos');
    const { selectedClients } = useSelector((state: any) => state.data)
    const [loading, setLoading] = useState<any[]>([])

    useEffect(() => {
        // if (clients.length === 0 && !clientsLoaded) {
        //     GetClients(dispatch, team.id, clientsLoaded)
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const downloadClientsFile = async () => {
        try {
            setLoading([...loading, 'download-clients'])
            await SignedInternalAPIRequest(
                {
                    clientId: null,
                },
                `exportFiles/v1/clients`,
                auth.currentUser,
                {
                    isFile: true,
                    fileName: `clientes-${moment().format('DD/MM/YYYY')}`,
                    fileExtension: 'xlsx',
                },
                'POST',
            )
            setLoading(loading.filter((l) => l !== 'download-clients'))
        } catch (error: any) {
            setLoading(loading.filter((l) => l !== 'download-clients'))
            message.error(error.message ?? 'Ocurrió un error al descargar el archivo')
        }
    }

    const tabsConfig = [
        {
            key: 'clientsTable',
            title: 'Clientes',
            content: (
                <TablesContainer
                    children={
                        <div className="d-flex flex-column">
                            <Row align="middle" style={{ marginBottom: '10px' }} justify="end">
                                {/* <Radio.Group onChange={(ev) => setFilterBy(ev.target.value)} value={filterBy} style={{ marginBottom: 0 }}>
                        <Radio.Button value="Todos">Todos</Radio.Button>
                        <Radio.Button value="Principales Clientes">Principales Clientes</Radio.Button>
                        <Radio.Button value="Clientes Nuevos">Clients Nuevos</Radio.Button>
                        <Radio.Button value="Sin info. fiscal">Sin info. fiscal</Radio.Button>
                    </Radio.Group> */}
                                {selectedClients?.length > 0 && (
                                    <Space>
                                        {/* <Tooltip title="Des-seleccionar todos">
                            <SelectionSlash weight="regular" size={20} style={{ marginLeft: '10px' }} className='icon iconDanger clickable' onClick={() => {
                                dispatch(setData({ item: 'selectedClients', data: [] }))
                            }} />
                        </Tooltip> */}
                                        <Dropdown
                                            disabled={loading.includes('deleteAll')}
                                            menu={{
                                                items: [
                                                    {
                                                        onClick: async () => {
                                                            dispatch(openModal('mergeClientsModalVisible'))
                                                            dispatch(
                                                                setData({
                                                                    item: 'clientsToMerge',
                                                                    data: selectedClients,
                                                                }),
                                                            )
                                                        },
                                                        label: (
                                                            <Typography.Text>
                                                                <Intersect
                                                                    weight="regular"
                                                                    size={15}
                                                                    style={{ marginRight: '5px' }}
                                                                />{' '}
                                                                Unificar clientes
                                                            </Typography.Text>
                                                        ),
                                                        key: 'mergeClients',
                                                    },
                                                    {
                                                        onClick: async () => {
                                                            setLoading([...loading, 'deleteAll'])
                                                        },
                                                        label: (
                                                            <Typography.Text>
                                                                <Trash
                                                                    weight="regular"
                                                                    size={15}
                                                                    style={{ marginRight: '5px' }}
                                                                />{' '}
                                                                Eliminar seleccionados
                                                            </Typography.Text>
                                                        ),
                                                        key: 'deleteAll',
                                                    },
                                                    {
                                                        onClick: async () => {
                                                            dispatch(setData({ item: 'selectedClients', data: [] }))
                                                        },
                                                        label: (
                                                            <Typography.Text>
                                                                <SelectionSlash
                                                                    weight="regular"
                                                                    size={15}
                                                                    style={{ marginRight: '5px' }}
                                                                />{' '}
                                                                Deshacer selección
                                                            </Typography.Text>
                                                        ),
                                                        key: 'unselect',
                                                    },
                                                ],
                                            }}
                                            trigger={['click']}
                                        >
                                            <Button style={{ marginLeft: '10px' }}>Acciones masivas</Button>
                                        </Dropdown>
                                    </Space>
                                )}
                            </Row>
                            <ClientsTable max={1000} />
                        </div>
                    }
                    title={'Clientes'}
                    description={
                        'Lista de clientes que guardados por las diferentes fuentes de información conectadas.'
                    }
                    actions={
                        <div style={{ width: '100%' }}>
                            <Row justify="end" style={{ marginBottom: '16px', width: '100%' }} gutter={12}>
                                <Space size={12}>
                                    <Button
                                        size="middle"
                                        type="dashed"
                                        onClick={() => {
                                            dispatch(openModal('uploadThingsModalVisible'))
                                            dispatch(
                                                setData({
                                                    item: 'importThingsInfo',
                                                    data: {
                                                        type: 'clients',
                                                        readableType: 'clientes',
                                                        templateUrl:
                                                            'https://firebasestorage.googleapis.com/v0/b/gigstackpro.appspot.com/o/public%2FClients_tamplate_gigistack.xlsx?alt=media&token=e7ba40f5-35d3-4b38-a74f-fdd0548c769e',
                                                        description:
                                                            'Los clientes que no tengan nombre o correo electrónico, no serán importados.',
                                                    },
                                                }),
                                            )
                                        }}
                                        loading={false}
                                        icon={<UploadSimple className="iconColor" size={18} weight="regular" />}
                                    >
                                        Importar
                                    </Button>
                                    <Button
                                        size="middle"
                                        onClick={downloadClientsFile}
                                        loading={loading.includes('download-clients')}
                                        icon={<DownloadSimple className="iconColor" size={18} weight="regular" />}
                                    ></Button>
                                    <Button
                                        size="middle"
                                        type="primary"
                                        onClick={() => {
                                            dispatch(
                                                setData({
                                                    item: 'client',
                                                    data: null,
                                                }),
                                            )
                                            dispatch(openModal('createClientDrawerVisible'))
                                        }}
                                        icon={<PlusCircle className="" size={18} weight="regular" />}
                                    >
                                        Nuevo cliente
                                    </Button>
                                </Space>
                            </Row>
                        </div>
                    }
                />
            ),
        },
        {
            key: 'clientsActions',
            title: 'Acciones',
            content: <ClientsJTDContainer />,
        },
    ]

    return (
        <>
            <ConfirmDeleteModal
                visible={loading.includes('deleteAll')}
                onCancel={() => {
                    setLoading(loading.filter((l) => l !== 'deleteAll'))
                }}
                onConfirm={() => {
                    // const keysToDelete = selectedClients.map((c: any) => c.id)
                    setLoading(loading.filter((l) => l !== 'deleteAll'))
                }}
            />
            {/* <Typography.Title level={4} className='mediumheader'><User style={{ width: '18px', height: '18px', marginRight: '6px' }} />Clientes</Typography.Title> */}
            <Typography.Text className="h2-bold neutral-1" style={{ marginBottom: '15px' }}>
                Clientes
            </Typography.Text>

            <DynamicTabs
                draggable={false}
                defaultActiveKey={'clientsTable'}
                tabsConfig={tabsConfig}
                onChange={() => {
                    dispatch(setData({ item: 'clientsToMerge', data: [] }))
                }}
            />
        </>
    )
}
export default ClientsPage
