import {
    Form,
    Input,
    Select,
    Typography,
    Upload,
    message,
    Modal,
    ColorPicker,
    AutoComplete,
    Popover,
    Checkbox,
    InputNumber,
    Row,
    Col,
    Button,
    Tag,
    DatePicker,
} from 'antd'
import { ReactElement, ReactNode, useState } from 'react'
import { getDownloadURL, ref, uploadBytesResumable, deleteObject } from 'firebase/storage'
import { generateCode } from '../../../helpers/helperFunctions'
import { useStorage } from 'reactfire'
import { useSelector } from 'react-redux'
import { deleteDoc, doc, getFirestore, setDoc } from 'firebase/firestore'
import { PlusOutlined } from '@ant-design/icons'
import type { RcFile, UploadProps } from 'antd/es/upload'
import type { UploadFile } from 'antd/es/upload/interface'
import {
    EyeSlash,
    Eye,
    CheckCircle,
    MagnifyingGlass,
    User,
    XCircle,
    Plus,
    UploadSimple,
    WarningCircle,
    CaretUpDown,
    Upload as UploadIcon,
} from '@phosphor-icons/react'
import { MetroSpinner } from 'react-spinners-kit'
import { tagsColors } from './TagsColors'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'

export const SearchAutocomplete = ({
    value,
    notFoundContent,
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    styleProps,
    onChange,
    options,
    onSelect,
    isLoading,
    onClear,
    popovercontent,
}: {
    name: string
    label?: string | null
    placeholder?: string | undefined
    description?: string
    disabled?: boolean
    rules?: any
    styleProps?: object
    onChange: (value: string) => void
    options: any
    notFoundContent?: ReactElement
    onSelect: (v: any) => void
    value?: any
    isLoading?: boolean
    onClear?: () => void
    popovercontent?: ReactElement
}) => {
    const LoadingIcon = () => {
        return <MetroSpinner size={16} color="#686769" />
    }
    return (
        <Form.Item name={name} label={label} help={description} rules={rules} style={styleProps ?? {}}>
            <AutoComplete
                onClear={onClear}
                allowClear={true}
                defaultValue={value}
                menuItemSelectedIcon={<MagnifyingGlass weight="regular" />}
                notFoundContent={notFoundContent ?? <></>}
                style={{ width: '100%' }}
                onSearch={onChange}
                disabled={disabled}
                options={options}
                onSelect={(value, option) => onSelect(option?.key)}
            //CHANGE THE REACT COMPONENT FOR RESULTS
            >
                <Input
                    prefix={
                        isLoading ? (
                            <LoadingIcon />
                        ) : (
                            <Popover content={popovercontent}>
                                <User size={13} weight="regular" />
                            </Popover>
                        )
                    }
                    placeholder={placeholder}
                />
            </AutoComplete>
        </Form.Item>
    )
}

export const SearchInput = ({
    onChangeCapture,
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    styleProps,
    onChange,
}: {
    onChangeCapture?: any
    name: string
    label?: string | null
    placeholder?: string | undefined
    description?: string
    disabled?: boolean
    rules?: any
    styleProps?: object
    onChange: Function
}) => {
    return (
        <Form.Item name={name} label={label} help={description} rules={rules} style={styleProps ?? {}}>
            <Input
                prefix={<MagnifyingGlass weight="regular" className="icon" />}
                onChangeCapture={onChangeCapture}
                disabled={disabled}
                placeholder={placeholder}
                onChange={(v) => onChange(v)}
            />
        </Form.Item>
    )
}

export const PasswordInput = ({
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    prefix,
    suffix,
}: {
    name: string
    label?: string | null
    placeholder?: string | undefined
    description?: string
    disabled?: boolean
    rules?: any
    prefix?: ReactNode
    suffix?: ReactNode
}) => {
    return (
        <Form.Item name={name} label={label} help={description} rules={rules} className="ph-no-capture">
            <Input.Password
                className="ph-no-capture"
                suffix={suffix}
                prefix={prefix}
                placeholder={placeholder}
                disabled={disabled}
                iconRender={(visible) =>
                    visible ? (
                        <Eye
                            weight="regular"
                            style={{
                                cursor: 'pointer',
                                color: '#c7c7c7',
                            }}
                        />
                    ) : (
                        <EyeSlash
                            weight="regular"
                            style={{
                                cursor: 'pointer',
                                color: '#c7c7c7',
                            }}
                        />
                    )
                }
            />
        </Form.Item>
    )
}

export const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault()
        event.stopPropagation()
    }
    const color = tagsColors[0]
    //random color from colors

    return (
        <Tag
            color={color.bg}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3, color: color.text, marginBottom: '3px' }}
        >
            {label}
        </Tag>
    )
}

interface CustomTagRenderProps {
    label: string
    closable: boolean
    onClose: (event: React.MouseEvent) => void
    color: {
        bg: string
        text: string
    }
}

export const customTagRender = (props: CustomTagRenderProps) => {
    const { label, closable, onClose, color } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault()
        event.stopPropagation()
    }

    return (
        <Tag
            color={color.bg}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3, color: color.text, marginBottom: '3px' }}
        >
            <Typography.Text className="smallparagraphbold">{label}</Typography.Text>
        </Tag>
    )
}

export const ChipsSelect = ({
    name,
    label,
    placeholder,
    change,
    options,
    description,
    disabled,
    rules,
    empty,
    customTag,
}: {
    name: string
    label?: string | null
    placeholder?: string | undefined
    change: any
    options: any
    description?: string
    disabled?: boolean
    rules?: any
    empty?: ReactNode
    customTag?: (props: CustomTagProps) => ReactElement
}) => {
    return (
        <Form.Item name={name} label={label} help={description} rules={rules}>
            <Select
                disabled={disabled}
                mode="tags"
                allowClear={true}
                notFoundContent={empty ?? <></>}
                tagRender={customTag ?? tagRender}
                // tagRender={tagRender}
                //SET CUSTOM COLORS TO tagsColors

                style={{ width: '100%' }}
                placeholder={placeholder}
                onChange={change}
                options={options}
            />
        </Form.Item>
    )
}

export const FileUpload = ({
    name,
    placeholder,
    label,
    icon,
    storagePath,
    firestorePath,
    showUploadList = true,
    disabled,
    firestoreKey,
    firestorePreviousData,
    previousDataKey,
    onURLUploaded,
    onFileUploaded,
    allowedFiles,
    rules,
    hardKey,
    incomingFiles,
    inputType = 'default',
}: {
    name: string
    placeholder?: string
    label?: string
    icon?: ReactElement
    storagePath: string
    firestorePath?: string
    showUploadList?: boolean
    disabled?: boolean
    firestoreKey?: string
    firestorePreviousData?: any
    previousDataKey?: string
    onURLUploaded?: (url: string) => void
    onFileUploaded?: (file: any) => void
    allowedFiles?: string[]
    rules?: any[]
    hardKey?: string
    incomingFiles?: any[],
    inputType?: 'default' | 'box'
}) => {
    const { team } = useSelector((state: any) => state.team)
    const [files, setFiles] = useState<any>([...(incomingFiles ?? [])])
    const storage = useStorage()
    const [elementSuccess, setElementSuccess] = useState(false)
    const [currentProgress, setCurrentProgress] = useState(0)

    const [messageApi, contextHolder] = message.useMessage()

    const onChange = ({ file, onSuccess, onError, onProgress }: any) => {
        const fileName = file.name
        const id = hardKey ?? generateCode(10, 'file')
        var composedFile = {
            ...file,
            name: fileName,
            id: id,
            storageRef: storagePath ?? `teams/${team.id}/files/` + id,
        }
        setFiles([...files, composedFile])

        console.log('UPLOADING TO', storagePath ?? `teams/${team.id}/files/` + id)

        const newRef = ref(storage, storagePath ?? `teams/${team.id}/files/` + id)
        // setRef(newRef);

        const uploadTask = uploadBytesResumable(newRef, file)

        uploadTask.on(
            'state_changed',
            (snapshot: any) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                onProgress({ percent: progress })
                setCurrentProgress(progress)
                setFiles((files: any) => {
                    return files.map((file: any) => {
                        if (file.uid === composedFile.uid) {
                            return {
                                ...file,
                                status: 'uploading',
                                percent: progress,
                            }
                        }
                        return file
                    })
                })
            },
            (error: any) => {
                console.log(error)

                messageApi.open({
                    type: 'error',
                    content: `Ocurrió un error al subir el archivo: ${error?.message}`,
                    className: 'custom-class',
                    duration: 3,
                    style: {
                        marginTop: '5vh',
                    },
                })
                setFiles((files: any) => {
                    return files.map((file: any) => {
                        if (file.uid === composedFile.uid) {
                            return {
                                ...file,
                                status: 'error',
                            }
                        }
                        return file
                    })
                })
                onError(error)
                setElementSuccess(false)
            },
            () => {
                onSuccess(null, file)
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    var composedDoc = { ...file }
                    if (firestorePath) {
                        composedDoc = {
                            ...(firestorePreviousData ?? {}),
                            [firestoreKey ?? 'url']: url,
                        }
                        if (previousDataKey) {
                            composedDoc[previousDataKey] = {
                                ...(firestorePreviousData ?? {})[previousDataKey],
                                [firestoreKey ?? 'url']: url,
                            }
                        }
                        const finalPath = firestoreKey ? `${firestorePath}` : `${firestorePath}/${id}`
                        console.log('SETTING ON DB TO', finalPath)

                        setDoc(doc(getFirestore(), finalPath), composedDoc, { merge: true })
                        if (onURLUploaded) {
                            onURLUploaded(url)
                        }
                    }
                    if (onFileUploaded) {
                        onFileUploaded({ ...composedFile, url: url })
                    }
                    setFiles((files: any) => {
                        return files.map((file: any) => {
                            if (file.uid === composedFile.uid) {
                                return {
                                    ...file,
                                    status: 'done',
                                    percent: 100,
                                    url: url,
                                }
                            }
                            return file
                        })
                    })
                    setElementSuccess(true)
                })
            },
        )
    }

    var placeHolderFiledName = files.length > 0 ? files[0].name : placeholder
    return (
        <div style={{ maxWidth: '100%' }}>
            <Form.Item
                name={name}
                label={label}
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
                rules={rules}
            >
                {contextHolder}
                <Upload
                    style={{ width: '100%', height: '100%' }}
                    customRequest={onChange}
                    className="customUpload"
                    accept={allowedFiles?.join(',')}
                    disabled={disabled}
                    fileList={files}
                    beforeUpload={(file, fileList) => {
                        if (files.length > 0) {
                            messageApi.open({
                                type: 'warning',
                                content: `No puedes seleccionar más de un archivo.`,
                                className: 'custom-class',
                                duration: 3,
                                style: {
                                    marginTop: '5vh',
                                },
                            })

                            return false
                        }

                        return true
                    }}
                    multiple={false}
                    listType="text"
                    showUploadList={showUploadList}
                    onRemove={async (file) => {
                        if (files.length === 0) return
                        if (!files[0]?.storageRef) return
                        try {
                            await deleteObject(ref(storage, files[0]?.storageRef))
                            await deleteDoc(doc(getFirestore(), firestorePath ?? ''))
                        } catch (error: any) {}
                        setFiles([])
                    }}
                >
                    {inputType === 'default' && (
                        <Row
                            align="middle"
                            style={{
                                width: '100%',
                                height: '100%',
                                minHeight: '36px',
                                border: '1px solid var(--Neutral-Extended-Borders, #D8DAE5)',
                                borderRadius: '5px',
                            }}
                        >
                            <Typography.Text
                                className="p-base-regular neutral-3"
                                style={{ paddingLeft: '5px', flex: 1 }}
                            >
                                {placeHolderFiledName}
                            </Typography.Text>

                            <Row
                                align="middle"
                                className="rounded-md"
                                style={{
                                    borderLeft: '1px solid var(--Neutral-Extended-Borders, #D8DAE5)',
                                    borderTopLeftRadius: '3px',
                                    borderBottomLeftRadius: '3px',
                                    backgroundColor: 'var(--neutral-5)',
                                    height: '100%',
                                    minHeight: '36px',
                                    padding: '0px 5px',
                                }}
                            >
                                <Typography.Text className="p-base-semi-bold neutral-1" style={{ paddingRight: '5px' }}>
                                    Subir
                                </Typography.Text>
                                {currentProgress > 0 && currentProgress < 100 ? (
                                    <Typography.Text className="p-base-bold neutral-1">
                                        {currentProgress}%
                                    </Typography.Text>
                                ) : files.length > 0 ? (
                                    elementSuccess ? (
                                        <CheckCircle className="icon" />
                                    ) : (
                                        <WarningCircle className="icon" />
                                    )
                                ) : (
                                    <UploadSimple className="icon" />
                                )}
                            </Row>
                        </Row>
                    )}

                    {inputType === 'box' && (
                        <div
                            className="d-flex flex-column d-center clickable"
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'var(--neutral-5)',
                                borderRadius: '5px',
                                padding: '50px',
                            }}
                        >
                            <UploadIcon />
                            <Typography.Text>{placeHolderFiledName}</Typography.Text>
                        </div>
                    )}
                </Upload>
            </Form.Item>
        </div>
    )
}

export const TextAreaInput = ({
    name,
    ref,
    label,
    placeholder,
    description,
    disabled,
    rules,
    propsStyle,
    minRows,
    maxRows,
    maxLength,
    showCount,
    onFocus,
    defaultValue
}: {
    name: string
    ref?: React.RefObject<any>
    label?: string | null
    placeholder?: string | undefined
    description?: string
    disabled?: boolean
    rules?: any
    propsStyle?: React.CSSProperties
    minRows?: number
    maxRows?: number
    maxLength?: number
    showCount?: boolean
    onFocus?: any
    defaultValue?: string
}) => {
    return (
        <Form.Item name={name} label={label} help={description} rules={rules} style={propsStyle ? propsStyle : {}}>
            <Input.TextArea
                onFocus={onFocus ? onFocus : () => { }}
                showCount={showCount ?? false}
                ref={ref}
                disabled={disabled}
                maxLength={maxLength ?? 1000000} //lines
                autoSize={{ minRows: minRows ?? 3, maxRows: maxRows ?? 6 }}
                placeholder={placeholder}
                defaultValue={defaultValue}
            />
        </Form.Item>
    )
}

export const TextInput = ({
    initialValue,
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    onChange,
    prefix,
    suffix,
    onInput,
    readOnly,
    defaultValue,
}: {
    initialValue?: any
    name: string
    label?: string | null
    placeholder?: string | undefined
    description?: string
    disabled?: boolean
    rules?: any
    onChange?: any
    prefix?: ReactNode
    suffix?: ReactNode
    onInput?: any
    readOnly?: boolean
    defaultValue?: any
}) => {
    return (
        <Form.Item
            initialValue={initialValue}
            name={name}
            label={label}
            help={description}
            rules={rules}
            style={{
                marginBottom: description ? '20px' : '10px',
            }}
        >
            <Input
                defaultValue={defaultValue}
                readOnly={readOnly}
                prefix={prefix}
                suffix={suffix}
                disabled={disabled}
                onInput={onInput ? onInput : () => { }}
                onChange={onChange ? onChange : () => { }}
                placeholder={placeholder}
            />
        </Form.Item>
    )
}

export const ColorInput = ({
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    showText = true,
}: {
    name: string
    label?: string | null
    placeholder?: string | undefined
    description?: string
    disabled?: boolean
    rules?: any
    showText?: boolean
}) => {
    return (
        <Form.Item name={name} label={label} help={description} rules={rules}>
            <ColorPicker disabled={disabled} showText={showText} />
        </Form.Item>
    )
}
interface UploadLogoProps {
    logo?: string // Prop para el logo
}

export const UploadLogo: React.FC<UploadLogoProps> = ({ logo }) => {
    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result as string)
            reader.onerror = (error) => reject(error)
        })
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [fileList, setFileList] = useState<UploadFile[]>([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: logo,
        },
    ])

    const handleCancel = () => setPreviewOpen(false)

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile)
        }

        setPreviewImage(file.url || (file.preview as string))
        setPreviewOpen(true)
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    }

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList)

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )
    return (
        <>
            <Upload listType="picture-card" fileList={fileList} onPreview={handlePreview} onChange={handleChange}>
                {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    )
}

export const SelectInput = ({
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    showText,
    options,
    onChange,
    defaultValue,
    mode,
    customStyle,
    initialValues
}: {
    name: string
    label?: string
    placeholder?: string
    description?: string
    disabled?: boolean
    rules?: any[]
    showText?: boolean
    options?: any[]
    onChange?: any
    defaultValue?: any
    mode?: 'multiple' | 'tags' | undefined
    customStyle?: React.CSSProperties
    initialValues?: Object
}) => {
    return (
        <Form.Item name={name} label={label} help={description} rules={rules} style={customStyle} initialValue={initialValues}>
            <Select
                suffixIcon={<CaretUpDown size={16} className="neutral-1" />}
                options={options}
                mode={mode}
                disabled={disabled}
                style={{ marginBottom: description ? '5px' : '0px' }}
                placeholder={placeholder}
                showSearch
                filterOption={(input: string, option?: { label: string; value: string }) =>
                    (option?.label ?? '')?.toLowerCase().includes(input.toLowerCase())
                }
                onChange={onChange ? onChange : () => { }}
                defaultValue={defaultValue}
            />
        </Form.Item>
    )
}

export const CheckBoxInput = ({
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    showText,
    content,
    checked,
    onChange,
    propsStyle,
}: {
    name: string
    label?: string
    placeholder?: string
    description?: string
    disabled?: boolean
    rules?: any[]
    showText?: boolean
    content?: ReactElement
    checked?: boolean
    onChange?: any
    propsStyle?: React.CSSProperties
}) => {
    return (
        <Form.Item
            name={name}
            label={label}
            valuePropName="checked"
            help={description}
            rules={rules}
            style={propsStyle}
        >
            <Checkbox onChange={onChange}>{content}</Checkbox>
        </Form.Item>
    )
}

export const NumberInput = ({
    initialValue,
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    showText,
    min,
    max,
    step,
    onChange,
    propsStyle,
    defaultValue,
    onBlur,
}: {
    initialValue?: any
    name: string
    label?: string
    placeholder?: string
    description?: string
    disabled?: boolean
    rules?: any[]
    showText?: boolean
    min: number
    max: number
    step: number
    onChange?: Function
    onBlur?: Function
    propsStyle?: React.CSSProperties
    defaultValue?: any
}) => {
    return (
        <Form.Item
            initialValue={initialValue}
            name={name}
            label={label}
            help={description}
            rules={rules}
            style={propsStyle}
        >
            <InputNumber
                style={{ width: '100%' }}
                min={min}
                max={max}
                step={step}
                placeholder={placeholder}
                onChange={onChange ? (v) => onChange(v) : undefined}
                defaultValue={defaultValue}
                disabled={disabled}
                changeOnWheel={false}
                onBlur={onBlur ? (v) => onBlur(v) : undefined}
            />
        </Form.Item>
    )
}

export const MetadataListInput = ({
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    showText,
    customAddButton,
    initialValue,
    onChange,
}: {
    name: string
    label?: string
    placeholder?: string
    description?: string
    disabled?: boolean
    rules?: any[]
    showText?: boolean
    customAddButton?: ReactElement,
    initialValue?: any[],
    onChange?: (v: any) => void
}) => {
    return (
        <Form.List name={name} initialValue={initialValue}>
            {(fields: any[] | undefined = initialValue, { add, remove }) => (
                <div className="d-flex flex-column" style={{ width: '100%', padding: '0px 5px' }}>
                    {label && <label>{label}</label>}
                    {fields && fields.length > 0 && (
                        <Row>
                            <Col xs={24} lg={12}>
                                <label>Key</label>
                            </Col>
                            <Col xs={24} lg={12}>
                                <label>Valor</label>
                            </Col>
                        </Row>
                    )}
                    {fields?.map(({ key, name, ...restField }) => (
                        <Row
                            key={key}
                            style={{ width: '100%', padding: '0px 0px', marginTop: '5px' }}
                            align="middle"
                            justify="space-between"
                            gutter={{ xs: 10 }}
                        >
                            <Col xs={24} lg={11}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingrese la llave o elimine el campo en metadata',
                                        },
                                    ]}
                                    noStyle
                                    name={[name, 'key']}
                                >
                                    <Input placeholder="Llave" disabled={disabled} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={11}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingrese el valor o elimine el campo en metadata',
                                        },
                                    ]}
                                    noStyle
                                    name={[name, 'value']}
                                >
                                    <Input placeholder="Valor" disabled={disabled}/>
                                </Form.Item>
                            </Col>
                            <XCircle
                                style={{ marginLeft: '10px' }}
                                weight="regular"
                                className="icon clickable"
                                onClick={() => {
                                    remove(name)
                                    if(onChange) onChange(name)
                                }}
                            />
                        </Row>
                    ))}
                    <Button
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            marginTop: '15px',
                            marginBottom: '15px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#474D66',
                        }}
                        icon={<Plus size={15} />}
                        onClick={() => {
                            add()
                            onChange && onChange(fields)
                        }}
                    >
                        Agregar {fields && fields.length > 0 ? 'otro' : 'un'} valor
                    </Button>
                </div>
            )}
        </Form.List>
    )
}

// Dates

export const DateInput = ({
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    showText,
    onChange,
    propsStyle,
    defaultValue,
    disabledDate,
}: {
    name: string
    label?: string
    placeholder?: string
    description?: string
    disabled?: boolean
    rules?: any[]
    showText?: boolean
    onChange?: Function
    propsStyle?: React.CSSProperties
    defaultValue?: any
    disabledDate?: any
}) => {
    return (
        <Form.Item name={name} label={label} help={description} rules={rules} style={propsStyle}>
            {/* <InputNumber style={{ width: '100%' }} placeholder={placeholder} onChange={onChange ? (v) => onChange(v) : undefined}
            defaultValue={defaultValue} disabled={disabled} /> */}
            <DatePicker style={{ width: '100%' }} disabledDate={disabledDate} defaultValue={defaultValue} />
        </Form.Item>
    )
}
